import React, { useState, useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import * as vendorsAPI from '../../Api/vendors';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import { currencyFormat } from './helperFunctions';
import { Button, Form, Label, Input } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'reactstrap-date-picker';
import { NotificationManager } from 'react-notifications';
import { Controller, useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-8%',
        minWidth: '500px',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
    },
    overlay: { zIndex: 1000 },
};

const ProfitLossLineItem = (props) => {
    const [loading, setLoading] = useState(false);
    const [vendors, setVendors] = useState([]);
    const [incomeData, setIncomeData] = useState([]);
    const [expenseData, setExpenseData] = useState([]);
    const [nonOperatingData, setNonOperatingData] = useState([]);
    const [dateTracker, setDateTracker] = useState({});
    const [itemToEdit, setItemToEdit] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const companyID = useSelector((state) => state.login.company.id);

    const {
        handleSubmit,
        control,
        setValue,
        register,
        reset,
        formState: { errors },
    } = useForm();

    async function fetchData() {
        setLoading(true);
        const vendorsData = await vendorsAPI.getAll(companyID);
        setVendors(vendorsData);
        if (props.type === 'income') {
            console.log('props', props.query)
            const incomeLineItem = await reportsAPI.getPLIncomeLineItem(props.query);
            const incomeLineItemTotal = await reportsAPI.getPLIncomeLineItemTotal(props.query);
            const data = [];
            for (let i = 0; i < incomeLineItem.length; i++) {
                data.push({
                    itemInfo: `${incomeLineItem[i].checkregister.id} - ${incomeLineItem[i].checkregister.ExpenseTypeID} - ${incomeLineItem[i].TransactionTypeID} - ${incomeLineItem[i].id}`,
                    date: incomeLineItem[i].TenantTransactionDate,
                    comment: incomeLineItem[i].Comment,
                    amount: currencyFormat(+incomeLineItem[i].TransactionAmount),
                    submittedBy: (incomeLineItem[i].user?.UserFName || '') + ' ' + (incomeLineItem[i].user?.UserLName || ''),
                    action: (
                        <div className="col-sm-10">
                            <Button
                                type="button"
                                onClick={() => {
                                    setItemToEdit(incomeLineItem[i]);
                                    setOpenModal(true);
                                }}
                                color="primary"
                            >
                                Edit
                            </Button>
                        </div>
                    ),
                });
            }

            data.push({
                amount: 'Total',
                submittedBy: currencyFormat(+incomeLineItemTotal[0].TotalAmount),
            });
            setIncomeData(data);
        }
        if (props.type === 'expense') {
            const expenseLineItem = await reportsAPI.getPLExpenseLineItem(props.query);
            const expenseLineItemTotal = await reportsAPI.getPLExpenseLineItemTotal(props.query);
            const data = [];
            for (let i = 0; i < expenseLineItem.length; i++) {
                data.push({
                    category: `${expenseLineItem[i].vendor.VendorName} - ${expenseLineItem[i].id}`,
                    invoiceDate: expenseLineItem[i].InvoiceDate,
                    memo: expenseLineItem[i].Memo,
                    amount: currencyFormat(+expenseLineItem[i].Amount),
                    obj: expenseLineItem[i],
                    action: (
                        <div className="col-sm-6">
                            <Button
                                type="button"
                                onClick={() => {
                                    setItemToEdit(expenseLineItem[i]);
                                    setOpenModal(true);
                                }}
                                color="primary"
                            >
                                Edit
                            </Button>
                        </div>
                    ),
                });
            }
            data.push({
                memo: 'Total',
                amount: currencyFormat(+expenseLineItemTotal[0].TotalAmount),
            });
            setExpenseData(data);
        }
        if (props.type === 'nonop') {
            const nonOperatingLineItem = await reportsAPI.getPLNonOperatingLineItem(props.query);
            const nonOperatingLineItemTotal = await reportsAPI.getPLNonOperatingLineItemTotal(props.query);
            const data = [];
            for (let i = 0; i < nonOperatingLineItem.length; i++) {
                data.push({
                    category: `${nonOperatingLineItem[i].vendor.VendorName} - ${nonOperatingLineItem[i].id}`,
                    invoiceDate: nonOperatingLineItem[i].InvoiceDate,
                    memo: nonOperatingLineItem[i].Memo,
                    amount: currencyFormat(+nonOperatingLineItem[i].Amount),
                    action: (
                        <div className="col-sm-10">
                            <Button
                                type="button"
                                onClick={() => {
                                    setItemToEdit(nonOperatingLineItem[i]);
                                    setOpenModal(true);
                                }}
                                color="primary"
                            >
                                Edit
                            </Button>
                        </div>
                    ),
                });
            }
            data.push({
                memo: 'Total',
                amount: currencyFormat(+nonOperatingLineItemTotal[0].TotalAmount),
            });
            setNonOperatingData(data);
        }
        setLoading(false);
    }

    useEffect(() => {
        props.query && fetchData();
    }, [props.query]);
    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Reports...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }
    const incomeColumns = [
        { name: 'itemInfo', label: 'CRID - ETID - TT - TTID' },
        { name: 'date', label: 'Date' },
        { name: 'comment', label: 'Comment' },
        { name: 'amount', label: 'Amount' },
        { name: 'submittedBy', label: 'Submitted By' },
        {
            name: 'action',
            label: 'Action',
            options: {
                download: false,
            },
        },
    ];
    const expenseColumns = [
        { name: 'category', label: 'Vendor - CheckRegisterID' },
        {
            name: 'invoiceDate',
            label: 'Invoice Date',
        },
        { name: 'memo', label: 'Memo' },
        { name: 'amount', label: 'Amount' },
        {
            name: 'action',
            label: 'Action',
            options: {
                download: false,
            },
        },
    ];

    const submitForm = async (values) => {
        console.log('values', values);
        var res = 0;
        if (props.type !== 'income') {
            let dataToSubmit = {
                checkRegisterID: itemToEdit.id,
            };
            if (values.vendor) dataToSubmit.VendorID = values.vendor;
            if (values.invoiceDate) dataToSubmit.InvoiceDate = values.invoiceDate;
            if (values.memo) dataToSubmit.Memo = values.memo;

            res = await reportsAPI.updateExpenseLineitem(dataToSubmit);
        } else {
            let dataToSubmit = {
                transactionID: itemToEdit.id,
            };
            if (values.tenantTransactionDate) dataToSubmit.TenantTransactionDate = values.tenantTransactionDate;
            if (values.comment) dataToSubmit.Comment = values.comment;

            res = await reportsAPI.updateIncomeLineitem(dataToSubmit);
        }
        console.log('res', res);
        if (res !== 1) {
            NotificationManager.error('Error processing your request. Please, contact us.', 'Error');
            return;
        }
        NotificationManager.success('Invoice Date Updated Successfully!', 'Success');
        reset();
        setOpenModal(false);

        await fetchData();
    };

    const renderModalContent = () => {
        return (
            <Form onSubmit={handleSubmit(submitForm)}>
                {props.type !== 'income' ? (
                    <>
                        <div className="row" style={{ marginTop: '2rem' }}>
                            <div className="col-sm-12">
                                <Label for="vendor" className="mr-sm-10">
                                    Vendor
                                </Label>
                                <Controller
                                    name="vendor"
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <Input {...field} defaultValue={itemToEdit.vendor.id} type="select" id="vendor">
                                            {itemToEdit ? (
                                                <option value={itemToEdit.vendor.id}>{itemToEdit.vendor.VendorName}</option>
                                            ) : (
                                                <option value="">Select</option>
                                            )}
                                            {vendors.map((vendor, idx) => {
                                                return (
                                                    <option key={vendor.id} value={vendor.VendorID}>
                                                        {vendor.VendorName}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '1rem' }}>
                            <div className="col-sm-6">
                                <Label for="invoiceDate" className="mr-sm-10">
                                    Invoice Date
                                </Label>
                                <Controller
                                    name="invoiceDate"
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            defaultValue={itemToEdit.InvoiceDate}
                                            name="invoiceDate"
                                            id="invoiceDate"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '1rem' }}>
                            <div className="col-sm-12">
                                <Label for="memo" className="mr-sm-10">
                                    Memo
                                </Label>
                                <Controller
                                    name="memo"
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <Input {...field} defaultValue={itemToEdit.Memo} type="textarea" id="memo" />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'right', marginTop: '1rem' }}>
                            <div className="col-sm-3">
                                <Button onClick={() => setOpenModal(false)} style={{ marginTop: '1rem' }}>
                                    {'Cancel'}
                                </Button>
                            </div>
                            <div className="col-sm-3">
                                <Button type="submit" color="primary" style={{ marginTop: '1rem' }}>
                                    {'Update'}
                                </Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="row" style={{ marginTop: '1rem' }}>
                            <div className="col-sm-6">
                                <Label for="tenantTransactionDate" className="mr-sm-10">
                                    Tenant TransactionDate
                                </Label>
                                <Controller
                                    name="tenantTransactionDate"
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            defaultValue={itemToEdit.TenantTransactionDate}
                                            name="tenantTransactionDate"
                                            id="tenantTransactionDate"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '1rem' }}>
                            <div className="col-sm-12">
                                <Label for="memo" className="mr-sm-10">
                                    Comment
                                </Label>
                                <Controller
                                    name="comment"
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <Input {...field} defaultValue={itemToEdit.Comment} type="textarea" id="memo" />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'right', marginTop: '1rem' }}>
                            <div className="col-sm-3">
                                <Button onClick={() => setOpenModal(false)} style={{ marginTop: '1rem' }}>
                                    {'Cancel'}
                                </Button>
                            </div>
                            <div className="col-sm-3">
                                <Button type="submit" color="primary" style={{ marginTop: '1rem' }}>
                                    {'Update'}
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </Form>
        );
    };

    return (
        <div>
            <Modal
                isOpen={openModal}
                onRequestClose={() => setOpenModal(false)}
                contentLabel="Edit Line Item"
                style={modalStyles}
            >
                {renderModalContent()}
            </Modal>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <i
                            className="ti-angle-left"
                            style={{ cursor: 'pointer', marginRight: '10px', verticalAlign: 'middle', fontSize: '60%' }}
                            onClick={props.back}
                        ></i>
                        <span>Line Item Details</span>
                    </h2>
                </div>
            </div>
            <div className="row">
                {props.type === 'income' && (
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <MuiThemeProvider theme={Constants.getMuiTheme()}>
                            <MUIDataTable
                                title={'Income'}
                                data={incomeData}
                                columns={incomeColumns}
                                options={props.options}
                            />
                        </MuiThemeProvider>
                    </div>
                )}

                {props.type === 'expense' && (
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <MuiThemeProvider theme={Constants.getMuiTheme()}>
                            <MUIDataTable
                                title={'Expenses'}
                                data={expenseData}
                                columns={expenseColumns}
                                options={{
                                    ...props.options,
                                    downloadOptions: {
                                        filename: 'ProfitLoss LineItem - Expense',
                                    },
                                }}
                            />
                        </MuiThemeProvider>
                    </div>
                )}
                {props.type === 'nonop' && (
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <MuiThemeProvider theme={Constants.getMuiTheme()}>
                            <MUIDataTable
                                title={'Non Operating Expenses'}
                                data={nonOperatingData}
                                columns={expenseColumns}
                                options={props.options}
                            />
                        </MuiThemeProvider>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfitLossLineItem;

import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Label, Input } from 'reactstrap';
import { FormGroup } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Util from '../Util/util';
import * as Constants from '../Util/constants';
import * as propertiesAPI from '../../Api/property';

const PaymentVendor = (props) => {
    const history = useHistory();
    const propertyID = props.location.state ? props.location.state.propertyID : null;
    console.log('propertyID', propertyID);
    const merchantName = props.location.state.merchantName;
    const login = useSelector((state) => state.login);
    const company = login.company;
    const companyID = company.id;

    const [loading, setLoading] = useState(false);
    const [existingMerchants, setExistingMerchants] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState(0);
    const [updated, setUpdated] = useState(false);
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        async function fetchData() {
            if (propertyID === null) {
                history.goBack();
                return;
            }
            setLoading(true);
            const existingMerchants = await propertiesAPI.getMerchants(companyID);
            const key = existingMerchants.find((m) => m.MerchantName === merchantName)?.EpicPayKeysID || 0;
            setExistingMerchants(existingMerchants);
            setSelectedMerchant(key);
            const accountInfo = await propertiesAPI.getAccountInfo(propertyID);
            if (accountInfo) {
                setValue('businessName', accountInfo.CompanyName ? accountInfo.CompanyName : '');
                setValue('firstName', accountInfo.ContactFName ? accountInfo.ContactFName : '');
                setValue('lastName', accountInfo.ContactLName ? accountInfo.ContactLName : '');
                setValue('email', accountInfo.ContactEmail ? accountInfo.ContactEmail : '');
                setValue('phone', accountInfo.PropertyPhone ? accountInfo.PropertyPhone : '');
            }
            setLoading(false);
        }
        fetchData();
    }, [companyID, propertyID, updated, setValue, history]);

    const submitForm = async (data) => {
        setLoading(true);
        const res = await propertiesAPI.boardMerchant({
            propertyID,
            email: data.email,
            companyName: data.businessName,
            phone: data.phone,
            firstName: data.firstName,
            lastName: data.lastName,
            redirectURL: data.email,
        });
        setLoading(false);
        if (res !== 0) {
            NotificationManager.error(res === -1 ? Constants.DEFAULT_ERROR : res.toString(), 'Error');
            return;
        }
        setUpdated(!updated);
        const location = {
            pathname: '/properties/viewall',
        };
        history.push(location);
    };

    const selectMerchant = async () => {
        if (parseInt(selectedMerchant) === 0) {
            NotificationManager.warning('Please, select a merchant.', 'Warning');
            return;
        }
        setLoading(true);
        const res = await propertiesAPI.copyMerchant({
            epicPayKeyID: parseInt(selectedMerchant),
            propertyID,
        });
        if (res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, 'Error');
            return;
        }
        setLoading(false);
        const location = {
            pathname: '/properties/viewall',
        };
        history.push(location);
    };

    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Properties...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <i className="ti-angle-left" style={{ cursor: 'pointer' }} onClick={() => history.goBack()}></i>
                        <h2>
                            <span>Create Payment Vendor Account</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="Use existing merchant account">
                            <div className="row">
                                <div className="col-sm-6">
                                    <Label for="existingMerchant" className="mr-sm-10">
                                        Exisiting Merchant Account
                                    </Label>
                                    <Input
                                        type="select"
                                        id="existingMerchant"
                                        value={selectedMerchant}
                                        onChange={(event) => setSelectedMerchant(event.target.value)}
                                    >
                                        {existingMerchants.find((m) => m.MerchantName === merchantName) ? (
                                            <option
                                                value={
                                                    existingMerchants.find((m) => m.MerchantName === merchantName)
                                                        .EpicPayKeysID
                                                }
                                            >
                                                {existingMerchants.find((m) => m.MerchantName === merchantName).MerchantName}
                                            </option>
                                        ) : (
                                            <option key="0" value="0">
                                                Select
                                            </option>
                                        )}
                                        {existingMerchants.map((obj, idx) => (
                                            <option key={idx} value={obj.EpicPayKeysID}>
                                                {obj.MerchantName}
                                            </option>
                                        ))}
                                    </Input>
                                </div>
                            </div>
                            <Button type="button" color="primary" style={{ marginTop: '10px' }} onClick={selectMerchant}>
                                Select Merchant
                            </Button>
                        </RctCollapsibleCard>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="Bussiness Details">
                            <Form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="businessName" className="mr-sm-10">
                                                Business Name
                                            </Label>
                                            <Controller
                                                name="businessName"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type="text"
                                                        id="businessName"
                                                        style={Util.setErrorStyle(errors.businessName)}
                                                    />
                                                )}
                                            />
                                            {errors.businessName && (
                                                <span style={{ color: 'red' }} role="alert">
                                                    required
                                                </span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-4">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="firstName" className="mr-sm-10">
                                                First Name
                                            </Label>
                                            <Controller
                                                name="firstName"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type="text"
                                                        id="firstName"
                                                        style={Util.setErrorStyle(errors.firstName)}
                                                    />
                                                )}
                                            />
                                            {errors.firstName && (
                                                <span style={{ color: 'red' }} role="alert">
                                                    required
                                                </span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-4">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="lastName" className="mr-sm-10">
                                                Last Name
                                            </Label>
                                            <Controller
                                                name="lastName"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type="text"
                                                        id="lastName"
                                                        style={Util.setErrorStyle(errors.lastName)}
                                                    />
                                                )}
                                            />
                                            {errors.lastName && (
                                                <span style={{ color: 'red' }} role="alert">
                                                    required
                                                </span>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Label for="email" className="mr-sm-10">
                                            Email
                                        </Label>
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{ 
                                                required: 'required' , 
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/i,
                                                    message: "Invalid email address"
                                                }}}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    type="text"
                                                    id="email"
                                                    style={Util.setErrorStyle(errors.email)}
                                                />
                                            )}
                                        />
                                        {errors.email && (
                                            <span style={{ color: 'red' }} role="alert">
                                                {errors.email.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="phone" className="mr-sm-10">
                                            Phone
                                        </Label>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <NumberFormat
                                                    className="form-control"
                                                    {...field}
                                                    format="+1 (###) ###-####"
                                                    mask="_"
                                                    id="phone"
                                                    style={Util.setErrorStyle(errors.phone)}
                                                />
                                            )}
                                        />
                                        {errors.phone && (
                                            <span style={{ color: 'red' }} role="alert">
                                                required
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <Button type="submit" color="primary" style={{ marginTop: '10px' }}>
                                    Create Account
                                </Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default PaymentVendor;


import React, { useState, useEffect, useMemo } from 'react';
import { FormGroup } from '@material-ui/core';
import { Label, Input, Button, ButtonGroup, Form } from 'reactstrap';
import Vimeo from 'react-vimeo-embed';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import * as Constants from '../Util/constants';
import * as helpAPI from '../../Api/help';
import LinearProgress from '../Util/LinearProgress';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',

        marginRight: '-8%',
        transform: 'translate(-50%, -50%)',
    },
};

const VideoTutorial = () => {
    const [videos, setVideos] = useState([]);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [videoLink, setVideoLink] = useState('');
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [uploading, setUploading] = useState(true);
    const [showDelete, setShowDelete] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            let arr = [];
            const helpVideos = await helpAPI.getHelpVideos();
            for (const hv of helpVideos) {
                // if (videoLink === '') setVideoLink(getVideoLink(hv.VideoEmbed));
                arr.push({
                    HelpVideosID: hv.HelpVideosID,
                    VideoName: hv.VideoName,
                    VideoEmbed: hv.VideoEmbed,
                    VideoDescription: hv.VideoDescription,
                });
            }
            setSelectedVideo(arr[0]);
            setVideos(arr);
            setLoading(false);
        }
        fetchData();
    }, []);

    const getVideoLink = (str) => {
        return str.includes('iframe')
            ? str.substring(str.indexOf('src=') + 5, str.includes('width=') ? str.indexOf('width=') - 2 : str.indexOf('?'))
            : str;
    };

    const handleVideoChange = (event) => {
        const id = event.target.value;
        const video = videos.find((v) => id == v.HelpVideosID);
        setSelectedVideo(video);
        const link = getVideoLink(video.VideoEmbed);
        console.log('video.VideoEmbed', video.VideoEmbed);
        console.log('link', link);
        setVideoLink(link);
    };

    const renderVideo = () => {
        if (videoLink) {
            return (
                <Vimeo
                    video={videoLink.toString()}
                    autoplay={false}
                    onError={(err) => {
                        console.log('err', err);
                    }}
                />
            );
        }
    };

    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Video Tutorials...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    const deleteVideo = async () => {
        try {
            await helpAPI.deleteVideo(selectedVideo.HelpVideosID);
            NotificationManager.success(`Successfully deleted a help video.`, 'Success');
            setVideos((vs) => vs.filter((v) => v.HelpVideosID != selectedVideo.HelpVideosID));
            setShowDelete(false);
        } catch (error) {
            NotificationManager.error(`Something went wrong. Try again later`, 'Error');
        }
    };

    const submit = async (data) => {
        const helpVideoIds = videos.map((v) => parseInt(v.HelpVideosID));
        const max = Math.max(...helpVideoIds);
        const payload = { ...data, HelpVideosID: max + 1, LeadSourceCompanyID: 0 };

        if (uploading) {
            if (!data.VideoEmbed || !data.VideoName) {
                NotificationManager.warning(`Video URL and Name are required`, 'Warning');
                return;
            }
            await helpAPI.addHelpVideo(payload);
            NotificationManager.success(`Successfully uploaded a help video.`, 'Success');
            setVideos([...videos, payload]);
            setShowUploadModal(false);
        } else {
            payload.HelpVideosID = selectedVideo.HelpVideosID;
            if (!data.VideoEmbed) payload.VideoEmbed = selectedVideo.VideoEmbed;
            if (!data.VideoName) payload.VideoName = selectedVideo.VideoName;
            if (!data.VideoDescription) payload.VideoDescription = selectedVideo.VideoDescription;
            await helpAPI.updateHelpVideo(payload);
            NotificationManager.success(`Successfully updated a help video.`, 'Success');
            setVideos((vs) => vs.map((v) => (v.HelpVideosID == selectedVideo.HelpVideosID ? payload : v)));
            setShowUploadModal(false);
        }
    };

    const renderModalContent = () => {
        return (
            <Form onSubmit={handleSubmit(submit)}>
                <div className="row">
                    <div className="col-sm-12">
                        <FormGroup className="mr-10 mb-10">
                            <Label for="VideoEmbed" className="mr-sm-10">
                                Video URL Or Video Embed
                            </Label>
                            <Input
                                id="VideoEmbed"
                                defaultValue={uploading ? '' : selectedVideo?.VideoEmbed}
                                {...register('VideoEmbed', { required: false })}
                            />
                            {errors.VideoEmbed && (
                                <span style={{ color: 'red' }} role="alert">
                                    required
                                </span>
                            )}
                        </FormGroup>
                    </div>
                    <div className="col-sm-12">
                        <FormGroup className="mr-10 mb-10">
                            <Label for="VideoName" className="mr-sm-10">
                                Video Name
                            </Label>
                            <Input
                                id="VideoName"
                                defaultValue={uploading ? '' : selectedVideo?.VideoName}
                                {...register('VideoName', { required: false })}
                            />
                            {errors.VideoName && (
                                <span style={{ color: 'red' }} role="alert">
                                    required
                                </span>
                            )}
                        </FormGroup>
                    </div>
                    <div className="col-sm-12">
                        <FormGroup className="mr-10 mb-10">
                            <Label for="VideoDescription" className="mr-sm-10">
                                Video Description
                            </Label>
                            <Input
                                type="textarea"
                                defaultValue={uploading ? '' : selectedVideo?.VideoDescription}
                                rows="6"
                                id="VideoDescription"
                                {...register('VideoDescription', { required: false })}
                            />
                            {errors.VideoDescription && (
                                <span style={{ color: 'red' }} role="alert">
                                    required
                                </span>
                            )}
                        </FormGroup>
                    </div>
                </div>
                <div className="text-right">
                    <Button
                        type="button"
                        onClick={() => setShowUploadModal(false)}
                        color="secondary"
                        size="sm"
                        className="w-auto"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" size="sm" className="w-auto ml-2">
                        Submit
                    </Button>
                </div>
            </Form>
        );
    };

    return (
        <Main>
            <SweetAlert
                warning
                btnSize="sm"
                show={showDelete}
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => deleteVideo()}
                onCancel={() => setShowDelete(false)}
            >
                You won't be able to undo this action.
            </SweetAlert>
            <Modal
                isOpen={showUploadModal}
                onRequestClose={() => setShowUploadModal(false)}
                contentLabel="upload video"
                style={modalStyles}
            >
                {renderModalContent()}
            </Modal>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Video Tutorials</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-xl-6">
                        <FormGroup className="mr-10 mb-10">
                            <Label for="video" className="mr-sm-10">
                                Select Video
                            </Label>

                            <Input name="video" type="select" id="video" onChange={handleVideoChange}>
                                {videos.map((obj, idx) => {
                                    return (
                                        <option key={idx} value={obj.HelpVideosID}>
                                            {obj.VideoName}
                                        </option>
                                    );
                                })}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-sm-6 col-md-6 col-xl-6">
                        <div className="row align-items-end">
                            <ButtonGroup className="mr-10 mb-10 d-flex">
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setUploading(true);
                                        setShowUploadModal(true);
                                    }}
                                >
                                    Upload Video
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup className="mr-10 mb-10 d-flex">
                                <Button
                                    onClick={() => {
                                        setUploading(false);
                                        setShowUploadModal(true);
                                    }}
                                    color="warning"
                                >
                                    Edit Video
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup className="mr-10 mb-10 d-flex">
                                <Button
                                    onClick={() => {
                                        setShowDelete(true);
                                    }}
                                    color="danger"
                                >
                                    Delete Video
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12" style={{ maxWidth: '1000px', margin: '1.5rem auto' }}>
                        {/* {videoLink && (
                            <Vimeo
                                video={videoLink.toString()}
                                autoplay={false}
                                onError={(err) => {
                                    console.log('err', err);
                                }}
                            />
                        )} */}
                        {selectedVideo && <div dangerouslySetInnerHTML={{ __html: selectedVideo.VideoEmbed }} />}
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default VideoTutorial;


import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import { AgChartsReact } from "ag-charts-react";

const TenantTenure = (props) => {
  const [loading, setLoading] = useState(false);
  const [tenureData, setTenureData] = useState([]);
  const [tenureRange, setTenureRange] = useState([
    "Less3Months",
    "3to6Months",
    "6to12Months",
    "12to18Months",
    "18to24Months",
    "2years",
  ]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  const [options, setOptions] = useState({
    data: [],
    series: [
      {
        type: "pie",
        angleKey: "value",
        calloutLabelKey: "label",
        sectorLabelKey: "value",
        sectorLabel: {
          color: "white",
          fontWeight: "bold",
        },
      },
    ],
  });
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getTenantTenure(
        props.reportSearch
      );
      tenureData["Less3Months"] = {
        range: "Under 3 Months",
        numberOfTenants: 0,
        percentage: 0,
      };
      tenureData["3to6Months"] = {
        range: "3-6 Months",
        numberOfTenants: 0,
        percentage: 0,
      };
      tenureData["6to12Months"] = {
        range: "6-12 Months",
        numberOfTenants: 0,
        percentage: 0,
      };
      tenureData["12to18Months"] = {
        range: "12-18 Months",
        numberOfTenants: 0,
        percentage: 0,
      };
      tenureData["18to24Months"] = {
        range: "18-24 Months",
        numberOfTenants: 0,
        percentage: 0,
      };
      tenureData["2years"] = {
        range: "2 Years +",
        numberOfTenants: 0,
        percentage: 0,
      };
      for (let i = 0; i < reportDetails.length; i++) {
        if (reportDetails[i].TenureMonths < 3) {
          tenureData["Less3Months"].numberOfTenants += 1;
        }
        if (
          reportDetails[i].TenureMonths >= 3 &&
          reportDetails[i].TenureMonths < 6
        ) {
          tenureData["3to6Months"].numberOfTenants += 1;
        }
        if (
          reportDetails[i].TenureMonths >= 6 &&
          reportDetails[i].TenureMonths < 12
        ) {
          tenureData["6to12Months"].numberOfTenants += 1;
        }
        if (
          reportDetails[i].TenureMonths >= 12 &&
          reportDetails[i].TenureMonths < 18
        ) {
          tenureData["12to18Months"].numberOfTenants += 1;
        }
        if (
          reportDetails[i].TenureMonths >= 18 &&
          reportDetails[i].TenureMonths < 24
        ) {
          tenureData["18to24Months"].numberOfTenants += 1;
        }
        if (reportDetails[i].TenureMonths >= 24) {
          tenureData["2years"].numberOfTenants += 1;
        }
      }
      tenureData["Less3Months"].percentage =
        Math.round(
          (tenureData["Less3Months"].numberOfTenants / reportDetails.length) *
            100
        ).toString() + "%";
      tenureData["3to6Months"].percentage =
        Math.round(
          (tenureData["3to6Months"].numberOfTenants / reportDetails.length) *
            100
        ).toString() + "%";
      tenureData["6to12Months"].percentage =
        Math.round(
          (tenureData["6to12Months"].numberOfTenants / reportDetails.length) *
            100
        ).toString() + "%";
      tenureData["12to18Months"].percentage =
        Math.round(
          (tenureData["12to18Months"].numberOfTenants / reportDetails.length) *
            100
        ).toString() + "%";
      tenureData["18to24Months"].percentage =
        Math.round(
          (tenureData["18to24Months"].numberOfTenants / reportDetails.length) *
            100
        ).toString() + "%";
      tenureData["2years"].percentage =
        Math.round(
          (tenureData["2years"].numberOfTenants / reportDetails.length) * 100
        ).toString() + "%";

      options.data = tenureRange.map((tenure) => {
        return {
          label: tenureData[tenure].range,
          value: tenureData[tenure].numberOfTenants,
        };
      });
      const formattedData = tenureRange.map((tenure) => {
        return tenureData[tenure];
      });

      setTenureData([
        ...formattedData,
        {
          range: "TOTAL",
          numberOfTenants: reportDetails.length,
          percentage: "100%",
        },
      ]);
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const tenureColumns = [
    { name: "range", label: "Range" },
    { name: "numberOfTenants", label: "Number of Tenants" },
    { name: "percentage", label: "Percentage" },
  ];

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>{props.reportName + " - " + filteredName.PropertyName}</span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Tenant Portal Accounts"}
              data={tenureData}
              columns={tenureColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
      <AgChartsReact options={options} />
    </div>
  );
};

export default TenantTenure;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const ApplicationSummary = (props) => {
  const [loading, setLoading] = useState(false);
  const [appSummData, setAppSummData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getApplicationSummary(
        props.reportSearch
      );
      for (let i = 0; i < reportDetails.length; i++) {
        appSummData.push({
          tenant:
            reportDetails[i].TenantFName + " " + reportDetails[i].TenantLName,
          appliedDate: formatDate(
            reportDetails[i].ProspectStartDate.slice(0, 10)
          ),
          currentStatus: reportDetails[i].tenantsprospect?.Description,
          leadSource: reportDetails[i].leadsource?.LeadSource,
        });
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const appSummColumns = [
    { name: "tenant", label: "Tenant" },
    { name: "appliedDate", label: "Applied Date" },
    { name: "currentStatus", label: "Current Status" },
    { name: "leadSource", label: "Lead Source" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Application Summary"}
              data={appSummData}
              columns={appSummColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default ApplicationSummary;

import React, {useState, useEffect} from 'react';
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Label, Input } from 'reactstrap';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import moment from 'moment';
import { useSelector } from "react-redux";

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Util from '../Util/util';
import * as Constants from '../Util/constants';
import * as tenantsAPI from '../../Api/tenants';

const NotifyAll = () => {
    const login = useSelector((state) => state.login);
    const company = login.company
    const companyID = company.id;
    const propertyID = login.selectedPropertyID;
    const user = login.user;
    const userID = user.id;
    const multiprop = user.notifications.multiProp;

    const [loading, setLoading] = useState(false);
    const [previousMessages, setPreviousMessages] = useState([]);
    const [notifyAll, setNotifyAll] = useState(false);
    const [updated, setUpdated] = useState(false);

    const { handleSubmit, control, formState: { errors }} = useForm();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const previousMessages = await tenantsAPI.getNotifyAllTenants({
                companyID,
                propertyID,
                multiprop
            });
            let arr = [];
            for(const pm of previousMessages) {
                arr.push({
                    date: moment.utc(pm.Date).format("MM/DD/YYYY"),
                    message: pm.Message,
                });
            }
            setPreviousMessages(arr);
            setLoading(false);
        }
        fetchData()
    }, [propertyID, multiprop, companyID, updated]);

    const columns = [
        { name: 'date', label: 'Date', },
        { name: 'message', label: 'Message', },
    ];

    const options = {
        filterType: 'dropdown',
        pagination: false,
        selectableRows: "none",
    }

    const submitForm = async (data) => {
        setLoading(true);
        const res = await tenantsAPI.notifyAllTenants({
            notifyAcrossAllProperties: notifyAll,
            userID,
            propertyID,
            companyID: notifyAll ? companyID : 0,
            message: data.message
        });
        setLoading(false);
        if(res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        setLoading(false);
        setUpdated(!updated);
        NotificationManager.success("Tenants Notified!", "Success")
    }

    const renderNotifyAcrossAllProperties = () => {
        if(multiprop) {
            return (
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={notifyAll} />} 
                            label="Notify tenants across all properties." 
                            onClick={() => setNotifyAll(!notifyAll)}
                        />
                    </FormGroup>
                </div>
            );
        }
    }

    if(loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Loading Notify All Tenants..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Notify All Tenants</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="Company Details">
                            <Form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="message" className="mr-sm-10">Message</Label>
                                            <Controller
                                                name="message"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input {...field} type="textarea" rows={4} id="message" className="form-control" 
                                                        style={Util.setErrorStyle(errors.message)} /> 
                                                )}
                                            />
                                            {errors.message && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                                {renderNotifyAcrossAllProperties()}
                                <Button type="submit" color="primary" style={{marginTop: '10px'}}>Send Emails and Text</Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <MuiThemeProvider theme={Constants.getMuiTheme()}>                                                    
                            <MUIDataTable
                                title={`Previous messages - ${previousMessages.length}`}
                                data={previousMessages}
                                columns={columns}
                                options={options}
                            />
                        </MuiThemeProvider>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default NotifyAll;
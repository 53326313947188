import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'reactstrap-date-picker';
import moment from 'moment';
import { Button, Form, Label, Input } from 'reactstrap';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { Switch } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as tenantAPI from '../../Api/tenants';
import * as billsAPI from '../../Api/bills';
import * as Util from '../Util/util';
import * as MyFuncs from '../Util/myFunctions';

const addFeeStyles = {
    paddingLeft: '1%',
    paddingRight: '1%',
    paddingTop: '1%',
    paddingBottom: '2%',
};

const ApplyElectricFee = (props) => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const user = login.user;
    const company = login.company;
    const propertyID = login.selectedPropertyID;
    const userID = user.id;
    const companyID = company.id;

    const [loading, setLoading] = useState(true);
    const [properties, setProperties] = useState([]);
    const [tenants, setTenants] = useState([]);

    const pns = useSelector((state) => state.login.properties);
    const [filteredName] = pns.filter((property) => property.PropertyID === propertyID);
    const PropertyName = filteredName.PropertyName;

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setProperties(await billsAPI.getProperties(userID));

            const tenants = await tenantAPI.getTenants({
                multiprop: false,
                userID,
                propertyID,
                companyID,
            });
            let arr = [];

            for (const t of tenants) {
                arr.push({
                    value: t.TenantID,
                    label: `${t.TenantFName} ${t.TenantLName} - Unit: ${t.UnitName}`,
                    email: t.TenantEmail,
                });
            }
            setTenants(arr);
            setLoading(false);
        }
        fetchData();
    }, [propertyID, userID, companyID]);

    const submitForm = async (data) => {
        if (data.tenantID.length === 0) {
            NotificationManager.warning('Please select at least one tenant.', 'Warning!');
            return;
        }

        let tenantsArr = [];
        data.tenantID.map((element) =>
            tenantsArr.push({ tenantID: element.value, tenantName: element.label, tenantEmail: element.email }),
        );

        const rate = MyFuncs.getFormattedNum(data.rate);
        const meter_reading = parseFloat(data.meter_reading.replace(/,/gi, ''));
        const index = tenants.findIndex(t=>t.value == data.tenantID[0].value);
        console.log("index",index)
        const nextTenant = index === tenants.length ? tenants[0] : tenants[index+1]
        console.log("nextTenant",nextTenant)
        // Add the Fee
        setLoading(true);
        const dataToSubmit = {
            tenants: tenantsArr,
            rate,
            read_date: data.read_date,
            meter_reading,
            userID,
            propertyID: parseInt(propertyID),
            propertyName: PropertyName,
        };
        console.log('data', dataToSubmit);
        const res = await tenantAPI.applyElectricCharge(dataToSubmit);

        if (res !== 0) {
            NotificationManager.error('Error processing your request. Please, contact us.', 'Error!');
            return;
        }

        NotificationManager.success('Electric charge applied sucessfully.', 'Success!');
        setLoading(false);
        reset(
            {
                read_date: moment.utc(data.read_date).format('YYYY-MM-DD'),
                rate: data.rate,
                tenantID: [nextTenant],
                meter_reading: 0,
            },
            {
                keepErrors: false,
                keepDirty: false,
            },
        );
    };

    const handleSelectTenant = async (selected) => {
        setValue('tenantID', selected);
        console.log("selected", selected)
        if (selected && selected.length > 0 && selected[selected.length - 1].value) {
            const lastCharge = await tenantAPI.getLastElectricCharge(selected[selected.length - 1].value);
            if (lastCharge) setValue('rate', parseFloat(lastCharge.Rate.toFixed(2)));
        }
    };

    const render = () => {
        if (loading) {
            return (
                <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Apply Additional Fee...'}>
                    <LinearProgress />
                </RctCollapsibleCard>
            );
        } else {
            return (
                <Main>
                    <div style={addFeeStyles}>
                        <div className="page-title d-flex justify-content-between align-items-center">
                            <div className="page-title-wrap">
                                <i
                                    className="ti-angle-left"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => history.push('/tenants/viewAll')}
                                ></i>
                                <h2>
                                    <span>Apply Electric Charge</span>
                                </h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <RctCollapsibleCard>
                                    <Form onSubmit={handleSubmit(submitForm)}>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Label for="tenantID" className="mr-sm-10">
                                                    Tenant
                                                </Label>
                                                <Controller
                                                    name="tenantID"
                                                    control={control}
                                                    defaultValue={[]}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            isMulti
                                                            options={tenants}
                                                            className="basic-multi-select form-control no-padding"
                                                            classNamePrefix="select"
                                                            onChange={handleSelectTenant}
                                                            style={Util.setErrorStyle(errors.tenantID)}
                                                        />
                                                    )}
                                                />
                                                {errors.tenantID && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-2">
                                                <Label for="read_date" className="mr-sm-10">
                                                    Read Date
                                                </Label>
                                                <Controller
                                                    name="read_date"
                                                    control={control}
                                                    defaultValue={moment.utc().format('YYYY-MM-DD')}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            id="read_date"
                                                            style={Util.setErrorStyle(errors.read_date)}
                                                        />
                                                    )}
                                                />
                                                {errors.read_date && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-sm-3">
                                                <Label for="meter_reading" className="mr-sm-10">
                                                    Meter Reading
                                                </Label>
                                                <Controller
                                                    name="meter_reading"
                                                    control={control}
                                                    defaultValue={0}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <NumberFormat
                                                            {...field}
                                                            id="meter_reading"
                                                            style={Util.setErrorStyle(errors.meter_reading)}
                                                            thousandSeparator={true}
                                                            className="form-control"
                                                        />
                                                    )}
                                                />
                                                {errors.meter_reading && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </div>

                                            <div className="col-sm-3">
                                                <Label for="rate" className="mr-sm-10">
                                                    Rate
                                                </Label>
                                                <Controller
                                                    name="rate"
                                                    control={control}
                                                    defaultValue={0}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <NumberFormat
                                                            {...field}
                                                            id="rate"
                                                            style={Util.setErrorStyle(errors.rate)}
                                                            prefix="$"
                                                            thousandSeparator={true}
                                                            className="form-control"
                                                        />
                                                    )}
                                                />
                                                {errors.rate && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <Button type="submit" color="primary" style={{ marginTop: '20px' }}>
                                            Apply Charge
                                        </Button>
                                    </Form>
                                </RctCollapsibleCard>
                            </div>
                        </div>
                    </div>
                </Main>
            );
        }
    };

    return render();
};

export default ApplyElectricFee;


import React, {useState, useEffect} from 'react';
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Label, Input } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from 'react-modal';
import MatButton from '@material-ui/core/Button';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Constants from '../Util/constants';
import * as unitsAPI from '../../Api/units';
import * as Util from '../Util/util';

const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-8%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '400px',
    },
};

const EditUnit = (props) => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const propertyID = login.selectedPropertyID;
    const unitID = props.location.state ? props.location.state.unitID : null;
    const unitTypeID = props.location.state ? props.location.state.unitTypeID : null;
        
    const [loading, setLoading] = useState(false);
    const [residenceType, setResidenceType ] = useState([]);
    const [unitFurnished, setUnitFurnished ] = useState([]);
    const [smoking, setSmoking ]= useState([]);
    const [ada, setAda ] = useState([]);
    const [pets, setPets ] = useState([]);
    const [parking, setParking ] = useState([]);
    const [laundry, setLaundry ] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalUnits, setModalUnits] = useState([]);
    const [modalMsg, setModalMsg] = useState('');
    const [updated, setUpdated] = useState(false);

    const { handleSubmit, control, setValue, getValues, formState: { errors }} = useForm();

    useEffect(() => {
        async function fetchData() {
            if(unitID === null || unitTypeID === null) {
                history.goBack();
                return;
            }
            setLoading(true);
            const unit = await unitsAPI.getEditUnit(unitID);
            if(unit) {
                setValue('unitName', unit.UnitName);
                setValue('unitType', unit.UnitType);
                setValue('unitCharge', unit.UnitCharge ? parseFloat(unit.UnitCharge).toFixed(2) : 0);
                setValue('adminFee', unit.AdminFee ? parseFloat(unit.AdminFee).toFixed(2) : 0);
                setValue('hoaFee', unit.HOAFee ? parseFloat(unit.HOAFee).toFixed(2) : 0);
                setValue('maxTenants', unit.MaxAllowedPeople ? unit.MaxAllowedPeople : '');
                setValue('residenceType', unit.UnitResidenceTypeID ? unit.UnitResidenceTypeID : '');
                setValue('totalBedrooms', unit.TotalBedrooms ? unit.TotalBedrooms : '');
                setValue('totalBathrooms', unit.TotalBathrooms ? unit.TotalBathrooms : '');
                setValue('squareFootage', unit.SquareFootage ? unit.SquareFootage : '');
                setValue('furnished', unit.IsFurnished ? parseInt(unit.IsFurnished) : '');
                setValue('smoking', unit.isSmokingAllowed ? parseInt(unit.isSmokingAllowed) : '');
                setValue('ada', unit.IsWheelChairAccessible ? parseInt(unit.IsWheelChairAccessible) : '');
                setValue('pets', unit.petsAllowed ? parseInt(unit.petsAllowed) : '');
                setValue('leaseDuration', unit.LeaseDurationMonth ? unit.LeaseDurationMonth : '');
                setValue('parking', unit.ParkingType);
                setValue('laundry', unit.LaundryType);
            }
            const unitSelectValues = await unitsAPI.getUnitSelectValues();
            if(unitSelectValues) {
                setResidenceType(unitSelectValues.residenceTypes);
                setUnitFurnished(unitSelectValues.unitFurnished);
                setSmoking(unitSelectValues.smoking);
                setAda(unitSelectValues.ada);
                setPets(unitSelectValues.pets);
                setParking(unitSelectValues.parking);
                setLaundry(unitSelectValues.laundry);
            }
            setLoading(false);
        }
        fetchData();
    }, [unitID, unitTypeID, setValue, updated, history])

    const submitForm = async (data) => {
        setLoading(true);
        const uCharge = data.unitCharge[0] === '$' ? data.unitCharge.substring(1, data.unitCharge.length) : data.unitCharge;
        const uAdmin = data.adminFee[0] === '$' ? data.adminFee.substring(1, data.adminFee.length) : data.adminFee;
        const uHOA = data.hoaFee[0] === '$' ? data.hoaFee.substring(1, data.hoaFee.length) : data.hoaFee;
        const res = await unitsAPI.editUnit({
            unitName: data.unitName,
            unitType: data.unitType,
            propertyID: propertyID,
            unitCharge: parseFloat(uCharge.replace(",", "")).toFixed(2),
            adminFee: parseFloat(uAdmin.replace(",", "")).toFixed(2),
            isFurnished: data.furnished,
            isSmokingAllowed: data.smoking,
            isWheelChairAccessible: data.ada,
            petsAllowed: data.pets,
            maxAllowedPeople: data.maxTenants,
            leaseDurationMonth: data.leaseDuration,
            parkingType: data.parking,
            laundryType: data.laundry,
            squareFootage: data.squareFootage,
            unitResidenceTypeID: data.residenceType,
            totalBedrooms: data.totalBedrooms,
            totalBathrooms: data.totalBathrooms,
            HOAFee: parseFloat(uHOA.replace(",", "")).toFixed(2),
            unitTypeID,
            unitID,
        });
        setLoading(false);
        console.log(res);
        if(res === -1) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        if(res.msg !== undefined) {
            NotificationManager.error(res.msg, "Error");
            return;
        }
        if(res.saved !== undefined && res.saved) {
            NotificationManager.success("Unit Updated!", "Success");
            setUpdated(!updated);
            return;
        }
        if(res.validatedUnitType !== undefined) {
            // Need to show a modal with updates to be updated.
            const unitsArr = [];
            for(const unit of res.untis) {
                unitsArr.push({
                    unitID: unit.UnitID,
                    unitName: unit.UnitName,
                    checked: unit.UnitID === unitID,
                });
            }
            setModalUnits(unitsArr);
            setModalMsg(`${res.fields} updated!`);
            setOpenModal(true);
        } else {
            NotificationManager.success('Unit updated!', "Success");
            setUpdated(!updated);
        }
    }

    const updateUnits = async () => {
        setLoading(true);
        const uCharge = getValues("unitCharge")[0] === '$' ? getValues("unitCharge").substring(1, getValues("unitCharge").length) : getValues("unitCharge");
        const uAdmin = getValues("adminFee")[0] === '$' ? getValues("adminFee").substring(1, getValues("adminFee").length) : getValues("adminFee");
        const uHOA = getValues("hoaFee")[0] === '$' ? getValues("hoaFee").substring(1, getValues("hoaFee").length) : getValues("hoaFee");
        const unitsToUpdate = [...modalUnits].filter(u => u.checked);
            console.log(uAdmin);
        const res = await unitsAPI.confirmUpdateUnit({
            unitName: getValues("unitName"),
            unitType: getValues("unitType"),
            propertyID: propertyID,
            unitCharge: parseFloat(uCharge.replace(",", "")).toFixed(2),
            adminFee: parseFloat(uAdmin.replace(",", "")).toFixed(2),
            isFurnished: getValues("furnished"),
            isSmokingAllowed: getValues("smoking"),
            isWheelChairAccessible: getValues("ada"),
            petsAllowed: getValues("pets"),
            maxAllowedPeople: getValues("maxTenants"),
            leaseDurationMonth: getValues("leaseDuration"),
            parkingType: getValues("parking"),
            laundryType: getValues("laundry"),
            squareFootage: getValues("squareFootage"),
            unitResidenceTypeID: getValues("residenceType"),
            totalBedrooms: getValues("totalBedrooms"),
            totalBathrooms: getValues("totalBathrooms"),
            HOAFee: parseFloat(uHOA.replace(",", "")).toFixed(2),
            unitTypeID,
            unitID,
            updateUnits: unitsToUpdate,
            addNewUnitType: modalUnits.length !== unitsToUpdate.length,
        });
        setLoading(false);
        if(res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        NotificationManager.success('Units updated!', "Success");
        setModalMsg("");
        setOpenModal(false);
        setUpdated(!updated);
    }

    const renderModalContent = () => {
        return (
            <Form>
                <p>
                    {modalMsg}<br/>
                    Select units that will be updated.
                </p>
                <MatButton color="primary" onClick={() => {
                    const temp = [...modalUnits]
                    temp.forEach(unit => unit.checked = true);
                    setModalUnits(temp);
                }}>                            
                    Select All
                </MatButton>
                <hr/>
                <div className="row">
                    {modalUnits.map((obj, idx) => {
                        return (
                            <div key={idx} className="col-sm-12 col-md-12 col-xl-12">
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox value={obj.unitID} checked={obj.checked} />} 
                                        label={obj.unitName} 
                                        onClick={() => {
                                            const temp = [...modalUnits];
                                            temp[idx].checked = !modalUnits[idx].checked
                                            setModalUnits(temp);
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        )
                    })}
                </div>
                <hr/>
                <Button type="button" color="primary" size="sm" className="w-auto" onClick={updateUnits}>Update</Button>
                <Button style={{marginLeft: '10px'}} type="button" color="danger" size="sm" className="w-auto" onClick={() => {
                    setModalMsg("");
                    setOpenModal(false);
                }}>
                    Cancel
                </Button>
            </Form>
        )
    }

    if(loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Loading Edit Unit..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <>
        <Modal
            isOpen={openModal}
            onRequestClose={() => setOpenModal(false)}
            contentLabel="Unit type details updated."
            style={modalStyles}
        >
            {renderModalContent()}
        </Modal>
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <i className="ti-angle-left" style={{cursor: 'pointer'}} onClick={() => history.goBack()}></i>
                        <h2>
                            <span>Edit Unit</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="Unit Details">
                            <Form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="unitName" className="mr-sm-10">Unit Name</Label>
                                            <Controller
                                                name="unitName"
                                                control={control}
                                                rules={{required: true}}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="unitName" style={Util.setErrorStyle(errors.unitName)} />
                                                )}
                                            />
                                            {errors.unitName && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="unitType" className="mr-sm-10">Unit Type</Label>
                                            <Controller
                                                name="unitType"
                                                control={control}
                                                rules={{required: true}}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="unitType" style={Util.setErrorStyle(errors.unitType)} />
                                                )}
                                            />
                                            {errors.unitType && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-2">
                                        <Label for="unitCharge" className="mr-sm-10">Unit Charge</Label>
                                        <Controller
                                            name="unitCharge"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <NumberFormat {...field} className="form-control" id="unitCharge" thousandSeparator={true} prefix={'$'} 
                                                    style={Util.setErrorStyle(errors.unitCharge)} />
                                            )}
                                        />
                                        {errors.unitCharge && (
                                            <span style={{ color: "red" }} role="alert">required</span>
                                        )}
                                    </div>
                                    <div className="col-sm-2">
                                        <Label for="adminFee" className="mr-sm-10">Admin Fee</Label>
                                        <Controller
                                            name="adminFee"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <NumberFormat {...field} className="form-control" id="adminFee" thousandSeparator={true} prefix={'$'} 
                                                    style={Util.setErrorStyle(errors.adminFee)} />
                                            )}
                                        />
                                        {errors.adminFee && (
                                            <span style={{ color: "red" }} role="alert">required</span>
                                        )}
                                    </div>
                                    <div className="col-sm-2">
                                        <Label for="hoaFee" className="mr-sm-10">HOA Fee</Label>
                                        <Controller
                                            name="hoaFee"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <NumberFormat {...field} className="form-control" id="hoaFee" thousandSeparator={true} prefix={'$'} />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="maxTenants" className="mr-sm-10">Max Tenants</Label>
                                            <Controller
                                                name="maxTenants"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="number" id="maxTenants" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="residenceType" className="mr-sm-10">Residence Type</Label>
                                        <Controller
                                            name="residenceType"
                                            control={control}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="residenceType">
                                                    {residenceType.map((obj, idx) => 
                                                        <option key={idx} value={obj.UnitResidenceTypeID}>{obj.Residence}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="totalBedrooms" className="mr-sm-10">Total Bedrooms</Label>
                                            <Controller
                                                name="totalBedrooms"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="totalBedrooms" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-2">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="totalBathrooms" className="mr-sm-10">Total Baths</Label>
                                            <Controller
                                                name="totalBathrooms"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="totalBathrooms" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-2">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="squareFootage" className="mr-sm-10">Square Footage</Label>
                                            <Controller
                                                name="squareFootage"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="squareFootage" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Label for="furnished" className="mr-sm-10">Furnished</Label>
                                        <Controller
                                            name="furnished"
                                            control={control}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="furnished">
                                                    {unitFurnished.map((obj, idx) => 
                                                        <option key={idx} value={obj.UnitFurnishedID}>{obj.Furnished}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="smoking" className="mr-sm-10">Smoking</Label>
                                        <Controller
                                            name="smoking"
                                            control={control}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="smoking">
                                                    {smoking.map((obj, idx) => 
                                                        <option key={idx} value={obj.UnitSmokingID}>{obj.Smoking}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="ada" className="mr-sm-10">ADA</Label>
                                        <Controller
                                            name="ada"
                                            control={control}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="ada">
                                                    {ada.map((obj, idx) => 
                                                        <option key={idx} value={obj.UnitADAID}>{obj.ADA}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="pets" className="mr-sm-10">Pets</Label>
                                        <Controller
                                            name="pets"
                                            control={control}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="pets">
                                                    {pets.map((obj, idx) => 
                                                        <option key={idx} value={obj.UnitPETSID}>{obj.PETS}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="leaseDuration" className="mr-sm-10">Lease Duration (Months)</Label>
                                            <Controller
                                                name="leaseDuration"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="leaseDuration" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="parking" className="mr-sm-10">Parking</Label>
                                        <Controller
                                            name="parking"
                                            control={control}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="parking">
                                                    {parking.map((obj, idx) => 
                                                        <option key={idx} value={obj.Parking}>{obj.Parking}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="laundry" className="mr-sm-10">Laundry</Label>
                                        <Controller
                                            name="laundry"
                                            control={control}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="laundry">
                                                    {laundry.map((obj, idx) => 
                                                        <option key={idx} value={obj.Laundry}>{obj.Laundry}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                </div>
                                <Button type="submit" color="primary" style={{marginTop: '10px'}}>Save Changes</Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                </div>
            </div>
        </Main>
        </>
    )
}

export default EditUnit;

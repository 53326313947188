import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import AssignmentIndOutlined from "@material-ui/icons/AssignmentIndOutlined";

const LeaseExpiration = (props) => {
  const [loading, setLoading] = useState(false);
  const [leaseExData, setLeaseExData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getLeaseExpiration(
        props.reportSearch
      );
      for (let i = 0; i < reportDetails.length; i++) {
        if (
          Math.floor(
            (new Date().getTime() -
              new Date(reportDetails[i].tenants[0].MoveOutDate).getTime()) /
              (24 * 60 * 60 * 1000)
          ) >= 0
        ) {
          leaseExData.push({
            tenant:
              reportDetails[i].tenants[0].TenantFName +
              " " +
              reportDetails[i].tenants[0].TenantLName +
              " " +
              reportDetails[i].tenants[0].id,
            unit: reportDetails[i].UnitName,
            unitType: reportDetails[i]?.unittype?.UnitType,
            moveOutDate: formatDate(reportDetails[i].tenants[0].MoveOutDate),
            noticeGiven:
              reportDetails[i].tenants[0].NoticeGiven === 0 ? (
                ""
              ) : (
                <CheckIcon size="medium" />
              ),
            onEviction:
              reportDetails[i].tenants[0].EvictionFiled === 0 ? (
                ""
              ) : (
                <CheckIcon size="medium" />
              ),
            rent: currencyFormat(+reportDetails[i].tenants[0].RentalAmount),
            housing: currencyFormat(+reportDetails[i].tenants[0].HousingAmount),
            utility: currencyFormat(+reportDetails[i].tenants[0].UtilityCharge),
            days: Math.floor(
              (new Date().getTime() -
                new Date(reportDetails[i].tenants[0].MoveOutDate).getTime()) /
                (24 * 60 * 60 * 1000)
            ),
            mktRent: currencyFormat(+reportDetails[i]?.unittype.UnitCharge),
            comments: reportDetails[i].tenants[0].ProspectComments,
            edit: (
              <IconButton
                aria-label="Print"
                onClick={() => {
                  const location = {
                    pathname: "/tenants/editTenant",
                    state: {
                      tenantID: reportDetails[i].tenants[0].id,
                      tenantName: `${reportDetails[i].tenants[0].TenantFName} ${reportDetails[i].tenants[0].TenantLName}`,
                    },
                  };
                  history.push(location);
                }}
              >
                <Edit />
              </IconButton>
            ),
            prelease: (
              <IconButton
                aria-label="Edit"
                onClick={() => {
                  const location = {
                    pathname: "/preLease",
                    state: {
                      unitID: reportDetails[i].id,
                    },
                  };
                  history.push(location);
                }}
              >
                <AssignmentIndOutlined style={{ color: "#228B22" }} />
              </IconButton>
            ),
          });
        }
      }
      leaseExData.push({ tenant: "TOTAL", unit: leaseExData.length });
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const leaseExColumns = [
    { name: "tenant", label: "Tenant" },
    { name: "unit", label: "Unit" },
    { name: "unitType", label: "Unit Type" },
    { name: "moveOutDate", label: "Move Out Date" },
    { name: "noticeGiven", label: "Notice Given" },
    { name: "onEviction", label: "On Eviction" },
    { name: "rent", label: "Rent" },
    { name: "housing", label: "Housing" },
    { name: "utility", label: "Utility" },
    { name: "days", label: "Days" },
    { name: "mktRent", label: "Mkt Rent" },
    { name: "comments", label: "Comments" },
    { name: "edit", label: "Edit" },
    { name: "prelease", label: "Prelease" },
  ];

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Lease Expirations"}
              data={leaseExData}
              columns={leaseExColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default LeaseExpiration;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { currencyFormat, formatDate } from './helperFunctions';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';

const SecurityDeposit = (props) => {
    const [loading, setLoading] = useState(false);
    const propertyName = useSelector((state) => state.login.properties);
    const [filteredName] = propertyName.filter((property) => property.PropertyID === props.propertyId);
    const [tableData, setTableData] = useState({});

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            var data = {};
            for (let i = 0; i < props.selectedValue.length; i++) {
                let securityData = [];
                const reportDetails = await reportsAPI.getSecurityDeposit(props.reportSearch);
                const reportTotal = await reportsAPI.getSecurityDepositTotal(props.reportSearch);
                for (let i = 0; i < reportDetails.length; i++) {
                    securityData.push({
                        unit: reportDetails[i].tenant.unit.UnitName,
                        tenant: reportDetails[i].tenant.TenantFName + ' ' + reportDetails[i].tenant.TenantLName,
                        securityDeposit: currencyFormat(+reportDetails[i].DebitSecurity),
                        tenantBalance: currencyFormat(+reportDetails[i].TenantBalance),
                    });
                }
                securityData.push({
                    tenant: 'TOTAL',
                    securityDeposit: currencyFormat(+reportTotal[0].DebitSecurity),
                });
                data[props.selectedValue[i].name] = securityData;
            }
            setTableData(data);
            setLoading(false);
        }
        fetchData();
    }, [props.selectedValue]);
    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Reports...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }
    const securityColumns = [
        { name: 'unit', label: 'Unit' },
        { name: 'tenant', label: 'Tenant' },
        { name: 'securityDeposit', label: 'Security Deposit' },
        { name: 'tenantBalance', label: 'Tenant Balance' },
    ];

    return (
        <div>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        {/* <span>{props.reportName + ' - ' + filteredName.PropertyName}</span> */}
                        <span>{props.reportName}</span>
                    </h2>
                </div>
            </div>
            <div className="row">
                {props.selectedValue.map((property) => {
                    return (
                        <div className="col-sm-12 col-md-12 col-xl-12 mb-4">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={property.name}
                                    data={tableData[property.name]}
                                    columns={securityColumns}
                                    options={{
                                        ...props.reportOptions,
                                        responsive: 'simple',
                                        downloadOptions: {
                                            filename:
                                                props.reportName + ' - ' + props.selectedValue.map((p) => p.name).join(', '),
                                        },
                                        onDownload: (buildHead, buildBody, columns, data) => {
                                            // Additional rows to be added to the CSV
                                            var additionalData = [];
                                            for (let i = 0; i < Object.values(tableData).length; i++) {
                                                const data = Object.values(tableData)[i];
                                                additionalData = [...additionalData, { unit: Object.keys(tableData)[i] }];
                                                additionalData.push({ unit: '' });
                                                additionalData = [...additionalData, ...data];
                                                additionalData.push({ unit: '' });
                                            }

                                            // Combine original data with additional rows
                                            const combinedData = additionalData.map((item) => {
                                                return {
                                                    data: Object.values(item),
                                                };
                                            });
                                            console.log('combinedData', combinedData.length);
                                            // Create custom CSV head and body
                                            const csvHead = buildHead(columns);
                                            const csvBody = buildBody(combinedData, columns);

                                            return `${csvHead}${csvBody}`; // Combine head and body to form the CSV content
                                        },
                                    }}
                                />
                            </MuiThemeProvider>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SecurityDeposit;


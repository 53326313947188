import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const EvictionSummary = (props) => {
  const [loading, setLoading] = useState(false);
  const [evictionData, setEvictionData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getEvictionSummary(
        props.reportSearch
      );
      for (let i = 0; i < reportDetails.length; i++) {
        const lateDate = await reportsAPI.getEvictionLate({
          tenID: reportDetails[i].id.toString(),
        });
        evictionData.push({
          tenant:
            reportDetails[i].TenantFName + " " + reportDetails[i].TenantLName,
          evictionFiled: formatDate(reportDetails[i].EvictionFiledDate),
          moveInDate: reportDetails[i].MoveInDate,
          leaseEndDate: reportDetails[i].LeaseEndDate,
          source: reportDetails[i].leadsource?.LeadSource,
          leasingAgent:
            reportDetails[i].user.UserFName +
            " " +
            reportDetails[i].user.UserLName,
          lateAfterXDays: lateDate
            ? Math.floor(
                (new Date().getTime() -
                  new Date(lateDate[0].TenantTransactionDate).getTime()) /
                  (24 * 60 * 60 * 1000)
              )
            : "",
          daysAsTenant: Math.floor(
            (new Date().getTime() -
              new Date(reportDetails[i].MoveInDate).getTime()) /
              (24 * 60 * 60 * 1000)
          ),
        });
      }
      evictionData.push({
        lateAfterXDays: "TOTAL",
        daysAsTenant: reportDetails.length,
      });
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const evictionColumns = [
    { name: "tenant", label: "Tenant" },
    { name: "evictionFiled", label: "Eviction Filed" },
    { name: "moveInDate", label: "Move In Date" },
    { name: "leaseEndDate", label: "Lease End Date" },
    { name: "source", label: "Source" },
    { name: "leasingAgent", label: "Leasing Agent" },
    { name: "lateAfterXDays", label: "Late after X days" },
    { name: "daysAsTenant", label: "Days as Tenant" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Eviction Summary"}
              data={evictionData}
              columns={evictionColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default EvictionSummary;

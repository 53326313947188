import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const MoveInOut = (props) => {
  const [loading, setLoading] = useState(false);
  const [moveInData, setMoveInData] = useState([]);
  const [moveOutData, setMoveOutData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportMoveIn = await reportsAPI.getMoveIn(props.reportSearch);
      const reportMoveOut = await reportsAPI.getMoveOut(props.reportSearch);
      for (let i = 0; i < reportMoveIn.length; i++) {
        moveInData.push({
          tenant:
            reportMoveIn[i].TenantFName + " " + reportMoveIn[i].TenantLName,
          unit: reportMoveIn[i].unit.UnitName,
          unitType: reportMoveIn[i].unit.unittype.UnitType,
          moveInDate: formatDate(reportMoveIn[i].MoveInDate),
          moveOutDate: formatDate(reportMoveIn[i].MoveOutDate),
          leaseStartDate: formatDate(reportMoveIn[i].LeaseStartDate),
          leaseEndDate: formatDate(reportMoveIn[i].LeaseEndDate),
          leaseAgent: reportMoveIn[i].user
            ? reportMoveIn[i].user.UserFName +
              " " +
              reportMoveIn[i].user.UserLName
            : "",
        });
      }
      for (let i = 0; i < reportMoveOut.length; i++) {
        moveOutData.push({
          tenant:
            reportMoveOut[i].TenantFName + " " + reportMoveOut[i].TenantLName,
          unit: reportMoveOut[i].unit.UnitName,
          reason: reportMoveOut[i].ProspectComments,
          unitType: reportMoveOut[i].unit.unittype.UnitType,
          moveInDate: formatDate(reportMoveOut[i].MoveInDate),
          moveOutDate: formatDate(reportMoveOut[i].MoveOutDate),
          leaseStartDate: formatDate(reportMoveOut[i].LeaseStartDate),
          leaseEndDate: formatDate(reportMoveOut[i].LeaseEndDate),
          leaseAgent: reportMoveOut[i].user
            ? reportMoveOut[i].user.UserFName +
              " " +
              reportMoveOut[i].user.UserLName
            : "",
        });
      }
      moveInData.push({
        leaseAgent: `Total Move Ins: ${moveInData.length}`,
      });
      moveOutData.push({
        leaseAgent: `Total Move Outs: ${moveOutData.length}`,
      });
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const moveInColumns = [
    { name: "tenant", label: "Tenant" },
    { name: "unit", label: "Unit" },
    { name: "unitType", label: "Unit Type" },
    { name: "moveInDate", label: "Move In Date" },
    { name: "moveOutDate", label: "Move Out Date" },
    { name: "leaseStartDate", label: "Lease Start Date" },
    { name: "leaseEndDate", label: "Lease End Date" },
    { name: "leaseAgent", label: "Lease Agent" },
  ];
  const moveOutColumns = [
    { name: "tenant", label: "Tenant" },
    { name: "unit", label: "Unit" },
    { name: "unitType", label: "Unit Type" },
    { name: "reason", label: "Reason" },
    { name: "moveInDate", label: "Move In Date" },
    { name: "moveOutDate", label: "Move Out Date" },
    { name: "leaseStartDate", label: "Lease Start Date" },
    { name: "leaseEndDate", label: "Lease End Date" },
    { name: "leaseAgent", label: "Lease Agent" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Move Ins"}
              data={moveInData}
              columns={moveInColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Move Outs"}
              data={moveOutData}
              columns={moveOutColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default MoveInOut;

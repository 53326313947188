import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import moment from 'moment';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import * as Constants from '../Util/constants';
import LinearProgress from '../Util/LinearProgress';
import * as companyAPI from '../../Api/company';

const LicenseAgreement = () => {
    const login = useSelector((state) => state.login);
    const company = login.company
    const companyID = company.id;

    const [loading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState('');
    const [partnerDefaults, setPartnerDefaults] = useState(null);

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const licenseAgreement = await companyAPI.getLicenseAgreement();
            if(licenseAgreement) {
                setEndDate(moment.utc(licenseAgreement.EffectiveDate).format("MM/DD/YYYY"));
            }    
            setPartnerDefaults(await companyAPI.getCompanyPartnerDefaults(companyID));
            setLoading(false);
        }
        fetchData()
    }, [companyID]);

    if(loading) {
        return (
            <RctCollapsibleCard
            colClasses="col-xs-12 col-sm-12 col-md-12"
            heading={"Loading License Agreement..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>License Agreement</span>
                        </h2>
                    </div>
                </div>
                <RctCollapsibleCard
                    colClasses="col-xs-12 col-sm-12 col-md-12"
                    heading={"Terms of use"}
                >
                    <div>
                        <p>
                            This Terms of Service agreement was executed on {endDate}  and remains in effect.
                        </p>
                        <p>
                            BY PRESSING 'I AGREE' CUSTOMER AGREES TO ENTER INTO THIS AGREEMENT. IF CUSTOMER DOES NOT AGREE TO THESE TERMS, DO NOT PRESS ‘I AGREE’ AND CUSTOMER MAY NOT ACCESS OR USE THE SERVICE. This Master Subscription Services Agreement is between {partnerDefaults ? partnerDefaults.PartnerName : ''}, a {partnerDefaults ? partnerDefaults.FormedIn : ''} {partnerDefaults ? partnerDefaults.CorporationType : ''} ({partnerDefaults ? partnerDefaults.PartnerName : ''}), and the entity or individual agreeing to these terms (Customer). It is dated as the date Customer agrees to this agreement.
                        </p>
                        <p>
                            <b>1. ONLINE PROPERTY MANAGEMENT SOFTWARE.</b> This agreement provides Customer access to a proprietary online software service for managing real estate properties, with the following functionality (depending on the version selected):<br/>
                                a.	Maintain accounting records for each property,<br/>
                                b.	Track tenants, association owners, charges and payments,<br/>
                                c.	Provide online access for staff, owners, residents, and board members,<br/>
                                d.	Receive maintenance requests and generate work orders,<br/>
                                e.	Accept and issue payments, and<br/>
                                f.	Market and fill vacancies online.<br/>
                                {partnerDefaults ? partnerDefaults.PartnerName : ''} will provide this functionality within a hosted server environment and other related services ordered by Customer under the terms below (Service).
                        </p>
                        <p>
                            <b>2. USE OF SERVICES.</b><br/>
                            a.	Trial Period. If Customer has registered for a trial use of the Services, Customer may access the Services for the duration specified on the website for no charge. The Service is provided AS IS, with no warranty during this time period. All Customer data may be deleted after the trial period, unless Customer converts its account to a paid Service.<br/>
                            b.	{partnerDefaults ? partnerDefaults.PartnerName : ''} Responsibilities. {partnerDefaults ? partnerDefaults.PartnerName : ''} must (i) use commercially reasonable efforts to make the Services available, except for scheduled outages, and unavailability caused by force majeure.<br/>
                            c.	Customer Responsibilities. Customer (i) is solely responsible for Customer Data, (ii) must use commercially reasonable efforts to prevent unauthorized access to the Services, and notify {partnerDefaults ? partnerDefaults.PartnerName : ''} promptly of any such unauthorized access, (iii) may use the Services only in accordance with applicable law, and (iv) may not setup any account pretending to be someone else (or spoof someone’s identity).<br/>
                            d.	Customer Employee and Contractor Access and Use. Customer may allow its employees and certain third-parties (including without limitation, tenants, property owners, and board members) to setup accounts and access the Services in compliance with the terms of this agreement, which access must be for the sole benefit of Customer. Customer is responsible for its employees and each of those third-parties compliance with this agreement.<br/>
                            e.	Restrictions: Customer may not (i) sell, resell, rent or lease the Services, (ii) use the Services to store or transmit infringing, unsolicited marketing emails, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party rights, (iii) interfere with or disrupt the integrity or performance of the Services, or (iv) attempt to gain unauthorized access to the Services or their related systems or networks.
                        </p>
                        <p>
                            <b>3. PAYMENT TERMS.</b> Customer must pay all fees itemized in its account in the Service. Customer is responsible for the payment of all sales, use and other similar taxes. There are no refunds or credits for partial months of the Service. {partnerDefaults ? partnerDefaults.PartnerName : ''} may change its fees upon notice to Customer via the email address in Customer’s account.<br/>
                            a.	Credit Card/ACH. Customer authorizes {partnerDefaults ? partnerDefaults.PartnerName : ''} to charge Customer’s credit card or debit Customer’s bank account each month for an amount equal to Customer’s then current balance. If either: (i) Customer’s credit card or bank account information changes, (ii) Customer’s credit card information expires, or (iii) {partnerDefaults ? partnerDefaults.PartnerName : ''} is unsuccessful in charging Customer’s credit card or debiting Customer’s bank account,<br/>
                            b.	then the Services may be suspended until Customer pays the applicable charges, and then after a suspension of 30 days {partnerDefaults ? partnerDefaults.PartnerName : ''} may terminate the Service at any time without notice.
                        </p>
                        <p>
                            <b>4. DISCLAIMER.</b> {partnerDefaults ? partnerDefaults.PartnerName : ''} DISCLAIMS ALL WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE AND FITNESS FOR A PARTICULAR PURPOSE. THE SERVICES MAY BE INTERRUPTED OR MAY NOT BE ERROR FREE. ALTHOUGH {partnerDefaults ? partnerDefaults.PartnerName : ''} TAKES REASONABLE PRECAUTIONS TO SECURE THE SERVICE, CUSTOMER UNDERSTANDS THAT {partnerDefaults ? partnerDefaults.PartnerName : ''} DOES NOT GUARANTY THAT THE SERVICES CANNOT BE HACKED.
                        </p>
                        <p>
                            <b>5. MUTUAL CONFIDENTIALITY.</b><br/>
                            a.	Definition of Confidential Information. Confidential Information means all confidential information disclosed by a party (Discloser) to the other party (Recipient), whether orally or in writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure (Confidential Information).Customer’s Confidential Information includes without limitation all Customer information uploaded, and {partnerDefaults ? partnerDefaults.PartnerName : ''}’s Confidential Information includes without limitation the Services, its parts and pricing.<br/>
                            b.	Protection of Confidential Information. The Recipient must use the same degree of care that it uses to protect the confidentiality of its own confidential information (but in no event less than reasonable care) not to disclose or use any Confidential Information of the Disclosing Party for any purpose outside the scope of this agreement. The Recipient must make commercially reasonable efforts to limit access to Confidential Information of Discloser to those of its employees and contractors who need such access for purposes consistent with this agreement and who have signed confidentiality agreements with Recipient no less restrictive than the confidentiality terms of this agreement.<br/>
                            c.	Exclusions. Confidential Information excludes information that: (i) is or becomes generally known to the public without breach of any obligation owed to Discloser, (ii) was known to the Recipient prior to its disclosure by the Discloser without breach of any obligation owed to the Discloser, (iii) is received from a third party without breach of any obligation owed to Discloser, or (iv) was independently developed by the Recipient without use or access to the Confidential Information.<br/>
                            d.	Disclosure Required by Law. The Recipient may disclose Confidential Information to the extent required by law or court order, but will provide Discloser with advance notice to seek a protective order. {partnerDefaults ? partnerDefaults.PartnerName : ''} will only disclose the limited information required to be disclosed by law or the court order.
                        </p>
                        <p>
                            <b>6. PROPRIETARY RIGHTS.</b>
                            a.	Reservation of Rights by {partnerDefaults ? partnerDefaults.PartnerName : ''}. The software, workflow processes, user interface, designs, know-how, and other technologies provided by {partnerDefaults ? partnerDefaults.PartnerName : ''} as part of the Services are the proprietary property of {partnerDefaults ? partnerDefaults.PartnerName : ''} and its licensors, and all right, title and interest in and to such items, including all associated intellectual property rights, remain only with {partnerDefaults ? partnerDefaults.PartnerName : ''}. {partnerDefaults ? partnerDefaults.PartnerName : ''} reserves all rights unless expressly granted in this agreement.<br/>
                            b.	Customer Restrictions. Customer may not: (i) Use the Services beyond its internal operations; (ii) Reverse engineer or deconstruct the Services; (iii) Remove or modify any proprietary marking or restrictive legends in the Service; or (iv)	Access the Service to build a competitive service or product, or copy any feature, function or graphic for competitive purposes.<br/>
                            c.	Customer Owned Data. All data uploaded by Customer or under any account of Customer remains the sole property of Customer, as between {partnerDefaults ? partnerDefaults.PartnerName : ''} and Customer (Customer Data), subject to the other terms of this agreement. Customer grants {partnerDefaults ? partnerDefaults.PartnerName : ''} the right to use the Customer Data for purposes of performing under this agreement. During the term of this agreement, Customer may only export its data through the report-by-report export feature of the Service in the following format: csv and excel. This export feature may not include every piece of data of Customer in the Service.
                        </p>
                        <p>
                            <b>7. EXCLUSION OF DAMAGES AND LIMITATION OF LIABILITY.</b><br/>
                            a.	EXCLUSION OF CERTAIN DAMAGES. {partnerDefaults ? partnerDefaults.PartnerName : ''} IS NOT LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (INCLUDING, WITHOUT LIMITATION, COSTS OF DELAY, LOSS OF DATA OR INFORMATION, AND ANY FAILURE OF DELIVERY OF THE SERVICES, EVEN IF IT KNOWS OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE).<br/>
                            b.	LIMITATION OF LIABILITY. {partnerDefaults ? partnerDefaults.PartnerName : ''}’S LIABILITY FOR ALL DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (WHETHER IN CONTRACT, TORT OR OTHERWISE) DOES NOT EXCEED THE ACTUAL AMOUNT PAID BY CUSTOMER WITHIN THE PRECEDING 12 MONTHS UNDER THIS AGREEMENT.
                        </p>
                        <p>
                            <b>8. TERM, TERMINATION, SUSPENSION OF SERVICE AND RETURN OF DATA.</b><br/>
                            a.	Term. This agreement continues for the duration specified when ordering the Service, and auto-renews for the same duration, (initial or renewal term, Term), until terminated by: (i)	Customer at any time within the Service (with no other advance notice required), or (ii) {partnerDefaults ? partnerDefaults.PartnerName : ''} with at least 60 days notice to the email address in Customer’s account.<br/>
                            b.	Mutual Termination for Material Breach. Except as otherwise provided in this agreement, if either party is in breach of any material term of this agreement, the other party may terminate this agreement at the end of a written 30-day notice/cure period, if the breach has not been cured. (i) Upon any termination as provided in 8(b) above by Customer, {partnerDefaults ? partnerDefaults.PartnerName : ''} must refund any prepaid and unused fees covering the remainder of the Term. (ii) Upon any termination as provided in 8(b) above by {partnerDefaults ? partnerDefaults.PartnerName : ''}, Customer must pay any unpaid fees covering the remainder of the Term. The Services will also be terminated.<br/>
                            c.	Termination of Service. If Customer desires to terminate the Service, it can do so at any time within the Service. {partnerDefaults ? partnerDefaults.PartnerName : ''} has no obligation to retain any Customer Data after termination.<br/>
                            d.	Suspension of Service for Violations of Law or this Agreement. {partnerDefaults ? partnerDefaults.PartnerName : ''} may immediately suspend any account or the Service and remove applicable Customer Data if it in good faith believes that, as part of using the Services, Customer or any account may have violated the law or this agreement. {partnerDefaults ? partnerDefaults.PartnerName : ''} may try to contact Customer in advance, but it is not required to do so.
                        </p>
                        <p>
                            <b>9. GOVERNING LAW, FORUM AND ARBITRATION.</b><br/>
                            a.	For Customers Located in the United States: This agreement is governed by the laws of the State of {partnerDefaults ? partnerDefaults.FormedIn : ''}, without regard to conflict of law principles. Any dispute arising out of or related to this agreement may only be brought in the state and federal courts for {partnerDefaults ? partnerDefaults.FormedIn : ''}. Both parties consent to the personal jurisdiction of such courts and waive any claim that it is an inconvenient forum. Nothing in this agreement prevents either party from seeking injunctive relief in a court of competent jurisdiction. The prevailing party in any litigation is entitled to recover its attorneys’ fees and costs from the other party.<br/>
                            b.	For Customers Located in Other Countries. This agreement is governed by the laws of the State of {partnerDefaults ? partnerDefaults.FormedIn : ''}, without regard to conflict of laws principles. Any dispute between Customer and {partnerDefaults ? partnerDefaults.PartnerName : ''} arising out of or related to this agreement must be determined by binding arbitration in {partnerDefaults ? partnerDefaults.FormedIn : ''} (in English) under the then current commercial or international rules (as applicable) of the American Arbitration Association. Nothing in this agreement prevents either party from seeking injunctive relief in a court of competent jurisdiction. The prevailing party in any arbitration or litigation is entitled to recover its attorneys’ fees and costs from the other party.
                        </p>
                        <p>
                            <b>10. CUSTOMER INDEMNITY.</b> Customer hereby indemnifies, defends, and hold harmless {partnerDefaults ? partnerDefaults.PartnerName : ''} against all third-party claims, demands, damages, costs, penalties, fines, and expenses (including reasonable attorneys’ fees and costs) arising out of or related to Customer’s use or access to the Service, which includes without limitation any breach of the agreement by Customer or claim related to the Customer Data; provided that Customer is not responsible to indemnity {partnerDefaults ? partnerDefaults.PartnerName : ''} to the extent of any claim of infringement of the Service of a third-party copyright, patent or trade secret.
                        </p>
                        <p>
                            <b>11. MISCELLANEOUS OTHER TERMS.</b><br/>
                            a.	Money Damages Insufficient. Any breach by a party of this agreement or violation of the other party’s intellectual property rights could cause irreparable injury or harm to the other party. The other party may seek a court order to stop any breach or avoid any future breach.<br/>
                            b.	Entire Agreement and Changes. This agreement constitutes the entire agreement between the parties, and supersedes all prior or contemporaneous negotiations, agreements and representations, whether oral or written, related to this subject matter. No modification or waiver of any term of this agreement is effective unless both parties sign it.<br/>
                            c.	No Assignment. Neither party may assign or transfer this agreement to a third party, except that with notice this agreement may be assigned as part of a merger, or sale of all or substantially all of the business or assets, of a party.<br/>
                            d.	Electronic Notices: Customer agrees to receive notices from {partnerDefaults ? partnerDefaults.PartnerName : ''} regarding the Service, and this agreement, from within the Service or to the email address in Customer’s account.<br/>
                            e.	Independent Contractors. The parties are independent contractors with respect to each other.<br/>
                            f.	Enforceability. If any term of this agreement is invalid or unenforceable, the other terms remain in effect.<br/>
                            g.	Survival of Terms. Any terms that by their nature survive termination of this agreement, will survive.
                        </p>
                    </div>
                </RctCollapsibleCard>
            </div>
        </Main>
    );
}

export default LicenseAgreement;

import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, FormText, Label, Input } from 'reactstrap';
import { FormGroup, Switch } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import DatePicker from 'reactstrap-date-picker';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import NumberFormat from 'react-number-format';

import Main from '../../Main';
import RctCollapsibleCard from '../../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../../Util/LinearProgress';
import * as billsAPI from '../../../Api/bills';
import * as Util from '../../Util/util';

const Edit = (props) => {
    const history = useHistory();

    const login = useSelector((state) => state.login);
    const company = login.company;
    const propertyID = login.selectedPropertyID;
    const companyID = company.id;
    const recurringBillID = props.location.state ? props.location.state.recurringBillID : null;

    const [loading, setLoading] = useState(true);
    const [payees, setPayees] = useState([]);
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [saving, setSaving] = useState(false);
    const [editBill, setEditBill] = useState({
        propertyID: 0,
        invoiceDate: '',
        paidDate: '',
        selectedPayee: 0,
        selectedPropertyID: propertyID,
        selectedExpenseType: 0,
        selectedUnit: 0,
        debitAmt: 0,
        invoiceNumber: '',
        memo: '',
        escrow: false,
        id: 0,
    });
    const [updFile, setUpdFile] = useState(null);
    const [unlimited, setUnlimited] = useState(false);
    const [frequency, setFrequency] = useState([]);
    const [postMethods, setPostMethods] = useState([]);

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            if (recurringBillID === null) history.goBack();
            const bill = await billsAPI.getRecurring(recurringBillID);
            setEditBill({
                payee: parseInt(bill.VendorID),
                expenseType: parseInt(bill.ExpenseTypeID),
                firstPay: bill.FirstPayDate !== '' ? moment.utc(bill.FirstPayDate).format('YYYY-MM-DD') : '',
                markPaid: parseInt(bill.Paid) === 1 ? true : false,
                debitAmt: parseFloat(bill.Amount).toFixed(2),
                invoiceNumber: bill.InvoiceNumber,
                memo: bill.Memo,
                escrow: parseInt(bill.Escrow) === 1 ? true : false,
                frequency: parseInt(bill.FrequencyID),
                numPayments: bill.NumberofPayments !== '' ? parseInt(bill.NumberofPayments) : '',
                postMethod: parseInt(bill.PostMethodID),
            });
            if (bill.Unlimited !== '' && parseInt(bill.Unlimited) === 1) {
                setUnlimited(true);
            }
            setPayees(await billsAPI.getPayee(companyID));
            setExpenseTypes(await billsAPI.getExpenseTypes(companyID));
            setFrequency(await billsAPI.getFrequencies());
            setPostMethods(await billsAPI.getPostMethods());
            setLoading(false);
        }
        fetchData();
    }, [recurringBillID, companyID, history]);

    const submitEditBill = async (data) => {
        if (isNaN(data.debitAmt.toString().replace(/\$|,/g, ''))) {
            NotificationManager.error('Enter a valid debit amount.', 'Error');
            return;
        }
        const amt = parseFloat(data.debitAmt.toString().replace(/\$|,/g, '')).toFixed(2);
        if (parseInt(data.payee) === 0) {
            NotificationManager.error('Payee is required.', 'Error');
            return;
        }
        if (parseInt(data.expenseType) === 0) {
            NotificationManager.error('Expense Type is required.', 'Error');
            return;
        }
        const firstPayDT = moment(data.firstPay);
        if (!firstPayDT.isValid()) {
            NotificationManager.warning('Please enter a valid first pay date.', 'Warning');
            return;
        }
        const firstPay = data.firstPay === '' ? null : moment(data.firstPay);
        if (firstPay !== null && !firstPay.isValid()) {
            NotificationManager.warning('Please enter a valid first pay date.', 'Warning');
            return;
        }
        console.log("editBill.markPaid", editBill.markPaid)
        const editBillData = {
            amount: amt,
            memo: data.memo,
            invoiceNumber: data.invoiceNumber,
            expenseTypeID: parseInt(data.expenseType),
            vendorID: parseInt(data.payee),
            escrow: data.escrow ? 1 : 0,
            firstPay: firstPayDT,
            frequencyID: parseInt(data.frequency),
            paid: editBill.markPaid ? 1 : 0,
            numberofPayments: data.numPayments,
            unlimited: unlimited ? 1 : 0,
            postMethodID: parseInt(data.postMethod),
            id: recurringBillID,
        };
        setLoading(true);
        setSaving(true);
        const res = await billsAPI.updateRecurring(editBillData);
        if (res !== 0) {
            NotificationManager.error('Error processing your request. Please, contact us.', 'Error');
            setSaving(false);
            setLoading(false);
            return;
        }
        if (updFile !== null) {
            await billsAPI.addRecurringReceipt({
                propertyID,
                recurringBillID,
                file: updFile,
            });
        }
        setSaving(false);
        setLoading(false);
        NotificationManager.success('Bill edited successfully.', 'Success!');
    };

    const handleFileChange = (event) => {
        setUpdFile(event.target.files[0]);
    };

    const renderPayeeLabel = () => {
        return (
            <>
                Payee
                <IconButton
                    aria-label="Payee"
                    onClick={() => {
                        history.push('/vendors');
                    }}
                    style={{ color: 'blue', maxHeight: '10px' }}
                >
                    <AddCircleOutline />
                </IconButton>
            </>
        );
    };

    const renderExpenseType = () => {
        return (
            <>
                Expense Type
                <IconButton
                    aria-label="ExpenseType"
                    onClick={() => {
                        history.push('/company/expenseTypes');
                    }}
                    style={{ color: 'blue', maxHeight: '10px' }}
                >
                    <AddCircleOutline />
                </IconButton>
            </>
        );
    };

    const handleUnlimited = () => {
        setUnlimited(!unlimited);
        if (!unlimited) {
            setValue('numPayments', '');
        }
    };

    const handleNumPayments = (val) => {
        setValue('numPayments', val, { shouldValidate: true });
        if (val !== '') {
            setUnlimited(false);
        }
    };

    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Recurring Bill...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div>
                <div className="formelements-wrapper">
                    <div
                        className="page-title d-flex justify-content-between align-items-center"
                        style={{ marginTop: '1%' }}
                    >
                        <div className="page-title-wrap">
                            <i className="ti-angle-left" style={{ cursor: 'pointer' }} onClick={() => history.goBack()}></i>
                            <h2>
                                <span>Edit Recurring Bill</span>
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <RctCollapsibleCard heading="Add Bill">
                                <Form onSubmit={handleSubmit(submitEditBill)}>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <FormGroup className="mr-10 mb-10">
                                                <Label for="payee" className="mr-sm-10">
                                                    {renderPayeeLabel()}
                                                </Label>
                                                <Controller
                                                    name="payee"
                                                    control={control}
                                                    defaultValue={editBill.payee}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Input {...field} type="select" id="payee">
                                                            <option value="0">Select</option>
                                                            {payees.map((obj) => {
                                                                return (
                                                                    <option key={obj.VendorID} value={obj.VendorID}>
                                                                        {obj.VendorName}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Input>
                                                    )}
                                                />
                                                {errors.payee && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormGroup className="mr-10 mb-10">
                                                <Label for="expenseType" className="mr-sm-10">
                                                    {renderExpenseType()}
                                                </Label>
                                                <Controller
                                                    name="expenseType"
                                                    control={control}
                                                    defaultValue={editBill.expenseType}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="select"
                                                            id="expenseType"
                                                            style={Util.setErrorStyle(errors.expenseType)}
                                                        >
                                                            <option value="0">Select</option>
                                                            {expenseTypes.map((obj) => {
                                                                return (
                                                                    <option
                                                                        key={obj.ExpenseTypeID}
                                                                        value={obj.ExpenseTypeID}
                                                                    >
                                                                        {obj.ExpenseType}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Input>
                                                    )}
                                                />
                                                {errors.expenseType && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-2">
                                            <FormGroup className="mr-10 mb-10">
                                                <Label for="firstPay" className="mr-sm-10">
                                                    First Pay By
                                                </Label>
                                                <Controller
                                                    name="firstPay"
                                                    control={control}
                                                    defaultValue={editBill.firstPay}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            id="firstPay"
                                                            style={Util.setErrorStyle(errors.firstPay)}
                                                        />
                                                    )}
                                                />
                                                {errors.firstPay && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-2">
                                            <FormGroup check className="mb-20">
                                                <Label for="markPaid" className="mr-sm-10">
                                                    Mark Paid
                                                </Label>
                                                <Switch
                                                    name="markPaid"
                                                    checked={editBill.markPaid}
                                                    onChange={() =>
                                                        setEditBill({ ...editBill, markPaid: !editBill.markPaid })
                                                    }
                                                    aria-label="Mark Paid"
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <FormGroup className="mr-10 mb-10">
                                                <Label for="debitAmt" className="mr-sm-10">
                                                    Debit Amt
                                                </Label>
                                                <Controller
                                                    name="debitAmt"
                                                    control={control}
                                                    defaultValue={editBill.debitAmt}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <NumberFormat
                                                            {...field}
                                                            id="debitAmt"
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            style={Util.setErrorStyle(errors.debitAmt)}
                                                            className="form-control"
                                                        />
                                                    )}
                                                />
                                                {errors.debitAmt && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormGroup className="mr-10 mb-10">
                                                <Label for="invoiceNumber" className="mr-sm-10">
                                                    Invoice Number
                                                </Label>
                                                <Controller
                                                    name="invoiceNumber"
                                                    control={control}
                                                    defaultValue={editBill.invoiceNumber}
                                                    render={({ field }) => (
                                                        <Input {...field} type="text" id="invoiceNumber" />
                                                    )}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormGroup className="mr-10 mb-10">
                                                <Label for="memo" className="mr-sm-10">
                                                    Memo
                                                </Label>
                                                <Controller
                                                    name="memo"
                                                    control={control}
                                                    defaultValue={editBill.memo}
                                                    render={({ field }) => <Input {...field} type="text" id="memo" />}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-1">
                                            <FormGroup check className="mb-20">
                                                <Label for="escrow" className="mr-sm-10">
                                                    Escrow?
                                                </Label>
                                                <Switch
                                                    name="escrow"
                                                    checked={editBill.escrow}
                                                    onChange={() => setEditBill({ ...editBill, escrow: !editBill.escrow })}
                                                    aria-label="Escrow"
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Label for="file">Attach Receipt</Label>
                                                <Input type="file" id="file" onChange={handleFileChange} />
                                                <FormText color="muted">Attach invoice file</FormText>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <FormGroup className="mr-10 mb-10">
                                                <Label for="frequency" className="mr-sm-10">
                                                    Frequency
                                                </Label>
                                                <Controller
                                                    name="frequency"
                                                    control={control}
                                                    defaultValue={editBill.frequency}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="select"
                                                            id="frequency"
                                                            style={Util.setErrorStyle(errors.frequency)}
                                                        >
                                                            <option value="0">Select</option>
                                                            {frequency.map((obj) => {
                                                                return (
                                                                    <option key={obj.FrequencyID} value={obj.FrequencyID}>
                                                                        {obj.Frequency}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Input>
                                                    )}
                                                />
                                                {errors.frequency && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-2">
                                            <FormGroup className="mr-10 mb-10">
                                                <Label for="numPayments" className="mr-sm-10">
                                                    Number of Payments
                                                </Label>
                                                <Controller
                                                    name="numPayments"
                                                    control={control}
                                                    defaultValue={editBill.numPayments}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="number"
                                                            id="numPayments"
                                                            onChange={(e) => handleNumPayments(e.target.value)}
                                                        />
                                                    )}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-2">
                                            <Label for="unlimited" className="mr-sm-10">
                                                Unlimited Payments
                                            </Label>
                                            <Switch
                                                name="unlimited"
                                                checked={unlimited}
                                                onChange={handleUnlimited}
                                                aria-label="Unlimited"
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <FormGroup className="mr-10 mb-10">
                                                <Label for="postMethod" className="mr-sm-10">
                                                    Post Method
                                                </Label>
                                                <Controller
                                                    name="postMethod"
                                                    control={control}
                                                    defaultValue={editBill.postMethod}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="select"
                                                            id="postMethod"
                                                            style={Util.setErrorStyle(errors.postMethod)}
                                                        >
                                                            <option value="0">Select</option>
                                                            {postMethods.map((obj) => {
                                                                return (
                                                                    <option key={obj.PostMethodID} value={obj.PostMethodID}>
                                                                        {obj.PostMethod}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Input>
                                                    )}
                                                />
                                                {errors.postMethod && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <Button type="submit" color="primary" size="sm" className="w-auto" disabled={saving}>
                                        Update Recurring Bill
                                    </Button>
                                </Form>
                            </RctCollapsibleCard>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default Edit;


import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../state/index';
import { NotificationManager } from 'react-notifications';
import { useHistory } from "react-router-dom";

import * as LoginAPI from '../../Api/login';
import * as BillsAPI from '../../Api/bills';
import * as CompanyAPI from '../../Api/company';
import CreditCard from '../Util/CreditCard/Creditcard';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';

const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const OwesMoney = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { login } = bindActionCreators(actionCreators, dispatch);
    const companyID = props.location.state.companyID;
    const userEmail = props.location.state.userEmail;
    const userPw = props.location.state.userPw;

    const [cardDetails, setCardDetails] = useState({
        number: '',
        name: '',
        expirationMonth: '',
        expirationYear: '',
        ccv: ''
    });
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [amountDue, setAmountDue] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const company = await CompanyAPI.get(companyID);
            if(company) {
                setName(company.CompanyName);
                setAmountDue(parseFloat(company.AmountDue).toFixed(2));
                setEmail(company.ContactEmail)
            }
        }
        fetchData();
    }, [companyID])

    const processPayment = async (data) => {
        setLoading(true);
        const res = await CompanyAPI.updateCompanyCard({
            nameOnCard: data.name,
            cardNumber: data.number,
            cardExpMonth: data.expiry.slice(0,2),
            cardExpYear: data.expiry.slice(3),
            cardCVC: data.cvc,
            cardZip: '',
            companyName: name,
            companyEmail: email,
            companyID,
        });
        console.log(res);
        if(res !== 0) {
            setLoading(false);
            NotificationManager.error(res, "Error");
            return;
        }
        const processPayment = await CompanyAPI.processPayment({
            companyID,
            amount: amountDue,
            companyName: name,
            companyEmail: email,
        });
        if(processPayment !== 0) {
            setLoading(false);
            NotificationManager.error(res, "Error");
            return;
        }
        // If works, log into the system
        await loginUser({
            email: userEmail,
            userPw,
        });
        setLoading(false);
    }

    const loginUser = async (data) => {
        const getLogin = await LoginAPI.login({
            email: data.email,
            password: data.userPw
        });
        const resData = getLogin[0];
        // get user's properties
        const getProperties = await BillsAPI.getProperties(resData.UserID);
        // get user's notifications
        const notifications = await LoginAPI.getNotifications(resData.UserID);
        login(resData, getProperties, notifications);
        history.push("/");
    }

    if(loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Procesing payment..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <div style={{marginTop: '20px', overflow: 'auto'}}>
            <div style={style}>
                <p>
                    <b>Your credit card information needs to be updated in order to continue using {name}</b><br/><br/>
                    <span style={{color: 'red'}}>
                        Total due: ${amountDue} <br/>
                        (${amountDue} will be charged upon submission of this form)
                    </span>
                </p>
            </div>
            <div style={style}>
                <CreditCard card={{
                    number: cardDetails.number,
                    name: cardDetails.name,
                    expiry: cardDetails.expirationMonth !== undefined ? `${cardDetails.expirationMonth}/${cardDetails.expirationYear}` : '',
                    cvc: cardDetails.ccv
                }}
                    updateCard={processPayment}
                />
            </div>
        </div>
    );
}

export default OwesMoney;
import { CInputGroup, CFormInput } from '@coreui/react-pro';
import React from 'react';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { actionCreators } from '../../state';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

const Search = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [keyword, setKeyword] = React.useState('');
    const { setSearchKeyword } = bindActionCreators(actionCreators, dispatch);
    return (
        <div className="search-wrapper">
            <CInputGroup>
                <CFormInput
                    placeholder="Search.."
                    aria-label="search"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setSearchKeyword(keyword);
                            const location = {
                                pathname: '/search-results',
                            };
                            history.push(location);
                        }
                    }}
                />

                <IconButton
                    style={{ marginLeft: '-45px', backgroundColor: 'transparent', zIndex: '999' }}
                    mini="true"
                    onClick={() => {
                        setSearchKeyword(keyword);
                        const location = {
                            pathname: '/search-results',
                        };
                        history.push(location);
                    }}
                    className="search-icon"
                >
                    <i className="zmdi zmdi-search"></i>
                </IconButton>
            </CInputGroup>
            {/* <Input
                type="search"
                className="search-input-lg form-control"
                onBlur={(e) => onBlur(e.target.value)}
                placeholder="Search.."
                // onChange={(e) =>
                //     setTimeout(() => {
                //         onBlur(e.target.value);
                //     }, 2000)
                // }
            /> */}
        </div>
    );
};

export default Search;


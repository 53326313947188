import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { formatDate } from "./helperFunctions";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
const LeasingAgent = (props) => {
  const [loading, setLoading] = useState(false);
  const [signedData, setSignedData] = useState([]);
  const [talliedData, setTalliedData] = useState([]);
  var [grandTotal, setGrandTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([
    "Unknown",
    "Word of Mouth",
    "Online",
    "Signs/Drive By",
    "American GI Forum",
    "",
    "Active Prospects:",
    "Total Applicants:",
    "Denied Prospects:",
    "Total Move Ins:",
    "Totals:",
    "Traffic to Applicant Ratio:",
    "Traffic to Move In Ratio:",
  ]);
  const [totals, setTotals] = useState({});
  const [sourceData, setSourceData] = useState([]);
  const [sourceColumns, setSourceColumns] = useState([
    { name: "category", label: "Category" },
  ]);
  const propertyName = useSelector((state) => state.login.properties);
  const companyID = useSelector((state) => state.login.company.id);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getLeasingAgent(
        props.reportSearch
      );
      props.reportSearch.comID = companyID;
      const reportUsers = await reportsAPI.getLeasingUser(props.reportSearch);
      const reportSources = await reportsAPI.getLeadSources({
        comID: companyID,
      });
      for (let i = 0; i < reportUsers.length; i++) {
        users.push(reportUsers[i].UserFName + reportUsers[i].UserLName);
        sourceColumns.push({
          name: reportUsers[i].UserFName + reportUsers[i].UserLName,
          label: reportUsers[i].UserFName + " " + reportUsers[i].UserLName,
        });
      }
      for (let i = 0; i < reportDetails.length; i++) {
        if (
          talliedData[
            reportDetails[i].user.UserFName + reportDetails[i].user.UserLName
          ]
        ) {
          talliedData[
            reportDetails[i].user.UserFName + reportDetails[i].user.UserLName
          ][reportDetails[i].leadsource.LeadSource]
            ? (talliedData[
                reportDetails[i].user.UserFName +
                  reportDetails[i].user.UserLName
              ][reportDetails[i].leadsource.LeadSource] += 1)
            : (talliedData[
                reportDetails[i].user.UserFName +
                  reportDetails[i].user.UserLName
              ][reportDetails[i].leadsource.LeadSource] = 1);
          if (reportDetails[i].Prospect === 1) {
            talliedData[
              reportDetails[i].user.UserFName + reportDetails[i].user.UserLName
            ].totalApplicants
              ? (talliedData[
                  reportDetails[i].user.UserFName +
                    reportDetails[i].user.UserLName
                ].totalApplicants += 1)
              : (talliedData[
                  reportDetails[i].user.UserFName +
                    reportDetails[i].user.UserLName
                ].totalApplicants = 1);
          }
          if (reportDetails[i].Prospect === 2) {
            talliedData[
              reportDetails[i].user.UserFName + reportDetails[i].user.UserLName
            ].moveIns
              ? (talliedData[
                  reportDetails[i].user.UserFName +
                    reportDetails[i].user.UserLName
                ].moveIns += 1)
              : (talliedData[
                  reportDetails[i].user.UserFName +
                    reportDetails[i].user.UserLName
                ].moveIns = 1);
          }
          if (reportDetails[i].Prospect === 3) {
            talliedData[
              reportDetails[i].user.UserFName + reportDetails[i].user.UserLName
            ].activeProspect
              ? (talliedData[
                  reportDetails[i].user.UserFName +
                    reportDetails[i].user.UserLName
                ].activeProspect += 1)
              : (talliedData[
                  reportDetails[i].user.UserFName +
                    reportDetails[i].user.UserLName
                ].activeProspect = 1);
          }
          if (reportDetails[i].Prospect === 4) {
            talliedData[
              reportDetails[i].user.UserFName + reportDetails[i].user.UserLName
            ].deniedProspects
              ? (talliedData[
                  reportDetails[i].user.UserFName +
                    reportDetails[i].user.UserLName
                ].deniedProspects += 1)
              : (talliedData[
                  reportDetails[i].user.UserFName +
                    reportDetails[i].user.UserLName
                ].deniedProspects = 1);
          }
        } else {
          talliedData[
            reportDetails[i].user.UserFName + reportDetails[i].user.UserLName
          ] = {
            name:
              reportDetails[i].user.UserFName +
              " " +
              reportDetails[i].user.UserLName,
          };
          talliedData[
            reportDetails[i].user.UserFName + reportDetails[i].user.UserLName
          ][reportDetails[i].leadsource.LeadSource] = 1;
          if (reportDetails[i].Prospect === 1) {
            talliedData[
              reportDetails[i].user.UserFName + reportDetails[i].user.UserLName
            ].totalApplicants = 1;
          }
          if (reportDetails[i].Prospect === 2) {
            talliedData[
              reportDetails[i].user.UserFName + reportDetails[i].user.UserLName
            ].moveIns = 1;
          }
          if (reportDetails[i].Prospect === 3) {
            talliedData[
              reportDetails[i].user.UserFName + reportDetails[i].user.UserLName
            ].activeProspect = 1;
          }
          if (reportDetails[i].Prospect === 4) {
            talliedData[
              reportDetails[i].user.UserFName + reportDetails[i].user.UserLName
            ].deniedProspects = 1;
          }
        }
        signedData.push({
          tenant:
            reportDetails[i].TenantFName + " " + reportDetails[i].TenantLName,
          unit: reportDetails[i].unit ? reportDetails[i].unit.UnitName : "",
          firstContact: formatDate(
            reportDetails[i].ProspectStartDate.substring(0, 10)
          ),
          leaseStartDate:
            reportDetails[i].Prospect === 1 || reportDetails[i].Prospect === 4
              ? ""
              : formatDate(reportDetails[i].LeaseStartDate),
          moveInDate:
            reportDetails[i].Prospect === 1 || reportDetails[i].Prospect === 4
              ? "Prospect"
              : formatDate(reportDetails[i].MoveInDate),
          leaseEndDate:
            reportDetails[i].Prospect === 1 || reportDetails[i].Prospect === 4
              ? ""
              : formatDate(reportDetails[i].LeaseEndDate),
          source: reportDetails[i].leadsource.LeadSource,
          leasingAgent:
            reportDetails[i].user.UserFName +
            " " +
            reportDetails[i].user.UserLName,
        });
      }
      for (let i = 0; i < categories.length; i++) {
        const emptyObject = { category: categories[i] };
        for (let j = 1; j < sourceColumns.length; j++) {
          const name = sourceColumns[j].name;
          if (i === 0) {
            talliedData[name]
              ? talliedData[name]["Unknown"]
                ? (emptyObject[name] = talliedData[name]["Unknown"])
                : (emptyObject[name] = 0)
              : (emptyObject[name] = 0);
          }
          if (i === 1) {
            talliedData[name]
              ? talliedData[name]["Word of Mouth"]
                ? (emptyObject[name] = talliedData[name]["Word of Mouth"])
                : (emptyObject[name] = 0)
              : (emptyObject[name] = 0);
          }
          if (i === 2) {
            talliedData[name]
              ? talliedData[name]["Online"]
                ? (emptyObject[name] = talliedData[name]["Online"])
                : (emptyObject[name] = 0)
              : (emptyObject[name] = 0);
          }
          if (i === 3) {
            talliedData[name]
              ? talliedData[name]["Signs/Drive By"]
                ? (emptyObject[name] = talliedData[name]["Signs/Drive By"])
                : (emptyObject[name] = 0)
              : (emptyObject[name] = 0);
          }
          if (i === 4) {
            talliedData[name]
              ? talliedData[name]["American GI Forum"]
                ? (emptyObject[name] = talliedData[name]["American GI Forum"])
                : (emptyObject[name] = 0)
              : (emptyObject[name] = 0);
          }
          if (i === 6) {
            talliedData[name]
              ? talliedData[name]["activeProspect"]
                ? (emptyObject[name] = talliedData[name]["activeProspect"])
                : (emptyObject[name] = 0)
              : (emptyObject[name] = 0);
            totals[name] = { activeProspect: emptyObject[name] };
          }
          if (i === 7) {
            talliedData[name]
              ? talliedData[name]["totalApplicants"]
                ? (emptyObject[name] = talliedData[name]["totalApplicants"])
                : (emptyObject[name] = 0)
              : (emptyObject[name] = 0);
            totals[name].totalApplicants = emptyObject[name];
          }
          if (i === 8) {
            talliedData[name]
              ? talliedData[name]["deniedProspects"]
                ? (emptyObject[name] = talliedData[name]["deniedProspects"])
                : (emptyObject[name] = 0)
              : (emptyObject[name] = 0);
            totals[name].deniedProspects = emptyObject[name];
          }
          if (i === 9) {
            talliedData[name]
              ? talliedData[name]["moveIns"]
                ? (emptyObject[name] = talliedData[name]["moveIns"])
                : (emptyObject[name] = 0)
              : (emptyObject[name] = 0);
            totals[name].moveIns = emptyObject[name];
          }
        }
        sourceData.push(emptyObject);
      }
      for (let j = 0; j < users.length; j++) {
        const userInfo = totals[users[j]];
        sourceData[10][users[j]] =
          userInfo.activeProspect +
          userInfo.deniedProspects +
          userInfo.moveIns +
          userInfo.totalApplicants;
        setGrandTotal(grandTotal + sourceData[10][users[j]]);
        sourceData[11][users[j]] =
          sourceData[10][users[j]] === 0
            ? "0%"
            : Math.round(
                ((userInfo.moveIns + userInfo.totalApplicants) /
                  sourceData[10][users[j]]) *
                  100
              ).toString() + "%";
        sourceData[12][users[j]] =
          sourceData[10][users[j]] === 0
            ? "0%"
            : Math.round(
                (userInfo.moveIns / sourceData[10][users[j]]) * 100
              ).toString() + "%";
      }
      signedData.push({
        tenant: "Total Agent Productivity (Prospect Contacts)",
        unit: reportDetails.length,
      });
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const signedColumns = [
    { name: "tenant", label: "Tenant" },
    { name: "unit", label: "Unit" },
    { name: "firstContact", label: "First Contact" },
    { name: "leaseStartDate", label: "Lease Start Date" },
    { name: "moveInDate", label: "Move In Date" },
    { name: "leaseEndDate", label: "Lease End Date" },
    { name: "source", label: "Source" },
    { name: "leasingAgent", label: "Leasing Agent" },
  ];

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"New Leases Signed During Report Period:"}
              data={signedData}
              columns={signedColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Leasing Agent Productivity by Source:"}
              data={sourceData}
              columns={sourceColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
        <h2>Grand Totals: {grandTotal}</h2>
      </div>
    </div>
  );
};

export default LeasingAgent;

import React, {useState, useEffect} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Label, Input } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import DatePicker from "reactstrap-date-picker";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Util from '../Util/util';
import * as Constants from '../Util/constants';
import * as companyAPI from '../../Api/company';

const EditBudgetItem = (props) => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const user = login.user;
    const userID = user.id;
    const propertyID = login.selectedPropertyID;
    const company = login.company
    const companyID = company.id;
    const id = props.location.state ? props.location.state.id : null;
    const expense = props.location.state ? props.location.state.expense : false;

    const [loading, setLoading] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [update, setUpdate] = useState(false);

    const {  
        handleSubmit, control, setValue, formState: { errors }
    } = useForm();

    useEffect(() => {
        async function fetchData() {
            if(id === null) {
                history.goBack();
                return;
            }
            setLoading(true)
            setItemList(expense ? await companyAPI.getListExpenseTypes(companyID) : await companyAPI.getListDepositSources());

            const itemData = expense ? await companyAPI.getBudgetExpense(id) : await companyAPI.getBudgetIncome(id);
            if(itemData) {
                setValue('item', parseInt(expense ? itemData.ExpenseTypeID : itemData.DepositSourceID));
                setValue('monthlyAmount', parseFloat(itemData.Amount).toFixed(2));
                setValue('dateFrom', moment.utc(itemData.BudgetStartDate).format("YYYY-MM-DD"));
                setValue('dateTo', moment.utc(itemData.BudgetEndDate).format("YYYY-MM-DD"));
            }
            setLoading(false)
        }
        fetchData();
    }, [propertyID, companyID, update, expense, history, id, setValue])


    const submitForm = async (data) => {
        const amt = parseFloat(data.monthlyAmount.toString().replace(/\$|,/g, '')).toFixed(2);
        if(amt <= 0) {
            NotificationManager.warning("Please, enter a valid monthly budget amount", "Error");
            return;
        }
        const dtFrom = moment(data.dateFrom);
        if(!dtFrom.isValid()) {
            NotificationManager.warning("Please enter a valid date from.", "Warning");
            return;
        }
        const dtTo = moment(data.dateTo);
        if(!dtTo.isValid()) {
            NotificationManager.warning("Please enter a valid date to.", "Warning");
            return;
        }
        if(!moment(dtTo).isAfter(moment(dtFrom))) {
            NotificationManager.warning("Date To must be after Date From.", "Warning");
            return;
        }
        setUpdate(!update);
        let res = -1;
        if(expense) {
            res = await companyAPI.updateBudget({
                itemID: parseInt(data.item),
                amount: amt,
                startDate: dtFrom,
                endDate: dtTo,
                userID: userID,
                id,
            })
        } else {
            res = await companyAPI.updateBudgetIncome({
                itemID: parseInt(data.item),
                amount: amt,
                startDate: dtFrom,
                endDate: dtTo,
                userID: userID,
                id,
            })
        }
        if(res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return
        }
        setUpdate(!update);
        NotificationManager.success("Budget updated item.", "Success");
    }

    if(loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={`Loading Edit Budget Item...`}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>  
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <i className="ti-angle-left" style={{cursor: 'pointer'}} onClick={() => history.goBack()}></i>
                    <h2>
                        <span>Edit Budget Item</span>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <RctCollapsibleCard heading="Add Budget Expense">
                        <Form onSubmit={handleSubmit(submitForm)}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <Label for="item" className="mr-sm-10">Item</Label>
                                    <Controller
                                        name="item"
                                        control={control}
                                        render={({ field }) => (
                                            <Input {...field} type="select" id="item">
                                                {itemList.map((obj, idx) => {
                                                    return <option key={idx} value={expense ? obj.ExpenseTypeID : obj.DepositSourceID}>{expense ? obj.ExpenseType : obj.DepositSource}</option>
                                                })}
                                            </Input>
                                        )}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <Label for="monthlyAmount" className="mr-sm-10">Monthly Budget Amount</Label>
                                    <Controller
                                        name="monthlyAmount"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <NumberFormat className="form-control" {...field} thousandSeparator={true} prefix={'$'} id="monthlyAmount" style={Util.setErrorStyle(errors.monthlyAmount)} />
                                        )}
                                    />
                                    {errors.monthlyAmount && (
                                        <span style={{ color: "red" }} role="alert">required</span>
                                    )}
                                </div>
                                <div className="col-sm-2">
                                    <Label for="dateFrom" className="mr-sm-10">Date From</Label>
                                    <Controller
                                        name="dateFrom"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <DatePicker {...field} id="dateFrom" style={Util.setErrorStyle(errors.dateFrom)} />
                                        )}
                                    />
                                    {errors.dateFrom && (
                                        <span style={{ color: "red" }} role="alert">required</span>
                                    )}
                                </div>
                                <div className="col-sm-2">
                                    <Label for="dateTo" className="mr-sm-10">Date To</Label>
                                    <Controller
                                        name="dateTo"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <DatePicker {...field} id="dateTo" style={Util.setErrorStyle(errors.dateTo)} />
                                        )}
                                    />
                                    {errors.dateTo && (
                                        <span style={{ color: "red" }} role="alert">required</span>
                                    )}
                                </div>
                            </div>
                            <Button type="submit" color="primary" style={{marginTop: '1rem'}}>Update Item</Button>
                        </Form>
                    </RctCollapsibleCard>
                </div>
            </div>
        </Main>
    );
}

export default EditBudgetItem;

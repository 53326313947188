import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Update from '@material-ui/icons/Edit';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import * as companyAPI from '../../Api/company';
import * as Constants from '../Util/constants';
import LinearProgress from '../Util/LinearProgress';
import Main from '../Main';

const RequestHistory = () => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const company = login.company;
    const companyID = company.id;

    const [requests, setRequests] = useState([]);
    const [loadingTable, setLoadingTable] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setLoadingTable(true);
            const enhancementRequests = await companyAPI.getEnhancementRequests(companyID);
            const arr = [];
            for (const er of enhancementRequests) {
                arr.push({
                    priority: er.Priority,
                    dateSubmited: moment.utc(er.EnhancementRequestSubmissionDate).format('MM/DD/YYYY'),
                    description: er.EnhancementRequest,
                    staffComments: er.EnhancementRequestComment ? er.EnhancementRequestComment : '',
                    commentDate: er.EnhancementRequestComment
                        ? moment.utc(er.EnhancementRequestCommentDate).format('MM/DD/YYYY')
                        : '',
                    status: er.Status,
                    requestedBy: `${er.UserFName} ${er.UserLName}`,
                    update: er,
                });
            }
            setRequests(arr);
            setLoadingTable(false);
        }
        fetchData();
    }, [companyID]);

    const columns = [
        { name: 'priority', label: 'Priority' },
        { name: 'dateSubmited', label: 'Date Submited' },
        { name: 'description', label: 'Description' },
        { name: 'staffComments', label: 'Staff Comments' },
        { name: 'commentDate', label: 'Comment Date' },
        { name: 'status', label: 'Status' },
        { name: 'requestedBy', label: 'Requested By' },
        {
            name: 'update',
            label: 'Update',
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton
                            aria-label="Print"
                            onClick={() => {
                                const location = {
                                    pathname: '/help/reditRequestHistory',
                                    request: {
                                        UserFName: value.UserFName,
                                        UserLName: value.UserLName,
                                        EnhancementRequestSubmissionDate: value.EnhancementRequestSubmissionDate,
                                        EnhancementRequest: value.EnhancementRequest,
                                        EnhancementRequestComplete: value.EnhancementRequestComplete,
                                        EnhancementRequestComment: value.EnhancementRequestComment,
                                        EnhancementRequestID: value.EnhancementRequestID,
                                    },
                                };
                                history.push(location);
                            }}
                        >
                            <Update />
                        </IconButton>
                    );
                },
            },
        },
    ];
    const options = {
        filterType: 'dropdown',
        selectableRows: 'none',
        rowsPerPage: 10,
    };

    const renderTable = () => {
        if (loadingTable) {
            return (
                <RctCollapsibleCard
                    colClasses="col-xs-12 col-sm-12 col-md-12"
                    heading={'Loading Closed Enhancement Requests...'}
                >
                    <LinearProgress />
                </RctCollapsibleCard>
            );
        } else {
            return (
                <div className="data-table-wrapper" style={Constants.margins}>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={`${requests.length} Closed Enhancement Requests`}
                                    data={requests}
                                    columns={columns}
                                    options={options}
                                />
                            </MuiThemeProvider>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Request History</span>
                        </h2>
                    </div>
                </div>
                {renderTable()}
            </div>
        </Main>
    );
};

export default RequestHistory;


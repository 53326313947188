import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const DepositListUnit = (props) => {
  const [loading, setLoading] = useState(false);
  const [depositData, setDepositData] = useState([]);
  const [depositTotal, setDepositTotal] = useState([]);
  const [unitNames, setUnitNames] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getDepositListUnit(
        props.reportSearch
      );
      for (let i = 0; i < reportDetails.length; i++) {
        if (depositData[reportDetails[i].tenant.unit.UnitName]) {
          depositData[reportDetails[i].tenant.unit.UnitName].push({
            transactionDate: formatDate(reportDetails[i].TenantTransactionDate),
            tenant:
              reportDetails[i].tenant.TenantFName +
              " " +
              reportDetails[i].tenant.TenantLName,
            payment: reportDetails[i].paymenttype?.PaymentType,
            transactionId: reportDetails[i].id,
            transactionAmount: currencyFormat(
              +reportDetails[i].TransactionAmount
            ),
          });
          depositTotal[reportDetails[i].tenant.unit.UnitName] +=
            +reportDetails[i].TransactionAmount;
        } else {
          unitNames.push(reportDetails[i].tenant.unit.UnitName);
          depositData[reportDetails[i].tenant.unit.UnitName] = [
            {
              transactionDate: formatDate(
                reportDetails[i].TenantTransactionDate
              ),
              tenant:
                reportDetails[i].tenant.TenantFName +
                " " +
                reportDetails[i].tenant.TenantLName,
              payment: reportDetails[i].paymenttype?.PaymentType,
              transactionId: reportDetails[i].id,
              transactionAmount: currencyFormat(
                +reportDetails[i].TransactionAmount
              ),
            },
          ];
          depositTotal[reportDetails[i].tenant.unit.UnitName] =
            +reportDetails[i].TransactionAmount;
        }
      }
      unitNames.map((unit) =>
        depositData[unit].push({
          transactionId: "TOTAL",
          transactionAmount: currencyFormat(+depositTotal[unit]),
        })
      );
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const depositColumns = [
    { name: "transactionDate", label: "Transaction Date" },
    { name: "tenant", label: "Tenant" },
    { name: "payment", label: "Payment" },
    { name: "transactionId", label: "TransactionID" },
    { name: "transactionAmount", label: "Transaction Amount" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        {unitNames.map((unit) => {
          return (
            <div className="col-sm-12 col-md-12 col-xl-12">
              <MuiThemeProvider theme={Constants.getMuiTheme()}>
                <MUIDataTable
                  title={`Unit: ${unit}`}
                  data={depositData[unit]}
                  columns={depositColumns}
                  options={props.reportOptions}
                />
              </MuiThemeProvider>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DepositListUnit;

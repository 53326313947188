import React, { useState, useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Label, Input, Button, ButtonGroup, Form } from 'reactstrap';
import moment from 'moment';
import MatButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Constants from '../Util/constants';
import * as workOrdersAPI from '../../Api/workOrders';
import { getMakeReadyTasks } from '../../Api/company';
import Flag from '@material-ui/icons/Flag';
import Done from '@material-ui/icons/Done';
import Edit from '@material-ui/icons/Edit';
import { NotificationManager } from 'react-notifications';
import Modal from 'react-modal';
import Select from 'react-select';
import { FormGroup } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-8%',
        transform: 'translate(-50%, -50%)',
        width: '35vw',
    },
    overlay: { zIndex: 1000 },
};

const MakeReadies = () => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const history = useHistory();
    const login = useSelector((state) => state.login);
    const propertyID = login.selectedPropertyID;
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [makeReadies, setMakeReadies] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [unitToEdit, setUnitToEdit] = useState(null);
    const [columns, setColumns] = useState([]);

    const defaultColumns = [
        { name: 'unit', label: 'Unit' },
        { name: 'unitType', label: 'Unit Type' },
        {
            name: 'preleased',
            label: 'Preleased To',
            options: {
                customBodyRender: (value) => {
                    if (!value.TenantFName || value.TenantFName === '') return <span>Not Leased </span>;
                    return (
                        <span>
                            <MatButton
                                color="primary"
                                onClick={() => {
                                    const location = {
                                        pathname: '/tenants/details',
                                        state: { tenantID: value.TenantID },
                                    };
                                    history.push(location);
                                }}
                            >
                                {value.TenantFName} {value.TenantLName}
                            </MatButton>
                            <br />
                            {moment.utc(value.MoveInDate).format('MM/DD/YYYY')}
                        </span>
                    );
                },
            },
        },
    ];
    async function fetchData() {
        setLoading(true);
        let unitMap = {};
        const makeReadies = await workOrdersAPI.getMakeReadies(propertyID);
        const makeReadyTasks = await getMakeReadyTasks(propertyID);
        setTasks(makeReadyTasks);
        console.log('task', makeReadyTasks);

        const dynamicColumns = makeReadyTasks.map((t) => ({
            name: t.Task,
            label: t.Task,
            options: {
                customBodyRender: (task, {rowData}) => {
                    console.log("t,", rowData)
                    if (!task)
                        return (
                            <IconButton
                                aria-label="Possible Duplicate"
                                onClick={async () => {
                                    try {
                                        await workOrdersAPI.createMakeReadyTaskByUnit({
                                            taskID: t.MakeReadyTaskID,
                                            unitID: rowData[7],
                                            status: 1,
                                        });
                                        NotificationManager.success(`Task Status Updated Successfully`, 'Success');
                                        fetchData();
                                    } catch (error) {
                                        NotificationManager.error(`Something went wrong. Try again later`, 'Error');
                                    }
                                }}
                            >
                                <Flag style={{ fill: 'red' }} />
                            </IconButton>
                        );
                    if (task?.comment) return <span>{task.comment}</span>;
                    return (
                        <IconButton
                            aria-label="Possible Duplicate"
                            onClick={async () => {
                                try {
                                    await workOrdersAPI.updateMakeReadyTaskByUnit({
                                        taskID: task.taskID,
                                        unitID: task.unitID,
                                        status: task.status == 0 ? 1 : 0,
                                    });
                                    NotificationManager.success(`Task Status Updated Successfully`, 'Success');
                                    fetchData();
                                } catch (error) {
                                    NotificationManager.error(`Something went wrong. Try again later`, 'Error');
                                }
                            }}
                        >
                            {task.status == 0 ? <Flag style={{ fill: 'red' }} /> : <Done style={{ fill: 'green' }} />}
                        </IconButton>
                    );
                },
            },
        }));
        setColumns([
            ...defaultColumns,
            ...dynamicColumns,
            {
                name: 'edit',
                label: 'Edit',
                options: {
                    customBodyRender: (unitid) => (
                        <>
                            <IconButton
                                onClick={() => {
                                    setOpenModal(true);
                                    setUnitToEdit(unitid);
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </>
                    ),
                },
            },
        ]);

        for (const mk of makeReadies) {
            console.log('mk.Task', mk.Task);
            if (!unitMap[mk.UnitID]) {
                unitMap[mk.UnitID] = {
                    unitID: mk.UnitID,
                    unit: mk.UnitName,
                    unitType: mk.UnitType,
                    preleased: mk,
                    [mk.Task]: {
                        task: mk.Task,
                        status: mk.Status,
                        comment: mk.Comment,
                        taskID: mk.MakeReadyTaskID,
                        unitID: mk.UnitID,
                    },
                    edit: mk.UnitID,
                };
                if (mk.Task)
                    unitMap[mk.UnitID][mk.Task] = {
                        task: mk.Task,
                        status: mk.Status,
                        comment: mk.Comment,
                        taskID: mk.MakeReadyTaskID,
                        unitID: mk.UnitID,
                    };
            } else {
                unitMap[mk.UnitID][mk.Task] = {
                    status: mk.Status,
                    comment: mk.Comment,
                    taskID: mk.MakeReadyTaskID,
                    unitID: mk.UnitID,
                };
            }
        }
        let arr = Object.values(unitMap);
        console.log('arr', arr);
        setMakeReadies(arr);
        setLoading(false);
    }
    useEffect(() => {
        fetchData();
    }, [propertyID]);

    const options = {
        filterType: 'dropdown',
        pagination: false,
        selectableRows: 'none',
        download: false,
        print: false,
    };

    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Make Readies...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    const submit = async (values) => {
        const { task, comment } = values;
        console.log('gunitToEdit', unitToEdit);
        await workOrdersAPI.updateMakeReadyTaskByUnit({
            taskID: task.value,
            unitID: unitToEdit,
            status: 0,
            comment: comment,
        });
        setOpenModal(false);
        await fetchData();
    };
    const renderModalContent = () => {
        return (
            <Form onSubmit={handleSubmit(submit)}>
                <h2>Add Comments</h2>
                <div style={{ marginTop: '2rem' }}>
                    <FormGroup className="mr-10 mb-10">
                        <Label for="task" className="mr-sm-10">
                            Select Task
                        </Label>
                        <Controller
                            name="task"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    defaultValue={[]}
                                    name="task"
                                    options={tasks.map((task) => ({
                                        value: task.MakeReadyTaskID,
                                        label: task.Task,
                                    }))}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            )}
                        />
                        {errors.task && (
                            <span style={{ color: 'red' }} role="alert">
                                required
                            </span>
                        )}
                    </FormGroup>
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <FormGroup className="mr-10 mb-10">
                        <Label for="comment" className="mr-sm-10">
                            Comment
                        </Label>
                        <Controller
                            name="comment"
                            rules={{ required: true }}
                            control={control}
                            render={({ field }) => <Input {...field} type="textarea" id="comment" row={8} />}
                        />
                        {errors.comment && (
                            <span style={{ color: 'red' }} role="alert">
                                required
                            </span>
                        )}
                    </FormGroup>
                </div>
                <div className="text-right mt-4">
                    <Button type="button" onClick={() => setOpenModal(false)} color="secondary" size="sm" className="w-auto">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" size="sm" className="w-auto ml-2">
                        Submit
                    </Button>
                </div>
            </Form>
        );
    };

    return (
        <Main>
            <Modal
                isOpen={openModal}
                onRequestClose={() => setOpenModal(false)}
                contentLabel="Add Comments"
                style={modalStyles}
            >
                {renderModalContent()}
            </Modal>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <span>View Make Readies</span>
                    </h2>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={`Make Readies: ${makeReadies.length}`}
                            data={makeReadies}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
        </Main>
    );
};

export default MakeReadies;


import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { currencyFormat } from './helperFunctions';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import AttachFile from '@material-ui/icons/AttachFile';
import axios from 'axios';
import * as url from '../Util/constants';

const GeneralLedger = (props) => {
    const [loading, setLoading] = useState(false);
    const [expense, setExpense] = useState([]);
    const [income, setIncome] = useState([]);
    const propertyName = useSelector((state) => state.login.properties);
    const [filteredName] = propertyName.filter((property) => property.PropertyID === props.propertyId);
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const reportExpense = await reportsAPI.getGeneralLedgerExpense(props.reportSearch);
            console.log("resport ", reportExpense)
            const reportExpenseTotal = await reportsAPI.getGeneralLedgerExpenseTotal(props.reportSearch);
            console.log("reportExpenseTotal ", reportExpenseTotal)
            const reportIncome = await reportsAPI.getGeneralLedgerIncome(props.reportSearch);
            const reportIncomeTotal = await reportsAPI.getGeneralLedgerIncomeTotal(props.reportSearch);
            for (let i = 0; i < reportExpense.length; i++) {
                const PaidDate = reportExpense[i].PaidDate;
                const receipts = reportExpense[i].receipts;
                let path = '';
                if (receipts && receipts.length > 0) {
                    const month = (new Date(PaidDate).getMonth() + 1).toString().padStart(2, '0');
                    path = `https://myirent.com/rent/Receipts/${props.propertyId}/${new Date(
                        PaidDate,
                    ).getFullYear()}/${month}/${reportExpense[i].id}.pdf`;
                }

                // const response = await axios.post(`${url.REQ_URL}/helper/checkFileExist`, { file: path });
                // console.log('D', response);
                expense.push({
                    invoiceDate: reportExpense[i].InvoiceDate,
                    paidDate: reportExpense[i].PaidDate,
                    recipt: path,
                    expenseType:
                        reportExpense[i].expensetype.accounttype.id === 19
                            ? reportExpense[i].expensetype.accounttype.AccountType
                            : reportExpense[i].expensetype.ExpenseType,
                    description: reportExpense[i].Memo,
                    vendor: reportExpense[i].vendor ? reportExpense[i].vendor.VendorName : 'N/A',
                    amount: currencyFormat(+reportExpense[i].Amount),
                });
            }
            for (let i = 0; i < reportIncome.length; i++) {
                if (
                    reportIncome[i].depositsource?.id === 1 ||
                    reportIncome[i].depositsource?.id === 2 ||
                    reportIncome[i].depositsource?.id === 3 ||
                    reportIncome[i].depositsource?.id === 4 ||
                    reportIncome[i].depositsource?.id === 7 ||
                    reportIncome[i].depositsource?.id === 11
                ) {
                    let path = null;

                    const PaidDate = reportIncome[i].checkregister.PaidDate;
                    const month = (new Date(PaidDate).getMonth() + 1).toString().padStart(2, '0');

                    if (reportIncome[i].checkregister?.receipts?.length > 0)
                        path = `https://myirent.com/rent/Receipts/${props.propertyId}/${new Date(
                            PaidDate,
                        ).getFullYear()}/${month}/${reportIncome[i].checkregister.id}.pdf`;

                    income.push({
                        invoiceDate: reportIncome[i].checkregister.InvoiceDate,
                        paidDate: reportIncome[i].checkregister.PaidDate,
                        recipt: path,
                        incomeType: reportIncome[i].depositsource.DepositSource,
                        comment: reportIncome[i].tenant
                            ? reportIncome[i].Comment +
                              ' - ' +
                              `${reportIncome[i].tenant.TenantFName + ' ' + reportIncome[i].tenant.TenantLName}`
                            : reportIncome[i].Comment,
                        amount: currencyFormat(+reportIncome[i].TransactionAmount),
                    });
                }
            }
            expense.push({
                vendor: 'Total',
                amount: currencyFormat(+reportExpenseTotal[0].AmountTotal),
            });
            income.push({
                comment: 'Total',
                amount: currencyFormat(+reportIncomeTotal[0].AmountTotal),
            });
            setLoading(false);
        }
        props.propertyId && fetchData();
    }, [props]);
    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Reports...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }
    const expensesColumns = [
        { name: 'invoiceDate', label: 'Invoice Date' },
        { name: 'paidDate', label: 'Paid Date' },
        { name: 'expenseType', label: 'Expense Type' },
        { name: 'description', label: 'Description' },
        { name: 'vendor', label: 'Vendor' },
        { name: 'amount', label: 'Amount' },
        {
            name: 'recipt',
            label: 'Receipt',
            options: {
                customBodyRender: (value) => {
                    if (value && value !== '') {
                        return (
                            <IconButton
                                aria-label="Receipt"
                                onClick={() => {
                                    window.open(value, '_blank');
                                }}
                            >
                                <AttachFile />
                            </IconButton>
                        );
                    }
                },
            },
        },
    ];
    const incomeColumns = [
        { name: 'invoiceDate', label: 'Invoice Date' },
        { name: 'paidDate', label: 'Paid Date' },
        { name: 'incomeType', label: 'Income Type' },
        { name: 'comment', label: 'Comment' },
        { name: 'amount', label: 'Amount' },
        {
            name: 'recipt',
            label: 'Receipt',
            options: {
                customBodyRender: (value) => {
                    if (value && value !== '') {
                        return (
                            <IconButton
                                aria-label="Receipt"
                                onClick={() => {
                                    window.open(value, '_blank');
                                }}
                            >
                                <AttachFile />
                            </IconButton>
                        );
                    }
                },
            },
        },
    ];

    return (
        <div>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <span>
                            {props.reportName +
                                ' - ' +
                                filteredName.PropertyName +
                                ' from ' +
                                props.startDate +
                                ' to ' +
                                props.endDate}
                        </span>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Expenses'}
                            data={expense}
                            columns={expensesColumns}
                            options={{
                                ...props.reportOptions,
                                downloadOptions: {
                                    filename:
                                        props.reportName +
                                        ' - ' +
                                        filteredName.PropertyName +
                                        ' from ' +
                                        props.startDate +
                                        ' to ' +
                                        props.endDate,
                                },
                                // onDownload: (buildHead, buildBody, columns, data) => {
                                //     const csvData = data.map((row) => {
                                //         // Ensure row.data is correctly structured
                                //         const rowData = row.data ? [...row.data] : [];
                                //         // Assuming the link is in the third column (index 2)
                                //         if (rowData[6]) {
                                //             rowData[6] = '=HYPERLINK(' + rowData[6] + ')';
                                //         }
                                //         console.log('rowData', rowData);
                                //         return { data: rowData };
                                //     });

                                //     // Generate the CSV content using buildHead and buildBody
                                //     const csvHead = buildHead(columns);
                                //     const csvBody = buildBody(csvData, columns);

                                //     return `${csvHead}${csvBody}`;
                                // },
                            }}
                        />
                    </MuiThemeProvider>
                </div>
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Income'}
                            data={income}
                            columns={incomeColumns}
                            options={{
                                ...props.reportOptions,
                                downloadOptions: {
                                    filename:
                                        props.reportName +
                                        ' - ' +
                                        filteredName.PropertyName +
                                        ' from ' +
                                        props.startDate +
                                        ' to ' +
                                        props.endDate,
                                },
                                // onDownload: (buildHead, buildBody, columns, data) => {
                                //     const newData = data.map((row) => {
                                //         const rowData = row.data ? [...row.data] : [];
                                //         if (rowData[5]) {
                                //             rowData[5] = `=HYPERLINK("${rowData[5]}")`;
                                //         }
                                //         return rowData;
                                //     });
                                //     return buildHead(columns) + buildBody(newData);
                                // },
                            }}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
        </div>
    );
};

export default GeneralLedger;

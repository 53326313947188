import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Button } from 'reactstrap';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Modal from 'react-modal';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Constants from '../Util/constants';
import * as unitsAPI from '../../Api/units';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-8%',
        transform: 'translate(-50%, -50%)',
        height: 'au',
    },
    overlay: { zIndex: 1000 },
};

const tdStyle = {
    maxWidth: '100%',
};

const PostVacancies = () => {
    const login = useSelector((state) => state.login);
    const propertyID = login.selectedPropertyID;

    const [loading, setLoading] = useState(false);
    //const [units, setUnits] = useState([]);
    const [showUnits, setShowUnits] = useState([]);
    const [onlyVacant, setOnlyVacant] = useState(true);
    const [unitsToPost, setUnitsToPost] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [automaticPostVacant, setAutomaticPostVacant] = useState(true);
    const [automaticRemoveNonVacant, setAutomaticRemoveNonVacant] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const units = await unitsAPI.getPostVacanciesUnits(propertyID);
            //setUnits(units);
            const arr = [];
            const occupied = onlyVacant ? 0 : 1;
            for (const u of units) {
                if (occupied === parseInt(u.Occupied)) {
                    const today = moment.utc();
                    const vacant = moment.utc(u.VacantDate);
                    const daysOnMarket = today.diff(vacant, 'days');
                    const loss = parseFloat(u.UnitCharge) * ((daysOnMarket * 12) / 365);
                    arr.push({
                        unit: u.UnitName,
                        unitType: u.UnitType,
                        moveOutDate: moment.utc(u.VacantDate).format('MM/DD/YYYY'),
                        daysOnMarket,
                        marketRent: parseFloat(u.UnitCharge).toFixed(2),
                        lossToVacancy: parseFloat(loss).toFixed(2),
                        unitID: u.UnitID,
                    });
                }
            }
            setShowUnits(arr);
            setLoading(false);
        }
        fetchData();
    }, [propertyID, onlyVacant]);

    const columns = [
        { name: 'unit', label: 'Unit' },
        { name: 'unitType', label: 'Unit Type' },
        { name: 'moveOutDate', label: 'Move Out Date' },
        { name: 'daysOnMarket', label: 'Days on Market' },
        {
            name: 'marketRent',
            label: 'Market Rent',
            options: {
                customBodyRender: (value) => {
                    return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} />;
                },
            },
        },
        {
            name: 'lossToVacancy',
            label: 'Loss to Vacancy',
            options: {
                customBodyRender: (value) => {
                    return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} />;
                },
            },
        },
    ];

    const options = {
        filterType: 'dropdown',
        pagination: false,
        onRowSelectionChange: (_, allSelected) => {
            const temp = [];
            for (const a of allSelected) {
                temp.push(showUnits[a.index]);
            }
            setUnitsToPost(temp);
        },
    };

    const handlePostVacancies = () => {
        if (unitsToPost.length === 0) {
            NotificationManager.warning('Please, select at least one unit', 'Error');
            return;
        }
        setOpenModal(true);
    };

    const renderButtons = () => {
        return (
            <>
                <Button color="link" onClick={() => setOnlyVacant(!onlyVacant)}>
                    {onlyVacant ? 'Show all units' : 'Show only vacant units'}
                </Button>
                <Button color="link" onClick={handlePostVacancies}>
                    Post
                </Button>
            </>
        );
    };

    const postUnits = async () => {
        setLoading(true);
        const res = await unitsAPI.postVacancies({
            propertyID,
            autoPostVacancies: automaticPostVacant ? 1 : 0,
            autoRemoveNonVacancies: automaticRemoveNonVacant ? 1 : 0,
            unitsUpdated: unitsToPost.length,
            selectedUnits: unitsToPost,
        });
        setLoading(false);
        setOpenModal(false);
        if (res !== 0) {
            NotificationManager.errors(Constants.DEFAULT_ERROR, 'Error');
            return;
        }
        NotificationManager.success(
            'Your units have been submitted to the various websites. Actual posting times are dependent upon the individual sites.',
            'Success',
        );
    };

    const renderModalContent = () => {
        return (
            <div>
                <p>Confirm to post the vacancies to the following boards:</p>
                <hr />
                <table>
                    <tr>
                        <td>
                            <a href="https://www.apartments.com/" target="_blank" rel="noreferrer">
                                <img
                                    style={tdStyle}
                                    src="https://myirent.com/rent/AdSiteImg/apartments.png"
                                    height="35"
                                    alt="apartments.com"
                                />
                            </a>
                        </td>
                        <td>
                            <a href="https://www.apartmentfinder.com/" target="_blank" rel="noreferrer">
                                <img
                                    style={tdStyle}
                                    src="https://myirent.com/rent/AdSiteImg/apartmentsFinder.png"
                                    height="35"
                                    alt="apartmentfinder.com"
                                />
                            </a>
                        </td>
                        <td>
                            <a href="https://www.apartamentos.com/" target="_blank" rel="noreferrer">
                                <img
                                    style={tdStyle}
                                    src="https://myirent.com/rent/AdSiteImg/apartamentos.png"
                                    height="35"
                                    alt="apartamentos.com"
                                />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="https://www.apartmenthomeliving.com/" target="_blank" rel="noreferrer">
                                <img
                                    style={tdStyle}
                                    src="https://myirent.com/rent/AdSiteImg/apartmenthomeliving.png"
                                    height="35"
                                    alt="apartmenthomeliving.com"
                                />
                            </a>
                        </td>
                        <td>
                            <a href="https://www.rentjungle.com/" target="_blank" rel="noreferrer">
                                <img
                                    style={tdStyle}
                                    src="https://myirent.com/rent/AdSiteImg/rentjungle.png"
                                    height="35"
                                    alt="rentjungle.com"
                                />
                            </a>
                        </td>
                        <td>
                            <a href="https://www.oodle.com/" target="_blank" rel="noreferrer">
                                <img
                                    style={tdStyle}
                                    src="https://myirent.com/rent/AdSiteImg/oodle.png"
                                    height="35"
                                    alt="oodle.com"
                                />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="https://www.westsiderentals.com/" target="_blank" rel="noreferrer">
                                <img
                                    style={tdStyle}
                                    src="https://myirent.com/rent/AdSiteImg/westsideRentals.png"
                                    height="35"
                                    alt="westsiderentals.com"
                                />
                            </a>
                        </td>
                        <td>
                            <a href="https://www.zumper.com/" target="_blank" rel="noreferrer">
                                <img
                                    style={tdStyle}
                                    src="https://myirent.com/rent/AdSiteImg/zumper.png"
                                    height="35"
                                    alt="zumper.com"
                                />
                            </a>
                        </td>
                    </tr>
                </table>
                <hr />
                <FormControlLabel
                    control={<Checkbox checked={automaticPostVacant} />}
                    label="Automatic post vacant units"
                    onClick={() => {
                        setAutomaticPostVacant(!automaticPostVacant);
                    }}
                />
                <br />
                <FormControlLabel
                    control={<Checkbox checked={automaticRemoveNonVacant} />}
                    label="Automatic remove non vacant units"
                    onClick={() => {
                        setAutomaticRemoveNonVacant(!automaticRemoveNonVacant);
                    }}
                />
                <hr />
                <Button type="button" color="primary" size="sm" className="w-auto" onClick={postUnits}>
                    Post
                </Button>{' '}
                <Button type="button" color="danger" size="sm" className="w-auto" onClick={() => setOpenModal(false)}>
                    Cancel
                </Button>
            </div>
        );
    };

    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Post Vacancies...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <>
            <Modal
                isOpen={openModal}
                onRequestClose={() => setOpenModal(false)}
                contentLabel="Units selected"
                style={modalStyles}
            >
                {renderModalContent()}
            </Modal>
            <Main>
                <div className="formelements-wrapper" style={Constants.margins}>
                    <div className="page-title d-flex justify-content-between align-items-center">
                        <div className="page-title-wrap">
                            <h2>
                                <span>Post Vacancies</span>
                            </h2>
                        </div>
                    </div>
                    <div className="row">{renderButtons()}</div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable title="Units" data={showUnits} columns={columns} options={options} />
                            </MuiThemeProvider>
                        </div>
                    </div>
                </div>
            </Main>
        </>
    );
};

export default PostVacancies;


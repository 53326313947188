import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const XDelinquencySummary = (props) => {
  const [loading, setLoading] = useState(false);
  const [delinqData, setDelinqData] = useState([]);
  var [thirtyTotal, setThirtyTotal] = useState(0);
  var [sixtyTotal, setSixtyTotal] = useState(0);
  var [ninetyTotal, setNinetyTotal] = useState(0);
  var [ninetyPlusTotal, setNinetyPlusTotal] = useState(0);
  const properties = useSelector((state) => state.login.properties);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      var endDate = new Date(props.reportSearch.eDt);

      var lastThirty = new Date(
        new Date(endDate).setDate(endDate.getDate() - 30)
      );
      var lastSixty = new Date(
        new Date(lastThirty).setDate(lastThirty.getDate() - 30)
      );
      var lastNinety = new Date(
        new Date(lastSixty).setDate(lastSixty.getDate() - 30)
      );
      for (let i = 0; i < properties.length; i++) {
        props.reportSearch.pID = `${properties[i].PropertyID}`;
        const delinq = await reportsAPI.getXDelinquency(props.reportSearch);
        for (let i = 0; i < delinq.length; i++) {
          const debt = +delinq[i].TOTAL;
          if (delinq[i].tenant) {
            const propertyName = delinq[i].tenant.property.PropertyName;
            if (0 > debt) {
              if (
                endDate > new Date(delinq[i].TenantTransactionDate) &&
                lastThirty < new Date(delinq[i].TenantTransactionDate)
              ) {
                thirtyTotal += debt;
              }
              if (
                lastThirty > new Date(delinq[i].TenantTransactionDate) &&
                lastSixty < new Date(delinq[i].TenantTransactionDate)
              ) {
                sixtyTotal += debt;
              }
              if (
                lastSixty > new Date(delinq[i].TenantTransactionDate) &&
                lastNinety < new Date(delinq[i].TenantTransactionDate)
              ) {
                ninetyTotal += debt;
              }
              if (lastNinety > new Date(delinq[i].TenantTransactionDate)) {
                ninetyPlusTotal += debt;
              }
            }
            delinqData[propertyName] = [
              {
                property: delinq[i].tenant.property.PropertyName,
                future: currencyFormat(0),
                thirtyDays: currencyFormat(thirtyTotal),
                sixtyDays: currencyFormat(sixtyTotal),
                ninetyDays: currencyFormat(ninetyTotal),
                overninetyDays: currencyFormat(ninetyPlusTotal),
                prepayments: currencyFormat(0),
                total: currencyFormat(
                  thirtyTotal + sixtyTotal + ninetyTotal + ninetyPlusTotal
                ),
              },
            ];
          }
        }
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const delinqColumns = [
    { name: "property", label: "Property" },
    { name: "future", label: "Future" },
    { name: "thirtyDays", label: "0-30 Days" },
    { name: "sixtyDays", label: "31-60 Days" },
    { name: "ninetyDays", label: "61-90 Days" },
    { name: "overninetyDays", label: "Over 90 Days" },
    { name: "prepayments", label: "Prepayments" },
    { name: "total", label: "Total" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - Date Range of Report Up To " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        {properties.map((property) => {
          return (
            <div className="col-sm-12 col-md-12 col-xl-12">
              <MuiThemeProvider theme={Constants.getMuiTheme()}>
                <MUIDataTable
                  title={`X-Property ${property.PropertyName} Delinquency`}
                  data={delinqData[property.PropertyName]}
                  columns={delinqColumns}
                  options={props.reportOptions}
                />
              </MuiThemeProvider>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default XDelinquencySummary;

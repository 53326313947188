import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import MatButton from '@material-ui/core/Button';
import { NotificationManager } from 'react-notifications';
import { Link, useHistory } from 'react-router-dom';

import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as workOrdersAPI from '../../Api/workOrders';
import * as Constants from '../Util/constants';

const ListOpenWorkOrders = (props) => {
    const history = useHistory();
    const { propertyID } = props;

    const [loading, setLoading] = useState(true);
    const [workOrders, setWorkOrders] = useState([]);
    const [hiddenImages, setHiddenImages] = useState([]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const openWKs = await workOrdersAPI.getOpens(propertyID);
            let wks = [];
            for (const wk of openWKs) {
                let image = '';
                const url = `https://myirent.com/rent/iRentAppIMG/${wk.PropertyID}/${wk.UnitID}/WKID_${wk.WOrkOrderID}.png`;
                image = (
                    <MatButton href={url} target="_blank" color="primary">
                        <img
                            src={url}
                            width={70}
                            onError={(e) => {
                                const element = e.target;
                                element.style.display = 'none';
                            }}
                        />
                    </MatButton>
                );
                wks.push({
                    unit: wk.UnitName === null ? 'Common Area' : wk.UnitName,
                    tenant: wk.TenantFName === null ? '' : `${wk.TenantFName} ${wk.TenantLName}`,
                    dateSubmitted: moment(wk.WorkOrderSubmitDate).format('MM/DD/YYYY'),
                    submittedBy: wk.UserFName === null ? '' : `${wk.UserFName} ${wk.UserLName}`,
                    description: wk.WorkOrderDescription,
                    staffComment: wk.WorkOrderComment !== null && wk.WorkOrderComment !== '0' ? wk.WorkOrderComment : '',
                    commentDate:
                        wk.WorkOrderComment !== null && wk.WorkOrderComment !== '0'
                            ? moment(wk.WorkOrderCompleteDate).format('MM/DD/YYYY')
                            : '',
                    staffAssigned: wk.MainFName === null ? '' : `${wk.MainFName} ${wk.MainLName}`,
                    status: wk.Status,
                    priority: wk.Priority,
                    tenantConsent: parseInt(wk.allowMaintenanceGetIn) === 1 ? 'Yes' : 'No',
                    image: image,
                    update: wk.WOrkOrderID,
                });
            }
            setWorkOrders(wks);
            setLoading(false);
        }
        fetchData();
    }, [propertyID]);

    const getImgLink = async (val) => {
        setLoading(true);
        const res = await workOrdersAPI.getImage({
            propertyID: val.PropertyID,
            unitID: val.UnitID,
            workOrderID: val.WOrkOrderID,
        });
        console.log('val', val);
        setLoading(false);
        if (res === '') {
            NotificationManager.warning('No image associated to this work order.', 'Warning!');
            return;
        }
        window.open(res, '_blank');
    };

    const columns = [
        { name: 'unit', label: 'Unit' },
        { name: 'tenant', label: 'Tenant' },
        { name: 'dateSubmitted', label: 'Date Submitted' },
        { name: 'submittedBy', label: 'Submitted By' },
        {
            name: 'description',
            label: 'Description',
            options: {
                customBodyRender: (value, { rowData }) => {
                    console.log('rowData', rowData);
                    return (
                        <Link
                            to="/printable/viewWorkOrder"
                            onClick={async () => {
                                await localStorage.setItem('workOrderID', rowData[12]);
                                await localStorage.setItem('propertyID', propertyID);
                            }}
                            target="_blank"
                        >
                            {value}
                        </Link>
                    );
                },
            },
        },
        { name: 'staffComment', label: 'Staff Comment' },
        { name: 'commentDate', label: 'Comment Date' },
        { name: 'staffAssigned', label: 'Staff Assigned' },
        { name: 'status', label: 'Status' },
        { name: 'priority', label: 'Priority' },
        { name: 'tenantConsent', label: 'Tenant Consent' },
        {
            name: 'image',
            label: 'Image',
            options: {
                download: false,
                print: false,
            },
            // options: {
            //     customBodyRender: async (value) => {
            //         if (value === '') return <span></span>;
            //         const url = `https://myirent.com/rent/iRentAppIMG/${value.PropertyID}/${value.UnitID}/WKID_${value.WOrkOrderID}.png`;
            //         const res = await workOrdersAPI.getImage({
            //             propertyID: value.PropertyID,
            //             unitID: value.UnitID,
            //             workOrderID: value.WOrkOrderID,
            //         });
            //         if (res === '') return <span></span>;
            //         return (
            //             // <a>
            //             //
            //             // </a>
            //             <MatButton href={url} target="_blank" color="primary">
            //                 <img src={url} width={70} />
            //             </MatButton>
            //         );
            //     },
            // },
        },
        {
            name: 'update',
            label: 'Update',
            options: {
                customBodyRender: (value) => {
                    return (
                        <MatButton
                            color="primary"
                            onClick={() => {
                                const location = {
                                    pathname: '/workOrders/update',
                                    state: {
                                        workOrderID: value,
                                        return: '/workOrders/add',
                                    },
                                };
                                history.push(location);
                            }}
                        >
                            Update
                        </MatButton>
                    );
                },
                download: false,
                print: false,
            },
        },
    ];

    const options = {
        filterType: 'dropdown',
        pagination: false,
        selectableRows: 'none',
        customSearch: (searchQuery, currentRow, columns) => {
            let found = false;
            currentRow.forEach((element) => {
                if (element === null) found = false;
                else if (typeof element === 'object') {
                    if (
                        element.UnitName !== null &&
                        element.UnitName.toString().trim().toUpperCase().includes(searchQuery.toUpperCase())
                    )
                        found = true;
                    if (
                        element.TenantFName !== null &&
                        element.TenantFName.toString().toUpperCase().includes(searchQuery.toUpperCase())
                    )
                        found = true;
                    if (
                        element.TenantLName !== null &&
                        element.TenantLName.toString().toUpperCase().includes(searchQuery.toUpperCase())
                    )
                        found = true;
                    if (
                        element.WorkOrderDescription !== null &&
                        element.WorkOrderDescription.toString().toUpperCase().includes(searchQuery.toUpperCase())
                    )
                        found = true;
                } else if (element.toString().toUpperCase().includes(searchQuery.toUpperCase())) {
                    found = true;
                }
            });
            return found;
        },
    };

    const render = () => {
        if (loading) {
            return (
                <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Work Orders...'}>
                    <LinearProgress />
                </RctCollapsibleCard>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <MuiThemeProvider theme={Constants.getMuiTheme()}>
                            <MUIDataTable title={'Open Work Orders'} data={workOrders} columns={columns} options={options} />
                        </MuiThemeProvider>
                    </div>
                </div>
            );
        }
    };

    return render();
};

export default ListOpenWorkOrders;


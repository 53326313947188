import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import AssignmentIndOutlined from "@material-ui/icons/AssignmentIndOutlined";
import { useHistory } from "react-router-dom";

const LeaseRenewals = (props) => {
  const [loading, setLoading] = useState(false);
  const [leaseRenData, setLeaseRenData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  const history = useHistory();
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getLeaseRenewals(
        props.reportSearch
      );
      for (let i = 0; i < reportDetails.length; i++) {
        if (
          reportDetails[i].tenants[0].LeaseStartDate !==
          reportDetails[i].tenants[0].MoveInDate
        ) {
          leaseRenData.push({
            tenant:
              reportDetails[i].tenants[0].TenantFName +
              " " +
              reportDetails[i].tenants[0].TenantLName +
              " " +
              reportDetails[i].tenants[0].id,
            unit: reportDetails[i].UnitName,
            unitType: reportDetails[i].unittype.UnitType,
            moveInDate: formatDate(reportDetails[i].tenants[0].MoveInDate),
            leaseStartDate: formatDate(
              reportDetails[i].tenants[0].LeaseStartDate
            ),
            leaseEndDate: formatDate(reportDetails[i].tenants[0].LeaseEndDate),
            days: Math.floor(
              (new Date().getTime() -
                new Date(reportDetails[i].tenants[0].LeaseEndDate).getTime()) /
                (24 * 60 * 60 * 1000)
            ),
            mktRent: currencyFormat(+reportDetails[i].unittype.UnitCharge),
            comments: reportDetails[i].tenants[0].ProspectComments,
            edit: (
              <IconButton
                aria-label="Print"
                onClick={() => {
                  const location = {
                    pathname: "/tenants/editTenant",
                    state: {
                      tenantID: reportDetails[i].tenants[0].id,
                      tenantName: `${reportDetails[i].tenants[0].TenantFName} ${reportDetails[i].tenants[0].TenantLName}`,
                    },
                  };
                  history.push(location);
                }}
              >
                <Edit />
              </IconButton>
            ),
            prelease: (
              <td className="text-center">
                <IconButton
                  aria-label="Edit"
                  onClick={() => {
                    const location = {
                      pathname: "/preLease",
                      state: {
                        unitID: reportDetails[i].id,
                      },
                    };
                    history.push(location);
                  }}
                >
                  <AssignmentIndOutlined style={{ color: "#228B22" }} />
                </IconButton>
              </td>
            ),
          });
        }
      }
      leaseRenData.push({ tenant: "TOTAL", unit: leaseRenData.length });
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const leaseRenColumns = [
    { name: "tenant", label: "Tenant" },
    { name: "unit", label: "Unit" },
    { name: "unitType", label: "Unit Type" },
    { name: "moveInDate", label: "Move In Date" },
    { name: "leaseStartDate", label: "Lease Start Date" },
    { name: "leaseEndDate", label: "Lease End Date" },
    { name: "days", label: "Days" },
    { name: "mktRent", label: "Mkt Rent" },
    { name: "comments", label: "Comments" },
    { name: "edit", label: "Edit" },
    { name: "prelease", label: "Prelease" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Renewals"}
              data={leaseRenData}
              columns={leaseRenColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default LeaseRenewals;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const PrePaidRent = (props) => {
  const [loading, setLoading] = useState(false);
  const [prepaidData, setPrepaidData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getPrePaidRent(props.reportSearch);
      for (let i = 0; i < reportDetails.length; i++) {
        prepaidData.push({
          unit: reportDetails[i].tenant.unit?.UnitName,
          tenant:
            reportDetails[i].tenant.TenantFName +
            " " +
            reportDetails[i].tenant.TenantLName,
          date: formatDate(reportDetails[i].TenantTransactionDate),
          amount: currencyFormat(+reportDetails[i].TransactionAmount),
          comment: reportDetails[i].tenant.unit.Comment,
          checkNumber: reportDetails[i].CheckNumber,
        });
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const prepaidColumns = [
    { name: "unit", label: "Unit" },
    { name: "tenant", label: "Tenant" },
    { name: "date", label: "Date" },
    { name: "amount", label: "Amount" },
    { name: "comment", label: "Comment" },
    { name: "checkNumber", label: "Check Number" },
  ];

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " up to " +
                props.endDate +
                " - Total: " +
                prepaidData.length}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Payables"}
              data={prepaidData}
              columns={prepaidColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default PrePaidRent;

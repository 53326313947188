import React, { useState, useEffect, useMemo } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import MatButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Loop from '@material-ui/icons/Loop';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import HourglassFullTwoTone from '@material-ui/icons/HourglassFullTwoTone';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import DeleteForever from '@material-ui/icons/DeleteForever';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationManager } from 'react-notifications';
import Alert from '@material-ui/lab/Alert';
import { Button, Form, Label, Input } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Main from '../../Main';
import RctCollapsibleCard from '../../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../../Util/LinearProgress';
import * as Constants from '../../Util/constants';
import * as applicantsAPI from '../../../Api/applicants';
import * as tenantsAPI from '../../../Api/tenants';
import * as companyAPI from '../../../Api/company';
import Review from './Review';
import { FormGroup, Dialog, Slide, Switch, AppBar, MenuItem, Select } from '@material-ui/core';
import Modal from 'react-modal';
import { getProperty, getCompanyProperties } from '../../../Api/property';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-8%',
        transform: 'translate(-50%, -50%)',
        width: '35vw',
    },
    overlay: { zIndex: 1000 },
};

const ViewAll = () => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const propertyID = login.selectedPropertyID;
    const propertyName = login.properties;
    const [filteredName] = propertyName.filter((property) => property.PropertyID === propertyID);
    const company = login.company;
    const companyID = company.id;

    const [loading, setLoading] = useState(false);
    const [applicants, setApplicants] = useState([]);
    const [deleteApplicant, setDeleteApplicant] = useState(0);
    const [reviewTenantID, setReviewTenantID] = useState(0);
    const [reviewData, setReviewData] = useState({});
    const [screeningReports, setScreeningReports] = useState(new Map());
    const [unitName, setUnitName] = useState('');
    const [updated, setUpdated] = useState(false);
    const [vacantUnits, setVacantUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState(0);
    const [needToSignUp, setNeedToSignUp] = useState(true);
    const [leadSourceCompanyID, setLeadSourceCompanyID] = useState(0);
    const [zipReportsCredentials, setZipReportsCredentials] = useState({
        username: '',
        password: '',
    });
    const [isZipReports, setIsZipReports] = useState(false);
    const [showMoveoutModal, setShowMoveoutModal] = useState(false);
    const [properties, setProperties] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState({});
    const [selectedProperty, setSelectedProperty] = useState({});

    const checkIfCompanyIsZip = async () => {
        const ZIP_REPORTS_LEAD_SOURCE_COMPANY_ID = 20;
        const company = await companyAPI.get(companyID);
        if (company && parseInt(company.LeadSourceCompanyID) === ZIP_REPORTS_LEAD_SOURCE_COMPANY_ID) return true;
        return false;
    };

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setProperties(propertyName)
            const isZip = await checkIfCompanyIsZip();
            setIsZipReports(isZip);
            const getApplicants = await applicantsAPI.getApplicants(propertyID);
            setScreeningReports(new Map(Object.entries(getApplicants.backgroundScreening)));
            const arr = [];
            for (const a of getApplicants.applicants) {
                arr.push({
                    dateEntered: moment.utc(a.ProspectStartDate).format('MM/DD/YYYY'),
                    name: `${a.TenantFName} ${a.TenantMName || ""} ${a.TenantLName}`,
                    preLeased: a.UnitName ? `${a.UnitName} - ${moment.utc(a.PMoveInDate).format('MM/DD/YYYY')}` : '',
                    phone: a.TenantPhone,
                    email: a.TenantEmail,
                    interest: a.UnitType,
                    leasingAgent: a.UserFName !== null ? `${a.UserFName} ${a.UserLName}` : '',
                    comments: a.ProspectComments,
                    delete: a.TenantID,
                    creditCheck: a,
                    review: a,
                    convert: a,
                    moveout: a
                });
            }
            setApplicants(arr);
            const vacantU = await tenantsAPI.getVacantUnitsByProperty(propertyID);
            if (vacantU.length > 0) {
                setSelectedUnit(vacantU[0].UnitID);
                setUnitName(vacantU[0].UnitName);
            }
            setVacantUnits(vacantU);
            setNeedToSignUp(await applicantsAPI.companyNeedToSignUpScreening(companyID));
            const getCompany = await applicantsAPI.getCompanyDetails(companyID);
            if (getCompany) {
                setLeadSourceCompanyID(parseInt(getCompany.LeadSourceCompanyID));
                setZipReportsCredentials({
                    username: getCompany.zipReportsUser !== undefined ? getCompany.zipReportsUser : '',
                    password: getCompany.zipReportsPassword !== undefined ? getCompany.zipReportsPassword : '',
                });
            }
            setLoading(false);
        }
        fetchData();
    }, [propertyID, companyID, updated]);

    const columns = [
        { name: 'dateEntered', label: 'Date Entered' },
        {
            name: 'name',
            label: 'Name',
            options: {
                customBodyRender: (value, { rowData }) => {
                    const tenantID = rowData[8];
                    const leaseHodlers = screeningReports.get(tenantID.toString()) || [];
                    if (value !== '') {
                        return (
                            <ul>
                                {leaseHodlers.map((obj, idx) => {
                                    const renderName = () => {
                                        if (obj.name === null) {
                                            return (
                                                <MatButton
                                                    color="primary"
                                                    onClick={() => {
                                                        const location = {
                                                            pathname: '/prospects/edit',
                                                            state: {
                                                                tenantID: parseInt(tenantID),
                                                                name: 'Applicant',
                                                            },
                                                        };
                                                        history.push(location);
                                                    }}
                                                >
                                                    {value}
                                                </MatButton>
                                            );
                                        }
                                        return (
                                            <MatButton
                                                color="primary"
                                                onClick={() => {
                                                    const location = {
                                                        pathname: '/applicants/viewReport',
                                                        state: {
                                                            tenantID: parseInt(tenantID),
                                                            name: value,
                                                        },
                                                    };
                                                    history.push(location);
                                                }}
                                                style={{ height: '1rem' }}
                                            >
                                                {obj.name}
                                            </MatButton>
                                        );
                                    };
                                    return <li key={idx}>{renderName()}</li>;
                                })}
                            </ul>
                        );
                    }
                },
            },
        },
        {
            name: 'preLeased',
            label: 'Pre Leased',
        },
        {
            name: 'phone',
            label: 'Phone',
            options: {
                customBodyRender: (value) => {
                    if (value !== '') {
                        return (
                            <a href={`tel:${value}`}>
                                {value.includes('+1') ? (
                                    value
                                ) : (
                                    <NumberFormat value={value} format="+1 (###) ###-####" displayType={'text'} />
                                )}
                            </a>
                        );
                    }
                },
            },
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                customBodyRender: (value) => {
                    if (value !== '') return <a href={`mailto:${value}`}>{value}</a>;
                },
            },
        },
        { name: 'interest', label: 'Interest' },
        {
            name: 'leasingAgent',
            label: 'Leasing Agent',
            options: {
                customBodyRender: (value, { rowData }) => {
                    const tenantID = rowData[8];
                    const name =
                        rowData[9].UserFName !== null
                            ? `${rowData[9].UserFName} ${rowData[9].UserLName}`
                            : 'External Applicant';
                    return (
                        <>
                            {name}
                            <MatButton
                                className="text-primary mr-10 mb-10"
                                onClick={() => {
                                    const location = {
                                        pathname: '/applicants/viewApplication',
                                        state: {
                                            tenantID: tenantID,
                                        },
                                    };
                                    history.push(location);
                                }}
                            >
                                View Application
                            </MatButton>
                        </>
                    );
                },
            },
        },
        { name: 'comments', label: 'Comments' },
        {
            name: 'delete',
            label: 'Delete',
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton
                            onClick={() => {
                                setDeleteApplicant(parseInt(value));
                            }}
                        >
                            <DeleteForever />
                        </IconButton>
                    );
                },
                download: false,
            },
        },
        {
            name: 'creditCheck',
            label: 'Credit Check',
            options: {
                customBodyRender: (value) => {
                    const renderCreditCheck = () => {
                        // ZipReports
                        if (isZipReports) {
                            let reports = screeningReports.get(value.TenantID.toString()) || [];
                            return (
                                <ul>
                                    {reports.map((obj, idx) => {
                                        const name =
                                            obj.name === null ? `${value.TenantFName} ${value.TenantLName}` : obj.name;
                                        const renderAction = () => {
                                            if (obj.report === null) {
                                                return (
                                                    <IconButton
                                                        onClick={() => {
                                                            if (obj.name === null) {
                                                                const location = {
                                                                    pathname: '/applicants/runBackgroundScreening',
                                                                    state: {
                                                                        tenantID: parseInt(value.TenantID),
                                                                        othersOnLeaseID: 0,
                                                                    },
                                                                };
                                                                history.push(location);
                                                            } else {
                                                                const location = {
                                                                    pathname: '/applicants/runBackgroundScreening',
                                                                    state: {
                                                                        tenantID: parseInt(value.TenantID),
                                                                        othersOnLeaseID: parseInt(obj.othersOnLeaseID),
                                                                    },
                                                                };
                                                                history.push(location);
                                                            }
                                                        }}
                                                        style={{ height: '1.5rem' }}
                                                    >
                                                        <FindInPageOutlinedIcon />
                                                    </IconButton>
                                                );
                                            }
                                            // Report is not ready
                                            if (
                                                obj.report.statusCode !== undefined &&
                                                parseInt(obj.report.statusCode) !== 2
                                            ) {
                                                return (
                                                    <IconButton
                                                        style={{ height: '1.5rem' }}
                                                        onClick={() => NotificationManager.warning(obj.report.statusMessage)}
                                                    >
                                                        <HourglassFullTwoTone style={{ color: 'blue' }} />
                                                    </IconButton>
                                                );
                                            }
                                            return (
                                                <IconButton
                                                    onClick={() => {
                                                        // open zipreprots in a new tab
                                                        const controlNo = obj.report.controlNo;
                                                        const controlKey = obj.report.controlKey;
                                                        const url = `https://api.icreditvisions.com/api?func_name=view&source=api&login=${zipReportsCredentials.username}&password=${zipReportsCredentials.password}&controlno=${controlNo}&dcontrolno_key=${controlKey}&formatHTML=Y`;
                                                        window.open(url, '_blank');
                                                    }}
                                                    style={{ height: '1.5rem' }}
                                                >
                                                    <CheckOutlinedIcon style={{ color: 'green' }} />
                                                </IconButton>
                                            );
                                        };
                                        return (
                                            <span key={idx} style={{ display: 'inline-block' }}>
                                                {name} {renderAction()}
                                            </span>
                                        );
                                    })}
                                </ul>
                            );
                        }

                        // check if need to SignUp
                        if (needToSignUp) {
                            return (
                                <MatButton
                                    className="text-primary mr-10 mb-10"
                                    onClick={() => {
                                        console.log(leadSourceCompanyID);
                                        if (leadSourceCompanyID && leadSourceCompanyID === 19) {
                                            // navigate to
                                            const url = `http://zipreports.com/zipapp.php?apptype=pg_company&promocode=TENANTFILE`;
                                            window.open(url, '_blank');
                                        } else {
                                            const location = {
                                                pathname: '/applicants/signupBackground',
                                            };
                                            history.push(location);
                                        }
                                    }}
                                >
                                    Signup
                                </MatButton>
                            );
                        }

                        let reports = screeningReports.get(value.TenantID.toString()) || [];
                        return (
                            <ul>
                                {reports.map((obj, idx) => {
                                    const name = obj.name === null ? `${value.TenantFName} ${value.TenantLName}` : obj.name;

                                    const renderAction = () => {
                                        if (obj.report === null) {
                                            return (
                                                <IconButton
                                                    onClick={() => {
                                                        if (obj.name === null) {
                                                            const location = {
                                                                pathname: '/applicants/runBackgroundScreening',
                                                                state: {
                                                                    tenantID: parseInt(value.TenantID),
                                                                    othersOnLeaseID: 0,
                                                                },
                                                            };
                                                            history.push(location);
                                                        } else {
                                                            const location = {
                                                                pathname: '/applicants/runBackgroundScreening',
                                                                state: {
                                                                    tenantID: parseInt(value.TenantID),
                                                                    othersOnLeaseID: parseInt(obj.othersOnLeaseID),
                                                                },
                                                            };
                                                            history.push(location);
                                                        }
                                                    }}
                                                    style={{ height: '1.5rem' }}
                                                >
                                                    <FindInPageOutlinedIcon />
                                                </IconButton>
                                            );
                                        }
                                        // if (obj.report[0].StatusCode !== undefined && parseInt(obj.report[0].StatusCode) === 0) {
                                        //     return (
                                        //         <IconButton style={{ height: '1.5rem' }}
                                        //             onClick={() => {NotificationManager.warning("Report is pending!")
                                        //             const location = {
                                        //                 pathname: '/applicants/viewReport',
                                        //                 state: {
                                        //                     isCIC: true,
                                        //                     reportKey: obj.report[0].Key,
                                        //                     reportID: obj.report[0].ReportID
                                        //                 }
                                        //             }
                                        //             history.push(location);}}
                                        //         >
                                        //             <HourglassFullTwoTone style={{ color: 'blue' }} />
                                        //         </IconButton>
                                        //     )
                                        // }
                                        return (
                                            <IconButton
                                                onClick={() => {
                                                    if (obj.report[0].OrderID === undefined) {
                                                        const location = {
                                                            pathname: '/applicants/viewReport',
                                                            state: {
                                                                isCIC: true,
                                                                reportKey: obj.report[0].Key,
                                                                reportID: obj.report[0].ReportID,
                                                            },
                                                        };
                                                        history.push(location);
                                                    } else {
                                                        const location = {
                                                            pathname: '/applicants/viewReport',
                                                            state: {
                                                                isCIC: false,
                                                                reportKey: '',
                                                                reportID: obj.report[0].OrderID,
                                                            },
                                                        };
                                                        history.push(location);
                                                    }
                                                }}
                                                style={{ height: '1.5rem' }}
                                            >
                                                <CheckOutlinedIcon style={{ color: 'green' }} />
                                            </IconButton>
                                        );
                                    };
                                    return (
                                        <span key={idx} style={{ display: 'inline-block' }}>
                                            {name} {renderAction()}
                                        </span>
                                    );
                                })}
                            </ul>
                        );
                    };

                    return renderCreditCheck();
                },
                download: false,
            },
        },
        {
            name: 'review',
            label: 'Review',
            options: {
                customBodyRender: (value) => {
                    return (
                        <>
                            {value.Approve}
                            <MatButton
                                className="text-primary mr-10 mb-10"
                                onClick={() => {
                                    setReviewTenantID(parseInt(value.TenantID));
                                    setReviewData({
                                        name: `${value.TenantFName} ${value.TenantMName} ${value.TenantLName}`,
                                        approveID: parseInt(value.ApproveID),
                                        backgroundID: parseInt(value.BackgroundID),
                                    });
                                }}
                            >
                                Review
                            </MatButton>
                        </>
                    );
                },
                download: false,
            },
        },
        {
            name: 'moveout',
            label: 'Transfer',
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton
                            aria-label="Print"
                            onClick={() => {

                                setSelectedTenant(value);
                                setShowMoveoutModal(true);
                            }}
                            style={{ color: 'gold' }}
                        >
                            <Loop />
                        </IconButton>
                    );
                },
                download: false,
            },
        },
        {
            name: 'convert',
            label: 'Convert',
            options: {
                customBodyRender: (value) => {
                    if (parseInt(value.ApproveID) !== 1) return <Alert severity="error">Application not Approved.</Alert>;
                    if (vacantUnits.length === 0)
                        return <Alert severity="warning">Property does not have vacant units.</Alert>;

                    return (
                        <>
                            <Input
                                type="select"
                                value={selectedUnit}
                                onChange={(e) => {
                                    setSelectedUnit(parseInt(e.target.value));
                                    setUnitName(vacantUnits[e.nativeEvent.target.selectedIndex].UnitName);
                                }}
                            >
                                {vacantUnits.map((obj, idx) => (
                                    <option key={idx} value={obj.UnitID}>
                                        {obj.UnitName}
                                    </option>
                                ))}
                            </Input>
                            <MatButton
                                className="text-primary mr-10 mb-10"
                                onClick={() => {
                                    const location = {
                                        pathname: '/applicants/convertToTenant',
                                        state: {
                                            tenantID: parseInt(value.TenantID),
                                            tenantName: `${value.TenantFName} ${value.TenantLName}`,
                                            unitID: selectedUnit,
                                            unitName: unitName,
                                        },
                                    };
                                    history.push(location);
                                }}
                            >
                                Convert
                            </MatButton>
                        </>
                    );
                },
                download: false,
            },
        },
    ];

    const options = {
        filterType: 'dropdown',
        pagination: false,
        selectableRows: 'none',
        customSearch: (searchQuery, currentRow, columns) => {
            let found = false;
            currentRow.forEach((element) => {
                if (element === null) found = false;
                else if (typeof element === 'object') {
                    if (
                        element.TenantFName !== null &&
                        element.TenantFName.toString().toUpperCase().includes(searchQuery.toUpperCase())
                    )
                        found = true;
                    if (
                        element.TenantLName !== null &&
                        element.TenantLName.toString().toUpperCase().includes(searchQuery.toUpperCase())
                    )
                        found = true;
                    if (
                        element.UserFName !== null &&
                        element.UserFName.toString().toUpperCase().includes(searchQuery.toUpperCase())
                    )
                        found = true;
                    if (
                        element.UserLName !== null &&
                        element.UserLName.toString().toUpperCase().includes(searchQuery.toUpperCase())
                    )
                        found = true;
                } else if (element.toString().toUpperCase().includes(searchQuery.toUpperCase())) {
                    found = true;
                }
            });
            return found;
        },
        downloadOptions: {
            filename: `Applicant List - ${filteredName.PropertyName} ${moment().format('MM/DD/YYYY')}`,
        },
    };

    const deleteApplicantFunc = async () => {
        setLoading(true);
        setDeleteApplicant(0);
        const res = await applicantsAPI.deniedProspect(deleteApplicant);
        setLoading(false);
        if (res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, 'Error');
            return;
        }
        setUpdated(!updated);
        NotificationManager.success('Applicant deleted.', 'Success');
    };

    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Applicants...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <SweetAlert
                warning
                btnSize="sm"
                show={deleteApplicant !== 0}
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => deleteApplicantFunc()}
                onCancel={() => setDeleteApplicant(0)}
            >
                Applicant will be set as denied.
            </SweetAlert>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <span>Applicants</span>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={`Current Applicants - ${applicants.length}`}
                            data={applicants}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
            <Review
                propertyID={propertyID}
                reviewTenantID={reviewTenantID}
                setReviewTenantID={setReviewTenantID}
                reviewData={reviewData}
                setUpdated={setUpdated}
                updated={updated}
            />

             <Modal
                isOpen={showMoveoutModal}
                onRequestClose={() => setShowMoveoutModal(false)}
                contentLabel="Move Applicant"
                style={modalStyles}
            >
                <Form>
                    <h2>Move out</h2>
                    <div style={{ marginTop: '2rem' }}>
                        <FormGroup className="mr-10 mb-10">
                            <Label for="task" className="mr-sm-10">
                                Select Property
                            </Label>
                            <Select
                                name="property"
                                onChange={(e)=>{
                                    setSelectedProperty(e.target.value)
                                }}
                            >
                                {
                                    properties.filter((property) => property.PropertyID !== propertyID).map(p=>{
                                        return (
                                            <MenuItem
                                                key={parseInt(p.PropertyID)}
                                                value={parseInt(p.PropertyID)}
                                            >
                                                {p.PropertyName}
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormGroup>
                    </div>
                    <div className="text-right mt-4">
                        <Button type="button" onClick={() => setShowMoveoutModal(false)} color="secondary" size="sm" className="w-auto">
                            Cancel
                        </Button>
                        <Button onClick={async ()=>{
                            await applicantsAPI.moveApplicant({ appId: selectedTenant.TenantID, propertyID : selectedProperty });
                            setShowMoveoutModal(false);
                            setUpdated(true);
                        }} color="primary" size="sm" className="w-auto ml-2">
                            Submit
                        </Button>
                    </div>
                </Form>
            </Modal>
        </Main>
    );
};

export default ViewAll;

import React, { useState, useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { Typography } from '@material-ui/core';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';

const XWorkOrderSummary = (props) => {
    const [loading, setLoading] = useState(false);
    const [summaryData, setSummaryData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const reportDetails = await reportsAPI.getReportDetails(props.reportSearch);
            console.log('reportDetails', reportDetails);
            const properties = reportDetails.getProps1?.concat(
                reportDetails.ukProps,
                reportDetails.canadaProps,
                reportDetails.propX,
            );
            properties.map((property) => {
                const openLength = reportDetails.GetOpen.filter((open) => open.PropertyID === property.PropertyID).length;
                const inProgressLength = reportDetails.GetInPro.filter(
                    (open) => open.PropertyID === property.PropertyID,
                ).length;
                const closedLength = reportDetails.GetClosed.filter(
                    (open) => open.PropertyID === property.PropertyID,
                ).length;
                const totalLength = reportDetails.GetWO.filter((open) => open.PropertyID === property.PropertyID).length;
                return summaryData.push({
                    property: property.PropertyName,
                    callOpen: openLength,
                    inProgress: inProgressLength,
                    closed: closedLength,
                    total: totalLength,
                });
            });
            summaryData.push({
                property: 'TOTAL',
                callOpen: reportDetails.GetOpen.length,
                inProgress: reportDetails.GetInPro.length,
                closed: reportDetails.GetClosed.length,
                total: reportDetails.GetWO.length,
            });
            setLoading(false);
        }
        fetchData();
    }, []);
    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Reports...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }
    const summaryColumns = [
        { name: 'property', label: 'Property' },
        { name: 'callOpen', label: 'Call/Open' },
        { name: 'inProgress', label: 'In Progress' },
        { name: 'closed', label: 'Closed' },
        { name: 'total', label: 'Total' },
    ];
    return (
        <div>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <span>
                            {props.reportName +
                                ' (Cross Property) - Date Range of Report: ' +
                                props.startDate +
                                ' to ' +
                                props.endDate}
                        </span>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Summary'}
                            data={summaryData}
                            columns={summaryColumns}
                            options={props.reportOptions}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
        </div>
    );
};

export default XWorkOrderSummary;


import React, {useState, useEffect} from 'react';
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Label, Input } from 'reactstrap';
import { FormGroup } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from 'moment';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Util from '../Util/util';
import * as Constants from '../Util/constants';
import * as companyAPI from '../../Api/company';

const EditRequestHistory = (props) => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const user = login.user;
    const request = props.location.request ? props.location.request : null;

    const [loading, setLoading] = useState(false);

    const { handleSubmit, control, setValue, formState: { errors }} = useForm();

    useEffect(() => {
        async function fetchData() {
            if(request === null) {
                history.goBack();
                return;
            }
            setLoading(true);
            if(request !== null) {
                setValue('requestedBy', `${request.UserFName} ${request.UserLName}`);
                setValue('submittedOn', moment.utc(request.EnhancementRequestSubmissionDate).format("MM/DD/YYYY"));
                setValue('request', request.EnhancementRequest);
                setValue('status', request.EnhancementRequestComplete);
                setValue('description', request.EnhancementRequestComment);
            }
            setLoading(false);
        }
        fetchData()
    }, [history, setValue, request]);

    const submitForm = async (data) => {
        setLoading(true);
        let status = '';
        switch (parseInt(data.status)) {
            case 1:
                status = 'Open';
                break;
            case 2:
                status = 'In Progress';
                break;
            case 3:
                status = 'Closed';
                break;
            default:
                break;
        }
        const res = await companyAPI.updateEnhancement({
            EnhancementRequestComment: data.description,
            StatusID: parseInt(data.status),
            Status: status,
            EnhancementRequestID: parseInt(request.EnhancementRequestID),
            EnhancementRequest: data.request,
            userEmail: user.email
        });
        setLoading(false);
        if(res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        NotificationManager.success("Enhancement Request Updated!", "Success");
    }

    if(loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Loading Edit Enhancement Request..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <i className="ti-angle-left" style={{cursor: 'pointer'}} onClick={() => history.goBack()}></i>
                        <h2>
                            <span>Edit Enhancement Request</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="Company Details">
                            <Form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="requestedBy" className="mr-sm-10">Requested By</Label>
                                            <Controller
                                                name="requestedBy"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="requestedBy" disabled={true} />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="submittedOn" className="mr-sm-10">Submitted On</Label>
                                            <Controller
                                                name="submittedOn"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="submittedOn" disabled={true} />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-6">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="request" className="mr-sm-10">Request</Label>
                                            <Controller
                                                name="request"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="request" disabled={true} />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Label for="status" className="mr-sm-10">Status</Label>
                                        <Controller
                                            name="status"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="status" style={Util.setErrorStyle(errors.status)}>
                                                    <option value="1">Open</option>
                                                    <option value="2">In Progress</option>
                                                    <option value="3">Closed</option>
                                                </Input>
                                            )}
                                        />
                                        {errors.status && (
                                            <span style={{ color: "red" }} role="alert">required</span>
                                        )}
                                    </div>
                                    <div className="col-sm-9">
                                        <Label for="description" className="mr-sm-10">Description</Label>
                                        <Controller
                                            name="description"
                                            control={control}
                                            rules={{required: true}}
                                            render={({ field }) => (
                                                <Input {...field} type="textarea" id="description" rows={4} style={Util.setErrorStyle(errors.description)} />
                                            )}
                                        />
                                        {errors.description && (
                                            <span style={{ color: "red" }} role="alert">required</span>
                                        )}
                                    </div>
                                </div>
                                <Button type="submit" color="primary" style={{marginTop: '10px'}}>Update</Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default EditRequestHistory;

import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Constants from '../Util/constants';

const MarketingText = () => {
    const login = useSelector((state) => state.login);
    const propertyID = login.selectedPropertyID;

    const [loading, setLoading] = useState(false);
    const [initUrl, setInitURl] = useState("");


    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            let url = `https://${window.location.hostname}`;
            if(window.location.hostname !== 'www.rhawa.myirent.com' && window.location.hostname !== 'rhawa.myirent.com') {
                url += '/rent';
            }
            setInitURl(url);
            setLoading(false);
        }
        fetchData();
    }, [])

    if(loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Loading Marketing Text..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Marketing Text</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="Marketing Links">
                            <div>
                                <ul>
                                    <li>
                                        <b>Your tenant portal link:</b>{' '} 
                                        <a href={`${initUrl}/tenantPortal`} target="_blank" rel="noreferrer">{initUrl}/tenantPortal</a>
                                    </li>
                                    <li>
                                        <b>Your apply online link:</b>{' '} 
                                        <a href={`${initUrl}/tenantPortal.cfm?p=${propertyID}`} target="_blank" rel="noreferrer">{initUrl}/tenantPortal.cfm?p={propertyID}</a>
                                    </li>
                                    <li>
                                        <b>Your waiting list link:</b>{' '} 
                                        <a href={`${initUrl}/waitingList.cfm?pID=${propertyID}`} target="_blank" rel="noreferrer">{initUrl}/waitingList.cfm?pID={propertyID}</a>
                                    </li>
                                    <li>
                                        <b>Your tenant directory link:</b>{' '} 
                                        <a href={`${initUrl}/TenantDirectory.cfm?pID=${propertyID}`} target="_blank" rel="noreferrer">{initUrl}/TenantDirectory.cfm?pID={propertyID}</a>
                                    </li>
                                    <li>
                                        <b>Your property vacancy listing link:</b>{' '} 
                                        <a href={`${initUrl}/showVacancies.cfm?pID=${propertyID}`} target="_blank" rel="noreferrer">{initUrl}/showVacancies.cfm?pID={propertyID}</a>
                                    </li>
                                </ul>
                            </div>
                        </RctCollapsibleCard>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default MarketingText;
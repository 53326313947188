import axios from 'axios';
import * as Constants from '../components/Util/constants';

const URL = Constants.REQ_URL;

export const get = async (companyID) => {
    let output = {};
    await axios.get(`${URL}/company/get/${companyID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getAll = async (activeOnly = false) => {
    let output = {};
    await axios.get(`${URL}/company/get?activeOnly=${activeOnly}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getDetails = async (companyID) => {
    let output = {};
    await axios.get(`${URL}/companyProfile/getDetails/${companyID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getCurrencies = async () => {
    let output = [];
    await axios.get(`${URL}/companyProfile/getCurrencies`).then((res) => {
        output = res.data;
    });
    return output;
};

export const updateSettings = async (data) => {
    let output = -1;
    await axios.post(`${URL}/companyProfile/updateSettings`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const updateDetails = async (data) => {
    let output = -1;
    await axios.post(`${URL}/companyProfile/updateDetails`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const updateStatus = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/updateStatus`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const updateCompanyCard = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/updCC`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const updateCompanyBank = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/updBank`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const verifyBank = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/verifyBank`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const updPaymentMethod = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/updPaymentMethod`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const getExpenseTypes = async (companyID) => {
    let output = [];
    await axios.get(`${URL}/companyProfile/getExpenseTypes/${companyID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getAccountTypes = async (companyID) => {
    let output = [];
    await axios.get(`${URL}/companyProfile/getAccountTypes/${companyID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const addExpenseType = async (data) => {
    let output = -1;
    await axios.post(`${URL}/companyProfile/addExpenseType`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const deleteExpenseType = async (expenseTypeID) => {
    let output = -1;
    await axios.get(`${URL}/companyProfile/deleteExpenseType/${expenseTypeID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const mergeExpenseTypes = async (data) => {
    let output = -1;
    await axios.post(`${URL}/companyProfile/mergeExpenseTypes`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const getLeadSource = async (companyID) => {
    let output = {};
    await axios.get(`${URL}/company/${companyID}/LeadSource`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getJournalEntries = async (propertyID) => {
    let output = [];
    await axios.get(`${URL}/company/getJournalEntries/${propertyID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getJournalType = async () => {
    let output = [];
    await axios.get(`${URL}/company/getJournalType`).then((res) => {
        output = res.data;
    });
    return output;
};

export const addJournal = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/addJournal`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const deleteJournal = async (journalID) => {
    let output = -1;
    await axios.get(`${URL}/company/deleteJournal/${journalID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getJournalByID = async (journalID) => {
    let output = -1;
    await axios.get(`${URL}/company/getJournalByID/${journalID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const editJournal = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/editJournal`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const getMakeReadyTasks = async (propertyID) => {
    let output = [];
    await axios.get(`${URL}/company/getMakeReadyTasks/${propertyID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const deleteMakeReady = async (makeReadyTaskID) => {
    let output = -1;
    await axios.get(`${URL}/company/deleteMakeReady/${makeReadyTaskID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const addMakeReadyTask = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/addMakeReadyTask`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const getMakeReadyTaskByID = async (makeReadyTaskID) => {
    let output = null;
    await axios.get(`${URL}/company/getMakeReadyTaskByID/${makeReadyTaskID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const updMakeReadyTask = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/updMakeReadyTask`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const getGLCategories = async (propertyID) => {
    let output = [];
    await axios.get(`${URL}/company/getGLCategories/${propertyID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const deleteGLCategories = async (paymentCategoryID) => {
    let output = -1;
    await axios.get(`${URL}/company/deleteGLCategories/${paymentCategoryID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const addGLCategory = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/addGLCategory`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const getCorporateDocs = async (data) => {
    let output = [];
    await axios.post(`${URL}/company/getCorporateFiles`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const getCorporateFileTypes = async () => {
    let output = [];
    await axios.get(`${URL}/company/getCorporateFileTypes`).then((res) => {
        output = res.data;
    });
    return output;
};

export const addCorporateDoc = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/addCorporateDoc`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const uploadCorporateFile = async (data) => {
    let output = 0;

    var formData = new FormData();
    formData.append('propertyID', data.propertyID);
    formData.append('corporateFileId', data.corporateFileId);
    formData.append('file', data.file);

    await axios({
        method: 'post',
        url: `${URL}/company/uploadCorporateFile`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then(function (res) {
        output = res.data;
    });

    return output;
};

export const deleteCorporateFile = async (id) => {
    let output = -1;
    await axios.get(`${URL}/company/deleteCorporateDoc/${id}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getBudgetExpenses = async (propertyID) => {
    let output = [];
    await axios.get(`${URL}/company/getBudgetExpenses/${propertyID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getBudgetIncomes = async (propertyID) => {
    let output = [];
    await axios.get(`${URL}/company/getBudgetIncomes/${propertyID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getListExpenseTypes = async (companyID) => {
    let output = [];
    await axios.get(`${URL}/company/getListExpenseTypes/${companyID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getListDepositSources = async () => {
    let output = [];
    await axios.get(`${URL}/company/getListDepositSources`).then((res) => {
        output = res.data;
    });
    return output;
};

export const addBudget = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/addBudget`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const deleteBudget = async (id) => {
    let output = -1;
    await axios.get(`${URL}/company/deleteBudget/${id}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const addBudgetIncome = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/addBudgetIncome`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const deleteBudgetIncome = async (id) => {
    let output = -1;
    await axios.get(`${URL}/company/deleteBudgetIncome/${id}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getBudgetExpense = async (id) => {
    let output = [];
    await axios.get(`${URL}/company/getBudgetExpense/${id}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getBudgetIncome = async (id) => {
    let output = [];
    await axios.get(`${URL}/company/getBudgetIncome/${id}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const updateBudget = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/updateBudget`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const updateBudgetIncome = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/updateBudgetIncome`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const sendLeaseAgreements = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/sendLeaseAgreements`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const getLicenseAgreement = async () => {
    let output = [];
    await axios.get(`${URL}/company/getLicenseAgreement`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getCompanyPartnerDefaults = async (cID) => {
    let output = [];
    await axios.get(`${URL}/company/getCompanyPartnerDefaults/${cID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const sendSupportRequest = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/sendSupportRequest`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const getFaq = async () => {
    let output = [];
    await axios.get(`${URL}/company/getFaq`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getEnhancementRequests = async (companyID) => {
    let output = [];
    await axios.get(`${URL}/company/getEnhancementRequests/${companyID}`).then((res) => {
        output = res.data;
    });
    return output;
};

export const updateEnhancement = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/updateEnhancement`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const uploadUnitFile = async (data) => {
    let output = 0;

    var formData = new FormData();
    formData.append('propertyID', data.propertyID);
    formData.append('userID', data.userID);
    formData.append('file', data.file);
    formData.append('unitTypeID', data.unitTypeID);
    formData.append('marketingFileTypeID', data.marketingFileTypeID);
    formData.append('fileName', data.fileName);

    await axios({
        method: 'post',
        url: `${URL}/company/uploadUnitFile`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then(function (res) {
        output = res.data;
    });

    return output;
};

export const getPriorities = async () => {
    let output = [];
    await axios.get(`${URL}/company/getPriorities`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getOpenEnhancementRequests = async (data) => {
    let output = [];
    await axios.post(`${URL}/company/getOpenEnhancementRequests`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const addEnhancementRequest = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/addEnhancementRequest`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const processPayment = async (data) => {
    let output = -1;
    await axios.post(`${URL}/company/processPayment`, data).then((res) => {
        output = res.data;
    });
    return output;
};


import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import { AgChartsReact } from "ag-charts-react";

const TrafficSource = (props) => {
  const [loading, setLoading] = useState(false);
  const [summData, setSummData] = useState([]);
  const [summTableData, setSummTableData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  const [options, setOptions] = useState({
    data: [],
    series: [
      {
        type: "pie",
        angleKey: "value",
        calloutLabelKey: "label",
        sectorLabelKey: "value",
        sectorLabel: {
          color: "white",
          fontWeight: "bold",
        },
      },
    ],
  });
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getTrafficDetails(
        props.reportSearch
      );
      const reportSummary = await reportsAPI.getTrafficSummary(
        props.reportSearch
      );
      for (let i = 0; i < reportDetails.length; i++) {
        detailsData.push({
          leadSource: reportDetails[i].leadsource.LeadSource,
          property: reportDetails[i].property.PropertyName,
          date: formatDate(reportDetails[i].ProspectStartDate.substring(0, 10)),
        });
      }
      for (let i = 0; i < reportSummary.length; i++) {
        if (reportSummary[i].TotalCount > 0) {
          summData.push({
            label: reportSummary[i].leadsource.LeadSource,
            value: reportSummary[i].TotalCount,
          });
        }
      }
      options.data = summData;
      setSummTableData([
        ...summData,
        { label: "TOTAL", value: reportDetails.length },
      ]);
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const summColumns = [
    { name: "label", label: "Lead Source" },
    { name: "value", label: "Total Count" },
  ];
  const detailsColumns = [
    { name: "leadSource", label: "Lead Source" },
    { name: "property", label: "Property" },
    { name: "date", label: "Date" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>{props.reportName + " - " + filteredName.PropertyName}</span>
          </h2>
        </div>
      </div>
      <AgChartsReact options={options} />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Summary"}
              data={summTableData}
              columns={summColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Details"}
              data={detailsData}
              columns={detailsColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default TrafficSource;

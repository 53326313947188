import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const UnitListMonth = (props) => {
  const [loading, setLoading] = useState(false);
  const [listUnits, setListUnits] = useState([]);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      for (let i = 0; i < props.selectedValue.length; i++) {
        props.reportSearch.pID = props.selectedValue[i].id;
        const allUnits = await reportsAPI.getUnitListing(props.reportSearch);
        if (!listUnits[props.selectedValue[i].name]) {
          for (let j = 0; j < allUnits.length; j++) {
            if (listUnits[props.selectedValue[i].name]) {
              listUnits[props.selectedValue[i].name].push({
                unit: allUnits[j].UnitName,
                unitDescription: allUnits[j].unittype.UnitTypeDescription,
                tenant:
                  allUnits[j].tenants.length === 0 ||
                  allUnits[j].tenants[allUnits[j].tenants.length - 1]
                    ?.Prospect === 3
                    ? "VACANT"
                    : allUnits[j].tenants[allUnits[j].tenants.length - 1]
                        .TenantFName +
                      " " +
                      allUnits[j].tenants[allUnits[j].tenants.length - 1]
                        .TenantLName,
                tenantEmail:
                  allUnits[j].tenants.length === 0 ||
                  allUnits[j].tenants[allUnits[j].tenants.length - 1]
                    ?.Prospect === 3
                    ? ""
                    : allUnits[j].tenants[allUnits[j].tenants.length - 1]
                        .TenantEmail,
                tenantPhone:
                  allUnits[j].tenants.length === 0 ||
                  allUnits[j].tenants[allUnits[j].tenants.length - 1]
                    ?.Prospect === 3
                    ? ""
                    : allUnits[j].tenants[allUnits[j].tenants.length - 1]
                        .TenantPhone,
                leaseStartDate:
                  allUnits[j].tenants.length === 0 ||
                  allUnits[j].tenants[allUnits[j].tenants.length - 1]
                    ?.Prospect === 3
                    ? ""
                    : allUnits[j].tenants[allUnits[j].tenants.length - 1]
                        .LeaseStartDate,
                leaseEndDate:
                  allUnits[j].tenants.length === 0 ||
                  allUnits[j].tenants[allUnits[j].tenants.length - 1]
                    ?.Prospect === 3
                    ? ""
                    : allUnits[j].tenants[allUnits[j].tenants.length - 1]
                        .LeaseEndDate,
              });
            } else {
              listUnits[props.selectedValue[i].name] = [
                {
                  unit: allUnits[j].UnitName,
                  unitDescription: allUnits[j].unittype.UnitTypeDescription,
                  tenant:
                    allUnits[j].tenants.length === 0 ||
                    allUnits[j].tenants[allUnits[j].tenants.length - 1]
                      .Prospect === 3
                      ? "VACANT"
                      : allUnits[j].tenants[allUnits[j].tenants.length - 1]
                          .TenantFName +
                        " " +
                        allUnits[j].tenants[allUnits[j].tenants.length - 1]
                          .TenantLName,
                  tenantEmail:
                    allUnits[j].tenants.length === 0 ||
                    allUnits[j].tenants[allUnits[j].tenants.length - 1]
                      .Prospect === 3
                      ? ""
                      : allUnits[j].tenants[allUnits[j].tenants.length - 1]
                          .TenantEmail,
                  tenantPhone:
                    allUnits[j].tenants.length === 0 ||
                    allUnits[j].tenants[allUnits[j].tenants.length - 1]
                      .Prospect === 3
                      ? ""
                      : allUnits[j].tenants[allUnits[j].tenants.length - 1]
                          .TenantPhone,
                  leaseStartDate:
                    allUnits[j].tenants.length === 0 ||
                    allUnits[j].tenants[allUnits[j].tenants.length - 1]
                      .Prospect === 3
                      ? ""
                      : allUnits[j].tenants[allUnits[j].tenants.length - 1]
                          .LeaseStartDate,
                  leaseEndDate:
                    allUnits[j].tenants.length === 0 ||
                    allUnits[j].tenants[allUnits[j].tenants.length - 1]
                      .Prospect === 3
                      ? ""
                      : allUnits[j].tenants[allUnits[j].tenants.length - 1]
                          .LeaseEndDate,
                },
              ];
            }
          }
        }
      }
      setLoading(false);
    }
    fetchData();
  }, [props.selectedValue, props.reportSearch, listUnits]);
  const vacancyColumns = [
    { name: "unit", label: "Unit" },
    { name: "unitDescription", label: "Unit Description" },
    { name: "tenant", label: "Tenant" },
    { name: "tenantEmail", label: "Tenant Email" },
    { name: "tenantPhone", label: "Tenant Phone" },
    { name: "leaseStartDate", label: "Lease Start Date" },
    { name: "leaseEndDate", label: "Lease End Date" },
  ];
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>{props.reportName}</span>
          </h2>
        </div>
      </div>
      <div className="row">
        {props.selectedValue.map((property) => {
          return (
            <div className="col-sm-12 col-md-12 col-xl-12">
              <MuiThemeProvider theme={Constants.getMuiTheme()}>
                <MUIDataTable
                  title={`Unit Listing - ${property.name}`}
                  data={listUnits[property.name]}
                  columns={vacancyColumns}
                  options={props.reportOptions}
                />
              </MuiThemeProvider>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UnitListMonth;

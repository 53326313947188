import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormGroup, Dialog, Slide, Switch, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { Badge } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Button, Form, Label, Input } from 'reactstrap';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import DatePicker from 'reactstrap-date-picker';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';

import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as tenantsAPI from '../../Api/tenants';
import * as constants from '../Util/constants';
import * as Util from '../Util/util';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MoveOutTenant = (props) => {
    const property = props.property;
    const tenant = props.tenant;
    const tID = tenant.TenantID;
    const pID = tenant.PropertyID;
    const uID = tenant.UnitID;
    const companyID = props.companyID;
    const userID = props.userID;
    console.log("property",property)
    const [balance, setBalance] = useState(0);
    const [refundDeposit, setRefundDeposit] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingMSG, setLoadingMSG] = useState('Loading...');
    const [formData, setFormData] = useState({
        moveOutDate: moment(tenant.MoveOutDate).format('YYYY-MM-DD'),
        forwardingAddress: '',
        selectedMoveOutReason: 0,
        city: property.PropertyCity,
        state: property.PropertyState,
        zip: property.PropertyZip,
        email: tenant.TenantEmail,
        phone: tenant.TenantPhone,
        rentAgain: true,
        whiteList: false,
        sendSecDeposit: false,
        refundAmount: 0,
        comments: '',
    });
    const [moveOutReason, setMoveOutReason] = useState([]);

    const [showMoveoutInput, setShowMoveoutInput] = useState(false);
    const [reason, setReason] = useState('');

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            if (tID !== undefined) {
                setBalance(parseFloat(await tenantsAPI.getTenantBalance(tID)).toFixed(2));
                setRefundDeposit(parseFloat(await tenantsAPI.getRefundableDeposit(tID)).toFixed(2));
                setMoveOutReason(await tenantsAPI.getMoveOutReasons(companyID));
                setFormData({
                    ...formData,
                    city: property.PropertyCity,
                    state: property.PropertyState,
                    zip: property.PropertyZip,
                    email: tenant.TenantEmail,
                    phone: tenant.TenantPhone,
                })
            }
            
            setLoading(false);
        }
        fetchData();
    }, [tID, companyID]);

    const handleClose = () => props.setOpenMoveOut(false);

    const addMoveoutReason = async () => {
        const reasons = await tenantsAPI.addMoveOutReasons(companyID, reason);
        setMoveOutReason(reasons);
        const r = reasons.find((a) => a.MoveOutReason === reason);
        setFormData({ ...formData, selectedMoveOutReason: r.MoveOutReasonID });
    };

    const submitForm = async (data) => {
        const moveOutDt = moment.utc(formData.moveOutDate);
        if (!moveOutDt.isValid()) {
            NotificationManager.error('Please, enter a valid move out date.', 'Error');
            return;
        }
        if (formData.selectedMoveOutReason === 0) {
            NotificationManager.error('Move Out Reason is requried.', 'Error');
            return;
        }
        if (formData.forwardingAddress === '') {
            NotificationManager.error('Forwarding Address is requried.', 'Error');
            return;
        }
        if (formData.city === '') {
            NotificationManager.error('City is requried.', 'Error');
            return;
        }
        if (formData.zip === '') {
            NotificationManager.error('Zip is requried.', 'Error');
            return;
        }
        if (formData.email === '') {
            NotificationManager.error('Email is requried.', 'Error');
            return;
        }
        if (parseInt(formData.state) === 0) {
            NotificationManager.error('State is requried.', 'Error');
            return;
        }
        setLoadingMSG('Saving...');
        setLoading(true);
        const res = await tenantsAPI.moveOut({
            moveOutDate: moveOutDt,
            whiteList: formData.whiteList ? 1 : 0,
            tenantID: tID,
            unitID: uID,
            moveOutReasonID: formData.selectedMoveOutReason,
            propertyID: pID,
            userID,
            tenantEmail: formData.email,
            tenantPhone: formData.phone ? formData.formData : '',
            address: formData.forwardingAddress,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            collections: 0,
            rentAgain: formData.rentAgain ? 1 : 2,
            comment: data.comments,
            secDepositRefund: formData.sendSecDeposit,
            refundAmount: formData.refundAmount === '' ? 0 : parseFloat(formData.refundAmount),
            tenantFName: tenant.TenantFName,
            tenantLName: tenant.TenantLName,
        });
        if (res !== 0) {
            setLoading(false);
            setLoadingMSG('Loading...');
            NotificationManager.error('Error processing your request. Please, contact us', 'Error');
            return;
        }
        setFormData({
            moveOutDate: moment(tenant.MoveOutDate).format('YYYY-MM-DD'),
            forwardingAddress: '',
            selectedMoveOutReason: 0,
            city: '',
            state: 0,
            zip: '',
            email: '',
            phone: '',
            rentAgain: true,
            whiteList: false,
            sendSecDeposit: false,
            refundAmount: 0,
            comments: '',
        });
        await props.getData();
        props.setOpenMoveOut(false);
        setLoadingMSG('Loading...');
        setLoading(false);
    };

    const renderContent = () => {
        return (
            <p>
                Balance Owed:{' '}
                <Badge className="mb-10 mr-10" color={balance > 0 ? 'danger' : 'success'}>
                    <NumberFormat value={balance} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </Badge>{' '}
                Refundable Security Deposit:{' '}
                <Badge className="mb-10 mr-10" color={refundDeposit > 0 ? 'danger' : 'dark'}>
                    <NumberFormat value={refundDeposit} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </Badge>
            </p>
        );
    };

    const render = () => {
        if (loading) {
            return (
                <div style={{ marginTop: '80px' }}>
                    <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={loadingMSG}>
                        <LinearProgress />
                    </RctCollapsibleCard>
                </div>
            );
        } else {
            const balanceWarning = () => {
                if (balance > 0) {
                    return (
                        <div className="col-sm-5">
                            <FormGroup check className="mb-20">
                                <div className="alert alert-danger fade show" role="alert" style={{ top: '20px' }}>
                                    Do not request refund if Tenant still owes balance.
                                </div>
                            </FormGroup>
                        </div>
                    );
                }
            };
            const moveOutReasonLabel = () => {
                return (
                    <>
                        Move Out Reason:{' '}
                        <IconButton
                            aria-label="Print"
                            onClick={() => {
                                setShowMoveoutInput(!showMoveoutInput);
                            }}
                            style={{ color: 'blue', maxHeight: '10px' }}
                        >
                            {showMoveoutInput ? <CloseOutlined /> : <AddCircleOutline />}
                        </IconButton>
                        {showMoveoutInput && (
                            <div style={{ display: 'inline-block' }}>
                                <Input
                                    style={{ display: 'inline-block', width: '170px', padding: '2px 15px' }}
                                    onChange={(e) => setReason(e.target.value)}
                                />
                                <Button size={'small'} outline style={{ marginLeft: '3px' }} onClick={addMoveoutReason}>
                                    Add
                                </Button>
                            </div>
                        )}
                    </>
                );
            };
            return (
                <div style={{ marginLeft: '10px' }}>
                    <div className="row" style={{ marginTop: '100px' }}>
                        <div className="col-sm-12 col-md-12 col-xl-12">{renderContent()}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <div className="row">
                                <div className="col-sm-2">
                                    <FormGroup className="mr-10 mb-10">
                                        <Label for="moveOutDate" className="mr-sm-10">
                                            Move Out Date
                                        </Label>
                                        <Controller
                                            name="moveOutDate"
                                            control={control}
                                            defaultValue={formData.moveOutDate}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <DatePicker
                                                    {...field}
                                                    id="moveOutDate"
                                                    innerRef={ref}
                                                    value={formData.moveOutDate}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            moveOutDate: e ? moment(e).format('YYYY-MM-DD') : '',
                                                        })
                                                    }
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-4">
                                    <FormGroup className="mr-10 mb-10">
                                        <Label for="moveOutReason" className="mr-sm-10">
                                            {moveOutReasonLabel()}
                                        </Label>
                                        <Controller
                                            name="moveOutReason"
                                            control={control}
                                            defaultValue={formData.selectedMoveOutReason}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <Input
                                                    {...field}
                                                    type="select"
                                                    id="moveOutReason"
                                                    innerRef={ref}
                                                    value={formData.selectedMoveOutReason}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, selectedMoveOutReason: e.target.value })
                                                    }
                                                >
                                                    <option value="0">Select</option>
                                                    {moveOutReason.map((obj) => {
                                                        return (
                                                            <option key={obj.MoveOutReasonID} value={obj.MoveOutReasonID}>
                                                                {obj.MoveOutReason}
                                                            </option>
                                                        );
                                                    })}
                                                </Input>
                                            )}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-6">
                                    <FormGroup className="mr-10 mb-10">
                                        <Label for="forwardingAddress" className="mr-sm-10">
                                            Forwarding Address:
                                        </Label>
                                        <Controller
                                            name="forwardingAddress"
                                            control={control}
                                            defaultValue={formData.forwardingAddress}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <Input
                                                    {...field}
                                                    type="text"
                                                    id="forwardingAddress"
                                                    innerRef={ref}
                                                    value={formData.forwardingAddress}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, forwardingAddress: e.target.value })
                                                    }
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormGroup className="mr-10 mb-10">
                                        <Label for="city" className="mr-sm-10">
                                            City:
                                        </Label>
                                        <Controller
                                            name="city"
                                            control={control}
                                            defaultValue={formData.city}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <Input
                                                    {...field}
                                                    type="text"
                                                    id="city"
                                                    innerRef={ref}
                                                    value={formData.city}
                                                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-4">
                                    <FormGroup className="mr-10 mb-10">
                                        <Label for="state" className="mr-sm-10">
                                            State:
                                        </Label>
                                        <Controller
                                            name="state"
                                            control={control}
                                            defaultValue={formData.state}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <Input
                                                    {...field}
                                                    type="select"
                                                    id="state"
                                                    innerRef={ref}
                                                    value={formData.state}
                                                    onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                                                >
                                                    <option value="0">Select</option>
                                                    {constants.usStates.map((obj) => {
                                                        return (
                                                            <option key={obj.abbreviation} value={obj.abbreviation}>
                                                                {obj.name}
                                                            </option>
                                                        );
                                                    })}
                                                </Input>
                                            )}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-3">
                                    <FormGroup className="mr-10 mb-10">
                                        <Label for="zip" className="mr-sm-10">
                                            Zip:
                                        </Label>
                                        <Controller
                                            name="zip"
                                            control={control}
                                            defaultValue={formData.zip}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <Input
                                                    {...field}
                                                    type="text"
                                                    id="zip"
                                                    innerRef={ref}
                                                    value={formData.zip}
                                                    onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormGroup className="mr-10 mb-10">
                                        <Label for="email" className="mr-sm-10">
                                            Email:
                                        </Label>
                                        <Controller
                                            name="email"
                                            control={control}
                                            defaultValue={formData.email}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <Input
                                                    {...field}
                                                    type="email"
                                                    id="email"
                                                    innerRef={ref}
                                                    value={formData.email}
                                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-2">
                                    <FormGroup className="mr-10 mb-10">
                                        <Label for="phone" className="mr-sm-10">
                                            Phone:
                                        </Label>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            defaultValue={formData.phone}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <Input
                                                    {...field}
                                                    type="text"
                                                    id="phone"
                                                    innerRef={ref}
                                                    value={formData.phone}
                                                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-2">
                                    <FormGroup check className="mb-20">
                                        <Label for="rentAgain" className="mr-sm-10">
                                            Rent this person again?
                                        </Label>
                                        <Switch
                                            name="rentAgain"
                                            checked={formData.rentAgain}
                                            onChange={() => setFormData({ ...formData, rentAgain: !formData.rentAgain })}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-2">
                                    <FormGroup check className="mb-20">
                                        <Label for="whiteList" className="mr-sm-10">
                                            Send to Whitelist?
                                        </Label>
                                        <Switch
                                            name="whiteList"
                                            checked={formData.whiteList}
                                            onChange={() => setFormData({ ...formData, whiteList: !formData.whiteList })}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-2">
                                    <FormGroup check className="mb-20">
                                        <Label for="sendSecDeposit" className="mr-sm-10">
                                            Refund Security Deposit?
                                        </Label>
                                        <Switch
                                            name="sendSecDeposit"
                                            checked={formData.sendSecDeposit}
                                            onChange={() =>
                                                setFormData({ ...formData, sendSecDeposit: !formData.sendSecDeposit })
                                            }
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">
                                    <FormGroup check className="mb-20">
                                        <Label for="refundAmount" className="mr-sm-10">
                                            Refund Amount
                                        </Label>
                                        <Controller
                                            name="refundAmount"
                                            control={control}
                                            defaultValue={formData.refundAmount}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <NumberFormat
                                                    {...field}
                                                    id="refundAmount"
                                                    innerRef={ref}
                                                    value={formData.refundAmount}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    onValueChange={(v) => {
                                                        setFormData({
                                                            ...formData,
                                                            refundAmount: v.floatValue === undefined ? 0 : v.floatValue,
                                                        });
                                                    }}
                                                    className="form-control"
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                </div>
                                {balanceWarning()}
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <FormGroup check className="mb-20">
                                        <Label for="comments" className="mr-sm-10">
                                            Comments
                                        </Label>
                                        <Controller
                                            name="comments"
                                            control={control}
                                            defaultValue={formData.comments}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <Input
                                                    {...field}
                                                    rows={5}
                                                    type="textarea"
                                                    id="comments"
                                                    innerRef={ref}
                                                    value={formData.comments}
                                                    onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <div>
            <Dialog open={props.open} onClose={handleClose} fullScreen TransitionComponent={Transition}>
                <Form onSubmit={handleSubmit(submitForm)}>
                    <AppBar className="bg-primary">
                        <Toolbar>
                            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <h5 className="w-100 mb-0">
                                Move Out Tenant - {tenant.TenantFName} {tenant.TenantLName}
                            </h5>
                            <Button type="submit" color="inherit">
                                Confirm
                            </Button>
                        </Toolbar>
                    </AppBar>
                    {render()}
                </Form>
            </Dialog>
        </div>
    );
};

export default MoveOutTenant;


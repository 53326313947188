import React, {useState, useEffect} from 'react';
import { Row, Col, Button, Label, Input, Form } from "reactstrap";
import { NotificationManager } from 'react-notifications';
import NumberFormat from 'react-number-format';
import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";
import MatButton from '@material-ui/core/Button';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import * as Constants from '../Util/constants';
import * as MyFuncs from '../Util/myFunctions';
import * as propertyAPI from '../../Api/property';
import * as unitsAPI from '../../Api/units';
import LinearProgress from '../Util/LinearProgress';
import { actionCreators } from '../../state/index';

const ActionButtons = (props) => {
    const handleBack = () => {
      props.previousStep();
    };
  
    const handleNext = () => {
      props.nextStep();
    };
  
    const handleFinish = () => {
      props.lastStep();
    };
  
    return (
      <div style={{marginTop: '15px'}}>
        <Row>
          {props.currentStep > 1 && (
            <Col>
              <Button onClick={handleBack}>Back</Button>
            </Col>
          )}
          <Col>
            {props.currentStep < props.totalSteps && (
              <Button onClick={handleNext}>Next</Button>
            )}
            {props.currentStep === props.totalSteps && (
              <Button onClick={handleFinish}>Finish</Button>
            )}
          </Col>
        </Row>
      </div>
    );
  };
  
  const One = (props) => {
    
    const validate = () => {
        if (!props.propertyType) {
            NotificationManager.error("Property Type is required.", "Error");
            return;
        }
        props.nextStep();
    };
  
    return (
        <>
            <Form>
                <div className="row">
                    <div className="col-sm-4">
                        <Label for="propertyType" className="mr-sm-10">Property Type</Label>
                        <Input type="select" id="propertyType" value={props.propertyType} onChange={(e) => props.setPropertyType(e.target.value)}>
                            {props.residenceType.map((obj, idx) => {
                                return <option key={idx} value={obj.UnitResidenceTypeID}>{obj.Residence}</option>
                            })}
                        </Input>
                    </div>
                </div>
                <ActionButtons {...props} nextStep={validate} />
            </Form>
        </>
    );
  };
  
  const Two = (props) => {

    const validate2 = async () => {
        if(
            !props.propertyInfo.propertyName ||
            !props.propertyInfo.propertyAddress ||
            !props.propertyInfo.propertyCity ||
            !props.propertyInfo.propertyState ||
            !props.propertyInfo.propertyZip
        ) {
            NotificationManager.error("All fields are required.", "Error");
            return;
        }
        if(await propertyAPI.getByNameAndCompany({
            companyID: props.companyID,
            propertyName: props.propertyInfo.propertyName
        })) {
            NotificationManager.error(`Property ${props.propertyInfo.propertyName} already exists.`, "Error");
            return;
        }

        props.nextStep();
    };
  
    return (
        <>
            <Form>
                <div className="row">
                    <div className="col-sm-12">
                        <Label for="propertyName" className="mr-sm-10">Property Name</Label>
                        <Input type="text" id="propertyName" 
                            value={props.propertyInfo.propertyName ? props.propertyInfo.propertyName : ''} 
                            onChange={(e) => props.setPropertyInfo({...props.propertyInfo, propertyName: e.target.value})}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <Label for="propertyAddress" className="mr-sm-10">Property Address</Label>
                        <Input type="text" id="propertyAddress" 
                            value={props.propertyInfo.propertyAddress ? props.propertyInfo.propertyAddress : ''} 
                            onChange={(e) => props.setPropertyInfo({...props.propertyInfo, propertyAddress: e.target.value})}
                        />
                    </div>
                    <div className="col-sm-3">
                        <Label for="propertyCity" className="mr-sm-10">Property City</Label>
                        <Input type="text" id="propertyCity" 
                            value={props.propertyInfo.propertyCity ? props.propertyInfo.propertyCity : ''} 
                            onChange={(e) => props.setPropertyInfo({...props.propertyInfo, propertyCity: e.target.value})}
                        />
                    </div>
                    <div className="col-sm-2">
                        <Label for="propertyState" className="mr-sm-10">Property State</Label>
                        <Input type="select" id="propertyState"
                            value={props.propertyInfo.propertyState ? props.propertyInfo.propertyState : ''} 
                            onChange={(e) => props.setPropertyInfo({...props.propertyInfo, propertyState: e.target.value})}
                        >
                            <option value="">Select a State</option>
                            {Constants.usStates.map((obj, idx) => 
                                <option key={idx} value={obj.abbreviation}>{obj.name}</option>
                            )}
                        </Input>
                    </div>
                    <div className="col-sm-2">
                        <Label for="propertyZip" className="mr-sm-10">Property Postal Code</Label>
                        <Input type="text" id="propertyZip" 
                            value={props.propertyInfo.propertyZip ? props.propertyInfo.propertyZip : ''} 
                            onChange={(e) => props.setPropertyInfo({...props.propertyInfo, propertyZip: e.target.value})}
                        />
                    </div>
                </div>
                <ActionButtons {...props} nextStep={validate2} />
            </Form>
        </>
    );
  };
  
  const Three = (props) => {
    const [showUnits, setShowUnits] = useState([]);
    const [unitDetails, setUnitDetails] = useState({
        name: '',
        charge: 0,
        adminFee: 0,
        hoaFee: 0,
        bedrooms: 0,
        bathrooms: 0,
        squareFootage: 0,
    });
    const [updated, setUpdated] = useState(true);
    
    useEffect(() => {
        async function fetchData() {
            const arr = [];
            for(const u of props.units) {
                arr.push({
                    unit: u.name,
                    unitType: `${u.bedrooms}br ${u.bathrooms}ba`,
                    unitCharge: u.charge,
                    replicate: u,
                });
            }
            setShowUnits(arr);
        }
        fetchData();
    }, [props.units, updated])

    const columns = [
        { name: 'unit', label: 'Unit', },
        { name: 'unitType', label: 'Unit Type', },
        { name: 'unitCharge', label: 'Unit Charge', 
            options: {
                customBodyRender: (value) => {
                    return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                }
            },
        },
        { name: 'replicate', label: 'Replicate', 
            options: {
                customBodyRender: (value) => {
                    return (
                        <MatButton color="primary" onClick={() => handleReplicateUnit(value)}>
                            Replicate
                        </MatButton>
                    );
                }
            },
        },
    ];

    const options = {
        filterType: 'dropdown',
        selectableRows: "none",
        rowsPerPage: 10,
    }

    const handleReplicateUnit = async (details) => {
        if(unitDetails.name === '') {
            NotificationManager.warning("Enter a new unit name before clicking replicate.", "Warning");
            return;
        }
        if(props.units.filter(u => u.name === unitDetails.name).length > 0) {
            NotificationManager.error(`Unit: ${unitDetails.name} already exist.`, "Error");
            return;
        }
        props.units.push({
            name: unitDetails.name,
            charge: details.charge,
            adminFee: details.adminFee,
            hoaFee: details.hoaFee,
            bedrooms: details.bedrooms,
            bathrooms: details.bathrooms,
            squareFootage: details.squareFootage,
        });
        props.setUnits(props.units);
        setUpdated(!updated);
    }

    const addUnit = () => {
        if(unitDetails.name === '') {
            NotificationManager.error("Unit name is required.", "Error");
            return;
        }
        if(MyFuncs.getFormattedNum(unitDetails.charge) <= 0) {
            NotificationManager.error("Unit charge must be greater than $0.", "Error");
            return;
        }
        if(parseInt(unitDetails.bedrooms) <= 0) {
            NotificationManager.error("Number of bedrooms must be greater than 0.", "Error");
            return;
        }
        if(parseInt(unitDetails.bathrooms) <= 0) {
            NotificationManager.error("Number of bathrooms must be greater than 0.", "Error");
            return;
        }
        if(props.units.filter(u => u.name === unitDetails.name).length > 0) {
            NotificationManager.error(`Unit: ${unitDetails.name} already exist.`, "Error");
            return;
        }
        props.units.push(unitDetails);
        props.setUnits(props.units);
        setUpdated(!updated);
    }

    return (
        <div>
            <Form>
                <div className="row">
                    <div className="col-sm-4">
                        <Label for="unitName" className="mr-sm-10">Name</Label>
                        <Input type="text" id="propertyAddress" 
                            value={unitDetails.name} 
                            onChange={(e) => setUnitDetails({...unitDetails, name: e.target.value})}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2">
                        <Label for="unitCharge" className="mr-sm-10">Charge</Label>
                        <NumberFormat className="form-control" id="unitCharge" thousandSeparator={true} prefix={'$'} 
                            value={unitDetails.charge} 
                            onChange={(e) => setUnitDetails({...unitDetails, charge: e.target.value})}
                        />
                    </div>
                    <div className="col-sm-2">
                        <Label for="adminFee" className="mr-sm-10">Admin Fee</Label>
                        <NumberFormat className="form-control" id="unitCharge" thousandSeparator={true} prefix={'$'} 
                            value={unitDetails.adminFee} 
                            onChange={(e) => setUnitDetails({...unitDetails, adminFee: e.target.value})}
                        /> 
                    </div>
                    <div className="col-sm-2">
                        <Label for="hoaFee" className="mr-sm-10">HOA Fee</Label>
                        <NumberFormat className="form-control" id="unitCharge" thousandSeparator={true} prefix={'$'} 
                            value={unitDetails.hoaFee} 
                            onChange={(e) => setUnitDetails({...unitDetails, hoaFee: e.target.value})}
                        /> 
                    </div>
                    <div className="col-sm-2">
                        <Label for="bedrooms" className="mr-sm-10">Bedrooms</Label>
                        <Input type="number" id="bedrooms" 
                            value={unitDetails.bedrooms} 
                            onChange={(e) => setUnitDetails({...unitDetails, bedrooms: parseInt(e.target.value)})}
                        /> 
                    </div>
                    <div className="col-sm-2">
                        <Label for="bathrooms" className="mr-sm-10">Bathrooms</Label>
                        <Input type="number" id="bathrooms" 
                            value={unitDetails.bathrooms} 
                            onChange={(e) => setUnitDetails({...unitDetails, bathrooms: parseInt(e.target.value)})}
                        /> 
                    </div>
                    <div className="col-sm-2">
                        <Label for="squareFootage" className="mr-sm-10">Square Footage</Label>
                        <Input type="number" id="squareFootage" 
                            value={unitDetails.squareFootage} 
                            onChange={(e) => setUnitDetails({...unitDetails, squareFootage: parseInt(e.target.value)})}
                        /> 
                    </div>
                </div>
                <Button type="button" color="primary" size="sm" className="w-auto" 
                    onClick={addUnit} style={{marginTop: '10px', marginBottom: '10px'}}
                >
                    Add Unit
                </Button>
            </Form>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>                                                    
                        <MUIDataTable
                            title={`Units - ${showUnits.length}`}
                            data={showUnits}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
            <ActionButtons {...props} nextStep={() => props.nextStep()} />
        </div>
    );
  };

const Four = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { updateSelectedProperty, addProperty } = bindActionCreators(actionCreators, dispatch);

    const [saving, setSaving] = useState(false);
    const showUnits = [];
    props.units.forEach((unit) => {
        showUnits.push({
            unit: unit.name,
            unitType: `${unit.bedrooms}br ${unit.bathrooms}ba`,
            unitCharge: MyFuncs.getFormattedNum(unit.charge),
            adminFee: MyFuncs.getFormattedNum(unit.adminFee),
            hoaFee: MyFuncs.getFormattedNum(unit.hoaFee),
            bedrooms: unit.bedrooms,
            bathrooms: unit.bathrooms,
            squareFootage: unit.squareFootage,
        })
    });

    const columns = [
        { name: 'unit', label: 'Unit', },
        { name: 'unitType', label: 'Unit Type', },
        { name: 'unitCharge', label: 'Unit Charge', 
            options: {
                customBodyRender: (value) => {
                    return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                }
            },
        },
        { name: 'adminFee', label: 'Admin Fee', 
            options: {
                customBodyRender: (value) => {
                    return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                }
            },
        },
        { name: 'hoaFee', label: 'HOA Fee', 
            options: {
                customBodyRender: (value) => {
                    return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                }
            },
        },
        { name: 'bedrooms', label: 'Bedrooms', },
        { name: 'bathrooms', label: 'Bathrooms', },
        { name: 'squareFootage', label: 'Square Footage', },
    ];

    const options = {
        filterType: 'dropdown',
        selectableRows: "none",
        rowsPerPage: 10,
    }

    const getSelectedResidence = () => {
        for(const rt of props.residenceType) {
            if(parseInt(rt.UnitResidenceTypeID) === parseInt(props.propertyType))
                return rt.Residence
        }
        return 0;
    }

    const handleConfirmAndSave = async () => {
        setSaving(true);
        const res = await propertyAPI.savePropertyWizard({
            propertyName: props.propertyInfo.propertyName,
            companyID: props.companyID,
            address1: props.propertyInfo.propertyAddress,
            city: props.propertyInfo.propertyCity,
            state: props.propertyInfo.propertyState,
            zip: props.propertyInfo.propertyZip,
            units: showUnits,
            unitResidenceTypeID: parseInt(props.propertyType),
            userID: props.userID,
        });
        setSaving(false);
        if(res === -1) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        props.listOfProperties.push({
            PropertyID: parseInt(res), 
            PropertyName: props.propertyInfo.propertyName
        });
        addProperty(props.listOfProperties.sort((a,b) => a.PropertyName.localeCompare(b.PropertyName)));
        updateSelectedProperty(parseInt(res));
        history.push("/");
    }

    if(saving) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Saving Data..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <div>
            <Form>
                <div className="row">
                    <div className="col-sm-6">
                        <Label for="propertyType" className="mr-sm-10">Property Type</Label>
                        <Input type="text" id="propertyType" readOnly value={getSelectedResidence()} />
                    </div>
                    <div className="col-sm-6">
                        <Label for="propertyName" className="mr-sm-10">Property Name</Label>
                        <Input type="text" id="propertyName" readOnly value={props.propertyInfo.propertyName} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <Label for="propertyAddress" className="mr-sm-10">Property Address</Label>
                        <Input type="text" id="propertyAddress" readOnly value={props.propertyInfo.propertyAddress} />
                    </div>
                    <div className="col-sm-3">
                        <Label for="propertyCity" className="mr-sm-10">Property City</Label>
                        <Input type="text" id="propertyCity" readOnly value={props.propertyInfo.propertyCity} />
                    </div>
                    <div className="col-sm-2">
                        <Label for="propertyState" className="mr-sm-10">Property State</Label>
                        <Input type="text" id="propertyState" readOnly value={props.propertyInfo.propertyState} />
                    </div>
                    <div className="col-sm-2">
                        <Label for="propertyZip" className="mr-sm-10">Property Postal Code</Label>
                        <Input type="text" id="propertyZip" readOnly value={props.propertyInfo.propertyZip} />
                    </div>
                </div>
            </Form>
            <br/>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>                                                    
                        <MUIDataTable
                            title={`Units - ${showUnits.length}`}
                            data={showUnits}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
            <Button type="button" color="primary" size="sm" className="w-auto" 
                onClick={handleConfirmAndSave} style={{marginTop: '15px'}}
            >
                Confirm and Save
            </Button>
        </div>
    )
}

const Wizard = () => {
    const login = useSelector((state) => state.login);
    const user = login.user;
    const userID = user.id;
    const listOfProperties = login.properties;

    const company = login.company;
    const companyID = company.id;

    const [stepWizard, setStepWizard] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [propertyType, setPropertyType] = useState(1);
    const [propertyInfo, setPropertyInfo] = useState({});
    const [units, setUnits] = useState([]);
    const [residenceType, setResidenceType ] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const unitSelectValues = await unitsAPI.getUnitSelectValues();
            if(unitSelectValues) {
                setResidenceType(unitSelectValues.residenceTypes);
            }
        }
        fetchData();
    }, [])

    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };

    const handleStepChange = (e) => {
        setActiveStep(e.activeStep - 1);
    };

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Property Wizard</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="">
                            <div>
                                <Stepper activeStep={activeStep}>
                                    <Step label="Property Type" />
                                    <Step label="Property Information" />
                                    <Step label="Units" />
                                    <Step label="Review and Save" />
                                </Stepper>
                                {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}
                                <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
                                    <One propertyType={propertyType} setPropertyType={setPropertyType} residenceType={residenceType} />
                                    <Two propertyInfo={propertyInfo} setPropertyInfo={setPropertyInfo} companyID={companyID} />
                                    <Three units={units} setUnits={setUnits} propertyInfo={propertyInfo} />
                                    <Four propertyType={propertyType} propertyInfo={propertyInfo} units={units} userID={userID}
                                        residenceType={residenceType} companyID={companyID} listOfProperties={listOfProperties} />
                                </StepWizard>
                            </div>
                        </RctCollapsibleCard>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default Wizard;

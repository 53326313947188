import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Label, Input } from 'reactstrap';
import { FormGroup, Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Util from '../Util/util';
import * as Constants from '../Util/constants';
import * as propertiesAPI from '../../Api/property';

const ViewAll = () => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const listOfProperties = login.properties;
    const propertyID = login.selectedPropertyID;
    const user = login.user;
    const userID = user.id;
    const company = login.company;
    const companyID = company.id;

    const dispatch = useDispatch();
    const { updateSelectedProperty, addProperty, removeProperty } = bindActionCreators(actionCreators, dispatch);

    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState([]);
    const [deleteID, setDeleteID] = useState(0);
    const [isUK, setIsUK] = useState(true);
    const [updated, setUpdated] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    async function fetchData() {
        setLoading(true);
        const arr = [];
        const properties = await propertiesAPI.getPropertiesWithMerchants(companyID);
        console.log('properties', properties);
        for (const p of properties) {
            arr.push({
                propertyName: p.PropertyName,
                contactInfo: p,
                propertyURL: p.PropertyURL,
                propertyAddress: p,
                paymentAccount: p,
                edit: p.PropertyID,
                delete: p.PropertyID,
            });
        }
        setProperties(arr);
        setLoading(false);
    }
    useEffect(() => {
        fetchData();
    }, [companyID, updated]);

    const columns = [
        { name: 'propertyName', label: 'Property Name' },
        {
            name: 'contactInfo',
            label: 'Contact Info',
            options: {
                customBodyRender: (value) => {
                    return (
                        <span>
                            {value.PropertyPhone} <br />
                            <a href={`mailto:${value.PropertyEmail}`}>{value.PropertyEmail}</a>
                        </span>
                    );
                },
            },
        },
        {
            name: 'propertyURL',
            label: 'Property URL',
            options: {
                customBodyRender: (value) => {
                    if (value) {
                        return (
                            <a href={value} target="_blank" rel="noreferrer">
                                {value}
                            </a>
                        );
                    }
                },
            },
        },
        {
            name: 'propertyAddress',
            label: 'Property Address',
            options: {
                customBodyRender: (value) => {
                    return (
                        <span>
                            {value.PropertyAddress1} {value.PropertyAddress2}
                            <br />
                            {value.PropertyCity} {value.PropertyState} {value.PropertyZip}
                        </span>
                    );
                },
            },
        },
        {
            name: 'paymentAccount',
            label: 'Payment Account',
            options: {
                customBodyRender: (value) => {
                    if (value.MerchantName) {
                        return (
                            <>
                                <span>{value.Status === 'Approved' ? value.MerchantName : value.Status}</span>
                                <IconButton
                                    onClick={async () => {
                                        await propertiesAPI.deleteMerchant({ epicPayKeyID: value.EpicPayKeysID });
                                        await fetchData();
                                    }}
                                >
                                    <Delete />
                                </IconButton>
                            </>
                        );
                    }
                    return (
                        <Link
                            to={{
                                pathname: '/properties/addMerchant',
                                state: {
                                    propertyID: parseInt(value.PropertyID),
                                },
                            }}
                        >
                            Create Merchant Account
                        </Link>
                    );
                },
            },
        },
        {
            name: 'edit',
            label: 'Edit',
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton
                            onClick={() => {
                                const location = {
                                    pathname: '/properties/edit',
                                    state: {
                                        propertyID: parseInt(value),
                                    },
                                };
                                history.push(location);
                            }}
                        >
                            <Edit />
                        </IconButton>
                    );
                },
            },
        },
        {
            name: 'delete',
            label: 'Delete',
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton
                            onClick={() => {
                                setDeleteID(parseInt(value));
                            }}
                        >
                            <DeleteForever />
                        </IconButton>
                    );
                },
            },
        },
    ];

    const options = {
        filterType: 'dropdown',
        pagination: false,
        selectableRows: 'none',
    };

    const deleteProperty = async () => {
        setLoading(true);
        const deletedPropertyID = deleteID;
        const res = await propertiesAPI.deleteProperty({
            propertyID: deletedPropertyID,
            userID,
        });
        setDeleteID(0);
        if (res !== 0) {
            setLoading(false);
            NotificationManager.error(Constants.DEFAULT_ERROR, 'Error');
            return;
        }
        if (deletedPropertyID === propertyID) {
            for (const p of listOfProperties) {
                if (p.PropertyID !== propertyID) {
                    updateSelectedProperty(p.PropertyID);
                    break;
                }
            }
        }
        const index = listOfProperties
            .map(function (x) {
                return x.PropertyID;
            })
            .indexOf(deletedPropertyID);
        listOfProperties.splice(index, 1);
        removeProperty(listOfProperties.sort((a, b) => a.PropertyName.localeCompare(b.PropertyName)));
        setLoading(false);
        setUpdated(!updated);
    };

    const submitForm = async (data) => {
        setLoading(true);
        const res = await propertiesAPI.addProperty({
            propertyName: data.propertyName,
            companyID,
            address1: data.propertyAddress1,
            address2: data.propertyAddress2 ? data.propertyAddress2 : '',
            city: data.propertyCity,
            state: data.propertyState,
            zip: data.propertyPostalCode.toString(),
            email: data.propertyEmail,
            phone: data.propertyPhone,
            url: data.propertyURL ? data.propertyURL : '',
            backgroundScreening: 1,
            country: companyID !== 7514 ? 'United States' : isUK ? 'UK' : 'Canada',
            userID,
        });
        setLoading(false);
        if (res === -1) {
            NotificationManager.error(Constants.DEFAULT_ERROR, 'Error');
            return;
        }
        setUpdated(!updated);
        const tempProps = listOfProperties.push({ PropertyID: res, PropertyName: data.propertyName });
        addProperty(tempProps.sort((a, b) => a.PropertyName.localeCompare(b.PropertyName)));
        NotificationManager.success('Property added!', 'Success');
    };

    const handleSelectCountry = () => setIsUK(!isUK);

    const renderCountry = () => {
        if (companyID === 7514) {
            return (
                <div className="col-sm-3">
                    <RadioGroup aria-label="anonymous" name="anonymous" value={true} row>
                        <Label className="mr-sm-10">Property Country</Label>
                        <FormControlLabel
                            value="cc"
                            control={<Radio checked={isUK} value="UK" name="selectCountry" onChange={handleSelectCountry} />}
                            label="UK"
                        />
                        <FormControlLabel
                            value="ach"
                            control={
                                <Radio checked={!isUK} value="Canada" name="selectCountry" onChange={handleSelectCountry} />
                            }
                            label="Canada"
                        />
                    </RadioGroup>
                </div>
            );
        }
    };

    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Properties...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <SweetAlert
                warning
                btnSize="sm"
                show={deleteID !== 0}
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => deleteProperty()}
                onCancel={() => setDeleteID(0)}
            >
                You will not be able to recover this property!
            </SweetAlert>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Properties</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="Property Details">
                            <Form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="propertyName" className="mr-sm-10">
                                                Property Name
                                            </Label>
                                            <Controller
                                                name="propertyName"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type="text"
                                                        id="propertyName"
                                                        style={Util.setErrorStyle(errors.propertyName)}
                                                    />
                                                )}
                                            />
                                            {errors.propertyName && (
                                                <span style={{ color: 'red' }} role="alert">
                                                    required
                                                </span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="propertyPhone" className="mr-sm-10">
                                            Property Phone
                                        </Label>
                                        <Controller
                                            name="propertyPhone"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <NumberFormat
                                                    className="form-control"
                                                    {...field}
                                                    format="+1 (###) ###-####"
                                                    mask="_"
                                                    id="propertyPhone"
                                                    style={Util.setErrorStyle(errors.propertyPhone)}
                                                />
                                            )}
                                        />
                                        {errors.propertyPhone && (
                                            <span style={{ color: 'red' }} role="alert">
                                                required
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-sm-4">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="propertyEmail" className="mr-sm-10">
                                                Property Email
                                            </Label>
                                            <Controller
                                                name="propertyEmail"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type="text"
                                                        id="propertyEmail"
                                                        style={Util.setErrorStyle(errors.propertyEmail)}
                                                    />
                                                )}
                                            />
                                            {errors.propertyEmail && (
                                                <span style={{ color: 'red' }} role="alert">
                                                    required
                                                </span>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-5">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="propertyURL" className="mr-sm-10">
                                                Property URL
                                            </Label>
                                            <Controller
                                                name="propertyURL"
                                                control={control}
                                                render={({ field }) => <Input {...field} type="text" id="propertyURL" />}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="propertyAddress1" className="mr-sm-10">
                                                Property Address
                                            </Label>
                                            <Controller
                                                name="propertyAddress1"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type="text"
                                                        id="propertyAddress1"
                                                        style={Util.setErrorStyle(errors.propertyAddress1)}
                                                    />
                                                )}
                                            />
                                            {errors.propertyAddress1 && (
                                                <span style={{ color: 'red' }} role="alert">
                                                    required
                                                </span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-4">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="propertyAddress2" className="mr-sm-10">
                                                Property Address 2
                                            </Label>
                                            <Controller
                                                name="propertyAddress2"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="propertyAddress2" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Label for="propertyCity" className="mr-sm-10">
                                            Property City
                                        </Label>
                                        <Controller
                                            name="propertyCity"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    type="text"
                                                    id="propertyCity"
                                                    style={Util.setErrorStyle(errors.propertyCity)}
                                                />
                                            )}
                                        />
                                        {errors.propertyCity && (
                                            <span style={{ color: 'red' }} role="alert">
                                                required
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="propertyState" className="mr-sm-10">
                                            Property State
                                        </Label>
                                        <Controller
                                            name="propertyState"
                                            control={control}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="propertyState">
                                                    {Constants.usStates.map((obj, idx) => (
                                                        <option key={idx} value={obj.abbreviation}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="propertyPostalCode" className="mr-sm-10">
                                            Property Postal Code
                                        </Label>
                                        <Controller
                                            name="propertyPostalCode"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    type="number"
                                                    id="propertyPostalCode"
                                                    style={Util.setErrorStyle(errors.propertyPostalCode)}
                                                />
                                            )}
                                        />
                                        {errors.propertyPostalCode && (
                                            <span style={{ color: 'red' }} role="alert">
                                                required
                                            </span>
                                        )}
                                    </div>
                                    {renderCountry()}
                                </div>
                                <Button type="submit" color="primary" style={{ marginTop: '10px' }}>
                                    Add Property
                                </Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <MuiThemeProvider theme={Constants.getMuiTheme()}>
                            <MUIDataTable
                                title={`Existing Properties - ${properties.length}`}
                                data={properties}
                                columns={columns}
                                options={options}
                            />
                        </MuiThemeProvider>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default ViewAll;


import React from 'react';
import { useSelector } from 'react-redux';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import * as Constants from '../Util/constants';

const imgStyle = {
    maxWidth: '800px',
    maxHeight: '500px',
};

const UserGuide = () => {
    const login = useSelector((state) => state.login);
    const company = login.company;

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>User Guide</span>
                        </h2>
                    </div>
                </div>
                <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'User Details'}>
                    <div>
                        <p>
                            If you're an Administrator for your account, you have the ability to add users to the system and
                            grant access to {company.name}. To do so, go to the "Company Profile" menu bar and choose "User
                            Details".
                            <br />
                            <br />
                            When adding users, be certain to choose the properties that they will have access to. If you have
                            multiple properties, you can give them access to one or more properties by choosing them from the
                            multi-select dropdown menu.
                            <br />
                            <br />
                            It is important that you choose the right level of security when creating a new user. Leasing
                            Agents have minimal access to the system, while Administrators have the ability to use ALL
                            features in the system, including deleting transactions, and adding users etc. Note that you can
                            edit user's privileges at a later date. For instance if you want to give temporary access to a
                            different property to a Property Manager, just go into the User Details section, and click on the
                            EDIT button on the right side of the screen under Actions.
                            <br />
                            <br />
                            If someone leaves your company, it's important that you delete them as a user. If you don't they
                            could gain access to your system remotely.
                            <br />
                            <br />
                            Here's an example of the User details screen:
                        </p>
                        <img src="https://myirent.com/rent/img/Help/1.png" style={imgStyle} alt="user-details" />
                    </div>
                </RctCollapsibleCard>

                <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Property Details'}>
                    <div>
                        <p>
                            The Property Rules are an extremely powerful tool in {company.name}. Along with other options, in
                            this section you can dictate how much late fees are, when they will be charged, and how often
                            tenants will be notified.
                            <br />
                            <br />
                            By default, rents are applied the first day of every month. When rents are applied, tenants are
                            notified via SMS and/or email. If you would like a late fee applied after a certain number of
                            days, you can indicate the amount and the frequency of the late fees. {company.name} supports
                            both one time, and daily late fees.
                            <br />
                            <br />
                            In this example, an initial late fee of $35 will be applied on the 4th day of the month, but only
                            if the tenant has a balance greater than $20. Every day after the 4th day, if the tenant still
                            has a balance of over $20, then they will be charged a daily late fee of $5 up to a maximum of
                            $180.
                            <br />
                            <br />
                            Tenants are notified (via email or SMS) every time a charge is posted to their account.
                            <br />
                            <br />
                            All of these rules are property based, so be sure to chose the appropriate property before making
                            any changes to the rules.
                        </p>
                        <img src="https://myirent.com/rent/img/Help/2.png" style={imgStyle} alt="property-details" />
                    </div>
                </RctCollapsibleCard>

                <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Entering Unit Types'}>
                    <div>
                        <p>
                            Before you can setup units to rent out, you first need to identify the different unit types that
                            this particular property offers. Unit types are tied to a specific property so if you have
                            multiple properties, make sure you have the correct property selected from the "Current Property"
                            dropdown, before you enter unit types. A typical situation might be an apartment building that
                            may have 20 units but only a few different unit types. For instance a 20 unit apartment building
                            may have 10 one bedroom, one bath units, and 10 two bedroom one bath units. Once you setup the
                            unit types you will then be able to choose the number of units and label them appropriately (i.e.
                            unit 101, 102 etc.).
                            <br />
                            <br />
                            <li>1. Under Company Profile, choose Unit Types from the left side menu.</li>
                            <li>2. Enter the Unit Type, a description of the unit, and unit charge.</li>
                            <li>3. Click "Add Unit Type"</li>
                            <li>4. Repeat for all the different unit types you offer for this specific property.</li>
                            <br />
                            In the above example you would have a screen that looks like:
                        </p>
                        <img src="https://myirent.com/rent/img/Help/3.png" style={imgStyle} alt="unit-rules" />
                    </div>
                </RctCollapsibleCard>

                <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Entering Unit Listings'}>
                    <div>
                        <p>
                            Once you have all the unit types entered, you'll want to enter in all of your units. Note this is
                            easily done for a small number of units. If you have over 100 units, you may want to contact{' '}
                            <a href={`mailto:${company.email}`}>{company.email}</a> to see if it's possible to import the
                            information rather than typing it in manually.
                            <br />
                            <br />
                            To enter the information manually go the Company Profile menu item, and select "Unit Listings"
                            from the left menu bar. Then enter the unit name and choose a Unit Type to associate with the
                            name. For instance you may want to input "101" as the first Unit Name, and then select "1
                            bedroom, 1 bath" as the Unit Type. After entering a few units, your screen should look similar to
                            this:
                            <br />
                        </p>
                        <img src="https://myirent.com/rent/img/Help/4.png" style={imgStyle} alt="unit-listing" />
                    </div>
                </RctCollapsibleCard>

                <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Prospects and Tenants'}>
                    <div>
                        <p>
                            Before a tenant can be entered, that tenant must be a prospect. To enter a new prospect, go to
                            the "Prospects" tab of the main menu. Fill out the information required in order to create a new
                            prospect. The new prospect screen should look similar to the following:
                            <br />
                        </p>
                        <img src="https://myirent.com/rent/img/Help/5.png" style={imgStyle} alt="tenants" />
                        <br />
                        <p>
                            Below the Add New Prospect form, a list of the current prospects will appear. Those new prospects
                            will look similar to this:
                            <br />
                        </p>
                        <img src="https://myirent.com/rent/img/Help/6.png" style={imgStyle} alt="prospects" />
                        <br />
                        <p>
                            {company.name} is setup with some checks and balances to ensure that someone is not allowed to
                            become a tenant until they have been "approved" by someone with Property Manager, or higher
                            security level. To approve a prospect, you need to click on the "edit" pencil icon for the
                            appropriate tenant. The screen should look similar to this:
                            <br />
                        </p>
                        <img src="https://myirent.com/rent/img/Help/7.png" style={imgStyle} alt="balances" />
                        <br />
                        <p>
                            Once approved, the "Convert to Tenant" button will appear:
                            <br />
                        </p>
                        <img src="https://myirent.com/rent/img/Help/8.png" style={imgStyle} alt="approve-tenant" />
                        <br />
                        <p>
                            After clicking on "Convert to Tenant" you will need to enter some required information. The
                            conversion screen should look similar to the following:
                            <br />
                        </p>
                        <img src="https://myirent.com/rent/img/Help/9.png" style={imgStyle} alt="convert-tenant" />
                    </div>
                </RctCollapsibleCard>
            </div>
        </Main>
    );
};

export default UserGuide;
